import { Modal } from '@equitymultiple/react-eui';
import headshot from 'images/charles-headshot.png';
import Signature from 'images/charles-signature.svg';
import React, { useState } from 'react';
import utils from 'utilities/utils';

import * as styles from './WelcomeLetter.module.scss';

type Props = {
  irRep: string;
  name: string;
  userId: number;
};

const WelcomeLetter = ({ irRep, name, userId }: Props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    utils.setCookie('welcome-letter-shown', userId, 999);
    setShow(false);
    const newUrl = window.location.href.replace('?show=welcome', '');
    window.history.replaceState({}, document.title, newUrl);
  };

  return (
    <Modal
      className={styles.welcomeLetterModal}
      open={show}
      onClose={handleClose}
    >
      <h3>Welcome</h3>
      <p>Dear {name},</p>
      <p>
        Thank you for creating an account, and for considering investing with
        us. We built EquityMultiple with a shared vision of leveling the playing
        field for individual investors like yourself - allowing you greater
        access than ever before to an asset class that&apos;s long been a
        fixture in institutional portfolios. While you no doubt understand the
        value of allocating a portion of your portfolio to real estate, we
        expect that you will continue to do your own diligence on us and the
        investments we offer. Our commitment is to conservative underwriting and
        vigilant asset management on your behalf.
      </p>
      <p>
        You can expect that our dedicated team of real estate professionals is
        focused on identifying and vetting sound investments across the country.
        We welcome you to{' '}
        <a href={irRep} target="_blank" rel="noreferrer">
          schedule a call
        </a>{' '}
        with our Investor Relations team so that you can get comfortable with
        EquityMultiple and our offerings as you consider investing.
      </p>
      <div className={styles.signatureBlock}>
        <div className={styles.signatureWrapper}>
          <span>
            <Signature />
          </span>
        </div>
        <div className={styles.bottom}>
          <img
            className={styles.headshot}
            src={headshot}
            alt="Charles Clinton"
          />
          <div className={styles.nameTitle}>
            Charles Clinton
            <br />
            Cofounder & CEO
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeLetter;
