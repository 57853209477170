import { Card } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import { HeaderSimple } from 'components/HeaderSimple';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { forgotPassword, setAuthSubmitting } from 'redux/actions/auth';
import { updateSignupValues } from 'redux/actions/onboarding';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import humane from 'utilities/humane';
import { throwSubmissionErrors } from 'utilities/validation';

import ForgotPasswordForm from './components/ForgotPasswordForm';
import * as styles from './ForgotPassword.module.scss';

interface Props {
  dispatch: Dispatch;
  incompleteSignup: boolean;
  sentForgotEmail: boolean;
  sentForgotEmailFailed: boolean;
  submitting: boolean;
}

const ForgotPassword = ({
  dispatch,
  incompleteSignup,
  sentForgotEmailFailed,
  sentForgotEmail,
  submitting
}: Props) => {
  const [email, setEmail] = useState('');

  useRecaptcha();

  useEffect(() => {
    document.title = 'Forgot Password | EquityMultiple';
  }, []);

  useEffect(() => {
    if (sentForgotEmail && !incompleteSignup)
      humane.notice(
        'We resent you an email with a reset password link. It may take a few minutes to arrive.'
      );
  }, [incompleteSignup, sentForgotEmail]);

  useEffect(() => {
    if (sentForgotEmailFailed)
      humane.error(
        'There was a problem resending the email, please try again.'
      );
  }, [sentForgotEmailFailed]);

  const handleSubmit = async formData => {
    dispatch(setAuthSubmitting());

    const token = await getRecaptchaToken('forgotPassword');

    const data = {
      user: {
        ...formData,
        captcha_response: token
      }
    };

    setEmail(formData.email);

    return dispatch(forgotPassword(data))
      .then(res => {
        // For users who created an account but exited before setting their password
        if (res?.step === 2) {
          dispatch(
            updateSignupValues({
              email: data.user.email
            })
          );
          history.replace('/users/signup/password');
        }
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const handleResend = async () => {
    const token = await getRecaptchaToken('forgotPassword');

    const data = {
      user: {
        email,
        captcha_response: token
      }
    };

    dispatch(forgotPassword(data));
  };

  const formNotSubmitted = !sentForgotEmail && !email;

  return (
    <div>
      <HeaderSimple firstPage />
      <Container>
        <Card>
          {formNotSubmitted ? (
            <div>
              <h4 className="margin-top-0">Forgot your password?</h4>
              <p>
                If you forgot your password, enter your email address below and
                clicking Reset Password. We'll send you an email with
                instructions to complete the password reset process.
              </p>
              <ForgotPasswordForm
                submitting={submitting}
                onSubmit={handleSubmit}
              />
            </div>
          ) : (
            <div className={styles.emailSent}>
              <h4 className="margin-top-0">
                Check your email for further instructions
              </h4>
              <p>
                We've sent you an email with a link to update your password.
              </p>
              <p>
                On occasion, email from us can get stuck in spam. If you don't
                receive an email within a few minutes, please check your spam
                folder or any other email filtering software you may have.
              </p>
              <h6 className={styles.h6}>Did not receive the email?</h6>
              <p>
                <button
                  className="text-link"
                  onClick={handleResend}
                  type="button"
                >
                  Click here
                </button>{' '}
                to resend the email to {email}
              </p>
            </div>
          )}
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    submitting: store.auth.loading || store.auth.submitting,
    sentForgotEmail: store.auth.sentForgotEmail,
    resentForgotEmail: store.auth.resentForgotEmail,
    sentForgotEmailFailed: store.auth.sentForgotEmailFailed,
    incompleteSignup: !!store.auth.signupValues.email
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(ForgotPassword);
