import { Alert, ErrorBoundary, Tooltip } from '@equitymultiple/react-eui';
import ClosingStageLabel from 'components/ClosingStageLabel/ClosingStageLabel';
import React from 'react';
import { Link } from 'react-router-dom';
import { ActiveOfferingClosing } from 'types/api/closing';
import { User } from 'types/api/user';
import { segmentTrackData } from 'utilities/analyticsHelpers';
import EmAnalytics from 'utilities/em_analytics';
import { trackInvestorPacketClick } from 'utilities/trackEvents';
import utils from 'utilities/utils';

import { getInvestorPacketUrlWithPage } from '../../../Offerings/helpers';
import { formatIrrForOfferingCard } from '../../helpers';
import * as styles from '../OfferingCardShared.module.scss';
import { getInvestmentWizardRoute, getOfferingCardValue } from './helpers';

interface Props {
  cardIndex?: number;
  closingData?: ActiveOfferingClosing;
  closingId?: string;
  currentUser?: User;
  inactive?: boolean;
  loading?: boolean;
}

const LoadingCard = () => (
  <div
    className={`${styles.offeringCard} ${styles.inactive} ${styles.cardDisabled}`}
  >
    <div className={`${styles.cardHeader} ${styles.loading}`} />
    <div className={styles.cardContent}>
      <div className={styles.propertyInfo}>
        -<span className={styles.separator}>|</span>
        <div className={styles.buildingIcon} />-
      </div>
      <h4 className={styles.offeringTitle}>-</h4>
      <div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Investment Type</div>
          <div className={styles.metricValue}>-</div>
        </div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Investors</div>
          <div className={styles.metricValue}>-</div>
        </div>
        <div className={styles.metric}>
          <div className={styles.metricLabel}>Total Raised</div>
          <div className={styles.metricValue}>-</div>
        </div>
      </div>
    </div>
  </div>
);

const OfferingCard = ({
  cardIndex,
  closingData,
  closingId,
  currentUser,
  inactive,
  loading
}: Props) => {
  const cardValue = getOfferingCardValue(closingData);
  let cardButtonStyle = '';
  if (
    cardValue === 'Complete Your Investment' ||
    cardValue === 'Update Payment'
  ) {
    cardButtonStyle = styles.poppyButton;
  }

  const offeringId = closingData?.offering_id;
  const investment = closingData?.investment;
  const disabled = closingData?.disabled || inactive;

  const trackClick = () => {
    const trackData = {
      ...segmentTrackData(closingData, currentUser),
      action: cardValue,
      cardPosition: cardIndex
    };

    EmAnalytics.track('Clicked Offering Card', 'Navigation', trackData);
  };

  const getButtonLink = () => {
    if (investment) {
      return getInvestmentWizardRoute(closingId, closingData.investment);
    }

    return `/invest/${offeringId}/closings/${closingId}`;
  };

  const renderOfferingCardBody = () => {
    const approach = closingData.offering_approach;
    const showProgress = closingData.show_progress_bar && !inactive;
    const investmentMinimumEnabled = closingData.investment_minimum_enabled;
    let investorPacketUrl = getInvestorPacketUrlWithPage(
      closingData.investor_packet_url,
      offeringId
    );
    const showInvestorPacket =
      closingData.show_investor_packet && investorPacketUrl;
    const strategy = closingData.strategy;
    const strategies = strategy?.split(',');
    const isEquity = strategies.includes('Equity');
    const isDebt = strategies.includes('Debt');
    const isSTN = strategies.includes('Short Term Note');
    const isPrefEquity = strategies.includes('Preferred Equity');
    const strategyValue = strategy.replace(',', ', ');
    const isFund = approach === 'Fund';
    const isAnnualizedYield =
      closingData.annual_return_label === 'Annualized Yield' &&
      !Number.isNaN(parseFloat(closingData.projected_annual_return)) &&
      parseFloat(closingData.projected_annual_return) !== 0;
    const isNetherwood = offeringId === 473;
    const isTrevian = offeringId === 465;
    const isStarwood = offeringId === 439;
    const progressBarWidth = Math.min(
      closingData.percent_commitment_funded,
      100
    );

    if (isStarwood)
      investorPacketUrl = 'http://connect.rightprospectus.com/Starwood/SPCD/';

    let returnMetricLabel = 'Target Return';
    if (isEquity && !isPrefEquity) {
      returnMetricLabel = 'Target Investor IRR';
    } else if (isFund) {
      if (showInvestorPacket) {
        if (isPrefEquity) returnMetricLabel = 'Total Preferred Return';
        else if (isDebt) returnMetricLabel = 'Debt';
      }
    } else if (isPrefEquity) {
      returnMetricLabel = 'Total Preferred Return';
    } else if (isSTN) {
      returnMetricLabel = isAnnualizedYield ? 'APY' : 'Rate';
    } else if (isDebt) {
      returnMetricLabel = 'Rate';
    }

    if (isNetherwood) returnMetricLabel = 'Proforma Total Returns';

    let returnMetricValue: string | React.ReactNode =
      `${closingData.total_preferred_return}%`;

    const showInvestorPacketLink =
      isNetherwood ||
      isTrevian ||
      ((isPrefEquity || isDebt) && isFund && showInvestorPacket);

    if (showInvestorPacketLink) {
      returnMetricValue = (
        <a
          href={investorPacketUrl}
          className={`text-link ${styles.investorPacketLink}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackInvestorPacketClick(
              'offering card',
              closingData.title,
              currentUser
            )
          }
        >
          See Investor Packet
        </a>
      );
    } else if (isSTN) {
      returnMetricValue = isAnnualizedYield
        ? closingData.projected_annual_return
        : `${closingData.rate}%`;
    } else if (isDebt) {
      returnMetricValue = `${closingData.rate}%`;
    } else if (isEquity) {
      returnMetricValue = formatIrrForOfferingCard(closingData.irr);
    }

    const showClosingStageLabel =
      ![
        'Investment Pending',
        'Review Your Investment',
        'Complete Your Investment'
      ].includes(cardValue) && closingData.stage !== 'active';

    return (
      <>
        <Link
          className={styles.cardLink}
          to={`/invest/${closingData.offering_id}/closings/${closingId}`}
          onClick={trackClick}
          data-testid="cardLink"
        >
          <div
            className={styles.cardHeader}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(42, 43, 48, 0) 25%, rgba(42, 43, 48, 0.75) 100%), url(${closingData.card_image})`
            }}
          >
            {showClosingStageLabel && (
              <ClosingStageLabel stage={closingData.stage} />
            )}
            {investmentMinimumEnabled && (
              <Alert type="positive" className={styles.cardAlert}>
                You can now invest a lower investment amount. The minimum has
                been fulfilled.
              </Alert>
            )}
            <div className={styles.headerBottom}>
              {showProgress && (
                <>
                  <div className={styles.progress}>
                    <div
                      data-testid="progress-bar"
                      className={styles.progressBar}
                      style={{
                        width: `${progressBarWidth}%`
                      }}
                    />
                  </div>
                  <div className={styles.progressLabel}>
                    {closingData.percent_commitment_funded}% Invested
                    {closingData.percent_commitment_funded > 100 && (
                      <Tooltip
                        tooltipContent={
                          <span>
                            <strong>
                              Why is this offering over 100% subscribed?
                            </strong>{' '}
                            In short, this progress bar reflects indications of
                            interest from investors, and actual placement into
                            investments occurs on a “first fund - first in”
                            basis. Note that we will never accept more than the
                            target amount such that any investor&apos;s position
                            is diluted. Please refer to{' '}
                            <a
                              href="https://www.equitymultiple.com/blog/equitymultiple-operations-methods/navigate-equitymultiples-investment-statuses/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              this article
                            </a>{' '}
                            for more details on our closing process.
                          </span>
                        }
                        style={{
                          margin: '-2px 0 0 3px'
                        }}
                        infoIcon
                        infoIconColor="#fff"
                      />
                    )}
                  </div>
                </>
              )}
              {closingData.show_view_count && !inactive && (
                <div className={styles.viewsCount}>
                  {closingData.views} {closingData.views > 1 ? 'Views' : 'View'}
                </div>
              )}
            </div>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.propertyInfo}>
              {closingData.location}
              <span className={styles.separator}>|</span>
              <div className={styles.buildingIcon} />
              {closingData.asset_type === 'Manufactured Housing Community'
                ? 'Manufactured Housing'
                : closingData.asset_type}
            </div>
            <h4 className={styles.offeringTitle}>{closingData.title}</h4>
            <div>
              <div className={styles.metric}>
                <div className={styles.metricLabel}>
                  {['Fund', 'Direct', 'Alternative to Savings'].includes(
                    approach
                  )
                    ? 'Investment Type'
                    : 'Tax Strategy'}
                </div>
                <div className={styles.metricValue}>{strategyValue}</div>
              </div>
              {inactive ? (
                <>
                  <div className={styles.metric}>
                    <div className={styles.metricLabel}>Investors</div>
                    <div className={styles.metricValue}>
                      {closingData.number_of_investors}
                    </div>
                  </div>
                  <div className={styles.metric}>
                    <div className={styles.metricLabel}>Total Raised</div>
                    <div className={styles.metricValue}>
                      {utils.formatCurrency(closingData.amount_raised, 0)}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.metric}>
                    <div
                      className={styles.metricLabel}
                      data-testid="return-metric-label"
                    >
                      {returnMetricLabel}
                    </div>
                    <div
                      className={styles.metricValue}
                      data-testid="return-metric"
                    >
                      {returnMetricValue}
                    </div>
                  </div>
                  <div className={styles.metric}>
                    <div className={styles.metricLabel}>Investment Minimum</div>
                    <div className={styles.metricValue}>
                      {utils.formatCurrency(
                        closingData.minimum_investment_amount,
                        0
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Link>

        {!inactive && !closingData.disabled && (
          <Link
            onClick={trackClick}
            className={`${styles.lightButton} ${cardButtonStyle}`}
            to={getButtonLink()}
          >
            {cardValue}
          </Link>
        )}
      </>
    );
  };

  return loading ? (
    <LoadingCard />
  ) : (
    <ErrorBoundary name={`Closing ${closingId}`}>
      <div
        className={`${styles.offeringCard} ${
          disabled ? styles.cardDisabled : ''
        } ${inactive ? styles.inactive : ''}`}
      >
        {renderOfferingCardBody()}
      </div>
    </ErrorBoundary>
  );
};

export default OfferingCard;
