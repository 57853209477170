import { Alert, Button } from '@equitymultiple/react-eui';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import { emailVerifyChannel } from 'containers/SignUp/helpers';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmAnalytics from 'utilities/em_analytics';

import PhotoId from '../../../images/icons/icon-photo-id.svg';
import * as styles from '../Profile.module.scss';

type Props = {
  location: Location;
};

const Start = ({ location }: Props) => {
  const query = queryString.parse(location.search);

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  useEffect(() => {
    if (query.email_confirmation) {
      emailVerifyChannel.postMessage('verified');
    }
  }, [query.email_confirmation]);

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <h3 className="margin-x">
        Before you start, here's what we'll need to complete this section
      </h3>
      <Alert type="neutral" className={styles.startMessage}>
        <PhotoId className={styles.photoId} />
        <div>A current copy of a government issued photo identification</div>
      </Alert>
      <div className="forwardBackButtonWrapCompact">
        <Button
          variant="orange"
          className="button-fixed-width margin-top-xx"
          wrapper={<Link to="/users/profile/experience" />}
          onClick={() => EmAnalytics.track('Started Profile', 'Onboarding')}
        >
          Continue
        </Button>
      </div>
    </AccountProgressContainer>
  );
};

export default Start;
