import './CapitalCallTimer.scss';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class CapitalCallTimer extends Component {
  timerInterval = null;

  static propTypes = {
    deadline: PropTypes.shape({}),
    small: PropTypes.bool
  };

  state = {
    days: 0,
    hours: 0,
    minutes: 0,
    warning: false
  };

  componentDidMount() {
    const { small, deadline } = this.props;
    if (deadline) {
      this.setTime();
      if (!small) this.timerInterval = setInterval(this.setTime, 30000);
    }
  }

  componentWillUnmount() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  }

  setTime = () => {
    const { deadline } = this.props;
    // Countdown runs until end of day on the funding deadline
    const end = moment(deadline).endOf('day');
    const now = moment();
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let warning = true;
    let pastEnd = true;

    const diff = end.diff(now);

    if (Math.sign(diff) === 1) {
      const diffDuration = moment.duration(diff);
      // Since we're showing exact hours, round down the number of days to the nearest integer.
      // This will show 0 if under 24 hours
      days = Math.floor(diffDuration.asDays());
      hours = diffDuration.hours();
      minutes = diffDuration.minutes();
      warning = diffDuration.days() < 3;
      pastEnd = false;
    }

    this.setState({
      days,
      hours,
      minutes,
      pastEnd,
      warning
    });
  };

  render() {
    const { small } = this.props;
    const { days, hours, minutes, pastEnd, warning } = this.state;

    return (
      <div
        className={`capital-call-timer ${warning ? 'orange' : ''} ${
          small ? 'small' : ''
        }`}
      >
        {!small && <div className="nav-title">Capital Call Due In:</div>}
        <div className="timer">
          {(!small || (small && (days > 0 || pastEnd))) && (
            <div className="tile">
              <div className="tile-label">Days {small && ' Left'}</div>
              <div className="tile-value">{days}</div>
            </div>
          )}
          {(!small || (small && days === 0 && hours > 0 && !pastEnd)) && (
            <div className="tile">
              <div className="tile-label">Hours {small && ' Left'}</div>
              <div className="tile-value">{hours}</div>
            </div>
          )}
          {(!small || (small && days === 0 && hours === 0 && !pastEnd)) && (
            <div className="tile">
              <div className="tile-label">Min {small && ' Left'}</div>
              <div className="tile-value">{minutes}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
