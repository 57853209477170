import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import { ReduxFormSelectField } from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { experienceOptions } from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const Experience = ({
  dispatch,
  error,
  handleSubmit,
  submitting
}: SharedProfileValues) => {
  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'investor_profile_attributes.investment_experience'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          history.push('/users/profile/objectives');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Experience</h3>
        <p className="margin-xx">
          Let us know your investment focus to help us personalize your
          experience. Your stated strategy will help guide our presentation of
          investment offerings.
        </p>
        <Field
          id="investment_experience"
          component={ReduxFormSelectField}
          name="investor_profile_attributes.investment_experience"
          label="Investment Experience"
          options={experienceOptions}
          multi
          showMultiLabelsBelow
        />

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/start" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    initialValues: getUserAttributes(store.auth.user),
    submitting: store.userSettings.updatingUserProfile
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'experience',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(Experience)
);
