import history from 'browserHistory';
import { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { User } from 'types/api/user';
import { isAccredited } from 'utilities/user';

interface Props {
  children: ReactElement;
  user: User;
}

const RequireAccreditedUser = ({ children, user }: Props) => {
  useEffect(() => {
    if (!isAccredited(user)) history.replace('/');
  }, [user]);

  return children;
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(RequireAccreditedUser);
