import { Button } from '@equitymultiple/react-eui';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { validateSchema } from 'utilities/yupValidations';

import { updateEmailSchema } from '../validation';

interface FormData {
  unconfirmed_email: string;
}

interface Props extends Partial<InjectedFormProps<FormData, Props>> {
  initialValues: {
    unconfirmedEmail?: boolean;
    unconfirmed_email?: string;
  };
  onSubmit: (values) => void;
  updatingUsername: boolean;
}

const SettingUpdateEmailForm = ({
  handleSubmit,
  initialValues,
  onSubmit,
  updatingUsername
}: Props) => {
  return (
    <form
      id="email_form"
      name="user"
      method="put"
      onSubmit={handleSubmit(onSubmit)}
    >
      {initialValues.unconfirmedEmail ? (
        <div>
          <p>
            Your request to update your email is registered. Confirmation link
            has been sent to your new email {initialValues.unconfirmedEmail}.
            Kindly confirm your new email to update.
          </p>
          <p>
            Press resend button to send the confirmation instructions again.
          </p>
        </div>
      ) : (
        <p>
          <strong className="text-blue">Note: </strong> You have to confirm your
          new email.
        </p>
      )}
      <Row>
        <Col lg={9} md={8}>
          <Field
            name="unconfirmed_email"
            component={ReduxFormInputField}
            id="unconfirmed_email"
            placeholder="Enter Your New Email"
            label="Email"
          />
        </Col>
        <Col lg={3} md={4}>
          <Button
            className="float-right"
            style={{ width: '140px' }}
            name="submit"
            type="submit"
            loading={updatingUsername}
          >
            {initialValues.unconfirmedEmail ? 'Resend' : 'Update'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm<FormData, Props>({
  form: 'updateEmail',
  touchOnBlur: false,
  validate: validateSchema(updateEmailSchema)
})(SettingUpdateEmailForm);
