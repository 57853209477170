import './CapitalCall.scss';

import { Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';

import history from '../../../../browserHistory';
import {
  createAdditionalInterest,
  getAdditionalAllocation,
  getUserCapitalCallsForInvestment,
  optIn,
  optOut
} from '../../../../redux/actions/capital-call';
import { loadInvestmentAccount } from '../../../../redux/actions/investment-account';
import { loadInvestment } from '../../../../redux/actions/investments';
import humane from '../../../../utilities/humane';
import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import CapitalCallAllocation from './CapitalCallAllocation';
import CapitalCallInterest from './CapitalCallInterest';

class CapitalCall extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    investment: PropTypes.shape({
      amount: PropTypes.string,
      capital_call_opt_in: PropTypes.bool,
      id: PropTypes.number,
      medium_banner_url: PropTypes.string,
      offering_id: PropTypes.number,
      offering_title: PropTypes.string,
      reference_id: PropTypes.number
    }),
    investmentAccount: PropTypes.shape({}),
    loading: PropTypes.bool,
    match: PropTypes.shape({
      params: PropTypes.shape({
        closing_id: PropTypes.string,
        investment_id: PropTypes.string,
        offering_id: PropTypes.string
      }),
      path: PropTypes.string
    }),
    sending: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.number,
      investor_profile: PropTypes.shape({
        representative_meeting_link: PropTypes.string
      })
    })
  };

  state = {
    step: 1,
    maxAdditionalAllocation: null,
    transaction: null
  };

  routeToFundPage = transaction => {
    const {
      match: { params }
    } = this.props;

    history.push(
      `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/${transaction.id}/fund`
    );
  };

  componentDidMount() {
    const {
      dispatch,
      match: { params, path }
    } = this.props;

    document.title = 'Capital Call | EquityMultiple';

    if (
      path ===
      '/invest/:closing_id/investment/:investment_id/capital_call/allocation'
    )
      this.setState({ step: 2 });

    dispatch(getUserCapitalCallsForInvestment(params.investment_id))
      .then(res => {
        if (res.length > 0) {
          res.forEach(transaction => {
            if (transaction.investment_id === parseInt(params.investment_id))
              if (
                transaction.status === 'posted' ||
                transaction.status === 'pending'
              ) {
                // Investor should not be allowed to reneter the capital call flow if they have already submitted payment
                history.replace(
                  `/invest/${params.closing_id}/investment/${params.investment_id}/confirm`
                );
              } else if (transaction.status === 'failed') {
                // Investor should only be allowed to update their payment method on the Fund step, they will not be able to make other adjustments
                this.routeToFundPage(transaction);
              } else {
                this.setState({ transaction: transaction });
              }
          });
        }
      })
      .catch(() => {
        history.push('/');
      });
    dispatch(loadInvestment(params.investment_id))
      .then(res => {
        dispatch(loadInvestmentAccount(res.account_reference_id));
      })
      .catch(() => {
        history.push('/');
      });
  }

  componentDidUpdate(prevProps) {
    const {
      match: { path }
    } = this.props;
    if (
      path ===
        '/invest/:closing_id/investment/:investment_id/capital_call/interest' &&
      prevProps.match.path !==
        '/invest/:closing_id/investment/:investment_id/capital_call/interest'
    )
      this.setState({ step: 1 });
  }

  onInterestSubmit = willOpt => {
    const {
      dispatch,
      match: { params }
    } = this.props;
    if (willOpt) {
      dispatch(optIn(params.investment_id)).then(() => {
        history.push(
          `/invest/${params.closing_id}/investment/${params.investment_id}/capital_call/allocation`
        );
        this.setState({ step: 2 });
      });
    } else {
      dispatch(optOut(params.investment_id)).then(() => {
        this.setState({ step: 3 }, () => window.scrollTo(0, 0));
      });
    }
  };

  onAllocationMount = () => {
    const {
      dispatch,
      match: { params }
    } = this.props;
    const { maxAdditionalAllocation } = this.state;
    if (maxAdditionalAllocation === null) {
      dispatch(getAdditionalAllocation(params.investment_id))
        .then(res => {
          if (res.total) {
            const total = parseFloat(res.total);
            this.setState({ maxAdditionalAllocation: total });
          } else {
            this.setState({ maxAdditionalAllocation: 0 });
          }
        })
        .catch(error => {
          humane.error(error.body.message);
        });
    }
  };

  onAllocationSubmit = amount => {
    const { dispatch, investment } = this.props;

    const { transaction } = this.state;

    const values = {
      amount,
      payment_method: 'ACH',
      effective_date: transaction.effective_date
    };
    if (amount !== 0) {
      dispatch(createAdditionalInterest(investment.id, values))
        .then(() => {
          this.routeToFundPage(transaction);
        })
        .catch(res => {
          humane.error(`${res.status_code} - ${res.body.error}`);
        });
    } else {
      this.routeToFundPage(transaction);
    }
  };

  handleBackClick = e => {
    e.preventDefault();
    this.setState({ step: 1 });
  };

  render() {
    const { investment, loading, sending, user } = this.props;
    const { maxAdditionalAllocation, step, transaction } = this.state;

    const offering = {
      title: investment?.offering_title,
      id: investment?.offering_id,
      medium_banner_url: investment?.medium_banner_url
    };

    return !investment || !transaction || loading ? (
      <EMLoadingIcon />
    ) : (
      <div id="capital-call" className="investment">
        <Container className="container-narrow">
          <h3>
            {investment.offering_title} <span>Capital Call</span>
          </h3>
          {step !== 3 && (
            <InvestmentStatus
              investment={investment}
              capitalCallTransaction={transaction}
              step={step}
              dateInvested={transaction.date_invested}
            />
          )}
          {step === 1 && (
            <CapitalCallInterest
              dateInvested={transaction.date_invested}
              investment={investment}
              offering={offering}
              optOut={!investment.capital_call_opt_in}
              onSubmit={this.onInterestSubmit}
              sending={sending}
              transaction={transaction}
              user={user}
            />
          )}
          {step === 2 && (
            <CapitalCallAllocation
              investment={investment}
              offering={offering}
              maxAmount={maxAdditionalAllocation}
              onMount={this.onAllocationMount}
              onSubmit={this.onAllocationSubmit}
              sending={sending}
              transaction={transaction}
            />
          )}
          {step === 3 && (
            <Card>
              <h2>Thank you!</h2>
              <p className="margin-xxx">
                We appreciate your involvement in this offering and will
                continue to provide you with updates as the project moves
                forward.
              </p>
              <Button
                style={{
                  width: '160px'
                }}
                to="/"
                className="float-right"
              >
                Done
              </Button>
              <button
                className="back float-right"
                onClick={this.handleBackClick}
                type="button"
              >
                Back
              </button>
            </Card>
          )}
          {step !== 3 && (
            <div className="border-box contact-box">
              <h3>Have Questions?</h3>
              <p>
                <a href="mailto:contact@equitymultiple.com">Email</a> or{' '}
                <a
                  href={user.investor_profile.representative_meeting_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a one on one call
                </a>{' '}
                with our real estate professionals.
              </p>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    investment: state.investments.investment,
    investmentAccount: state.investmentAccount.investmentAccountData,
    loading: state.investments.loading || state.capitalCall.loading,
    closing: state.offerings.closing,
    sending: state.capitalCall.sending,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(CapitalCall);
