import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  reset
} from 'redux-form';
import { Dispatch } from 'types/redux';

import {
  ReduxFormRadioField,
  ReduxFormSelectField
} from '../../../../components/ReduxFormFields';
import { generateAccountStatement } from '../../../../redux/actions/portfolio';
import EmAnalytics from '../../../../utilities/em_analytics';
import {
  dateRangeOptions,
  effectiveDateDescription,
  transferDateDescription
} from '../../constants';
import * as styles from './AccountStatementModal.module.scss';

type Params = {
  date_type: string;
  end_date?: string;
  start_date?: string;
};

interface Props {
  currentValues?: {
    dateRange?: string;
    dateType?: 'effective_date' | 'date_posted';
  };
  dispatch: Dispatch;
  open?: boolean;
  toggleOpen: () => void;
}

const AccountStatementModal = ({
  currentValues,
  dispatch,
  handleSubmit,
  open,
  toggleOpen
}: Props & InjectedFormProps<object, Props>) => {
  const onSubmit = values => {
    let dateRange;
    try {
      dateRange = JSON.parse(currentValues.dateRange);
    } catch {
      dateRange = currentValues.dateRange;
    }

    const params: Params = {
      date_type: values.dateType
    };

    if (currentValues.dateRange && currentValues.dateRange !== 'all') {
      params.start_date = dateRange.startDate;
      params.end_date = dateRange.endDate;
    }

    toggleOpen();

    dispatch(generateAccountStatement(params));

    EmAnalytics.track('Investor Generated Account Statement', '', {
      timePeriod: dateRange
    });
  };

  const handleClose = () => {
    toggleOpen();

    // Prevent seeing the fields change while the modal disappears
    setTimeout(() => {
      dispatch(reset('accountStatementModal'));
    }, 200);
  };

  const getDateTypeLabel = dateType =>
    dateType === 'date_posted' ? 'Transfer Date' : 'Effective Date';

  return (
    <Modal
      className={styles.modal}
      onClose={toggleOpen}
      open={open}
      title={<h6>Account Statement Download</h6>}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p className="margin-xx margin-top-xx">
          Download your account statement by selecting the time period:
        </p>
        <div className="margin-x">
          <Field
            type="radio"
            name="dateType"
            id="account-statement-transfer-date"
            value="date_posted"
            component={ReduxFormRadioField}
            label={transferDateDescription}
          />
        </div>
        <div className="margin-xx">
          <Field
            type="radio"
            name="dateType"
            id="account-statement-effective-date"
            value="effective_date"
            component={ReduxFormRadioField}
            label={effectiveDateDescription}
          />
        </div>
        <Field
          name="dateRange"
          id="account-statement-date-range"
          component={ReduxFormSelectField}
          label={getDateTypeLabel(currentValues.dateType)}
          options={dateRangeOptions}
        />
        <div className="button-wrap">
          <Button onClick={handleClose} variant="ghost">
            Cancel
          </Button>
          <Button type="submit" style={{ width: 180 }}>
            Download
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

function mapStateToProps(store) {
  const selector = formValueSelector('accountStatementModal');
  const currentValues = selector(store, 'dateRange', 'dateType');
  return {
    initialValues: {
      dateRange: 'all',
      dateType: 'date_posted'
    },
    currentValues
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'accountStatementModal',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false
  })(AccountStatementModal)
);
