import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import {
  change,
  Field,
  Form,
  getFormValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { Dispatch } from 'types/redux';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import RequireLoginWrap from '../components/RequireLoginWrap';
import {
  attributionAdvertisementOptions,
  attributionDetailSourceOptions,
  AttributionOption,
  attributionOptions,
  attributionResearchOptions
} from '../content';
import { discoverSchema } from '../validation';

interface FormValues {
  attribution?: AttributionOption;
  attribution_additional_influences?: string;
  attribution_advertisement_source?: string[];
  attribution_detail_source?: string;
  attribution_research_source?: string[];
}

export interface Props extends Partial<InjectedFormProps<FormValues, Props>> {
  dispatch: Dispatch;
  formValues: FormValues;
}

const Discover = ({
  dispatch,
  error,
  formValues,
  handleSubmit,
  submitting
}: Props) => {
  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';
  }, []);

  const onSubmit = values => {
    return dispatch(
      updateUserProfile({
        investor_profile_attributes: {
          ...values
        }
      })
    )
      .then(async () => {
        await dispatch(loadAuth());

        history.push('/users/signup/email_verify');
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const clearAttributionSubFields = () => {
    dispatch(change('discover', 'attribution_research_source', []));
    dispatch(change('discover', 'attribution_advertisement_source', []));
  };

  const {
    attribution,
    attribution_research_source: attributionResearchSource,
    attribution_advertisement_source: attributionAdvertisementSource
  } = formValues;

  const showAdditionalInfluences =
    (attribution === 'Online Research' &&
      attributionResearchSource?.length > 0) ||
    (attribution === 'Advertisements' &&
      attributionAdvertisementSource?.length > 0) ||
    attribution === 'Referred by Fellow Investor';

  return (
    <RequireLoginWrap>
      <AccountProgressContainer signupStage="sign up" showSkipLink>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="margin-x">How did you discover us?</h3>
          <p className="margin-xx">
            Your input here is deeply appreciated. Where you heard about
            EquityMultiple and what motivated you to sign up are essential
            insights for us to create better experiences for you and others.
          </p>

          <p>Where did you first learn about EquityMultiple?</p>
          <Field
            name="attribution"
            id="attribution"
            component={ReduxFormSelectField}
            placeholder="Select"
            options={attributionOptions.map(option => ({
              label: option,
              value: option
            }))}
            className="input-fixed-width-wide margin-xx"
            onChange={clearAttributionSubFields}
          />

          {attribution === 'Online Research' && (
            <>
              <p>Where did you conduct research?</p>
              <Field
                name="attribution_research_source"
                id="attribution_research_source"
                component={ReduxFormSelectField}
                placeholder="Type here"
                options={attributionResearchOptions}
                className="input-fixed-width-wide margin-xx"
                multi
                showMultiLabelsBelow
              />
            </>
          )}

          {attribution === 'Advertisements' && (
            <>
              <p>Where did you spot an EquityMultiple advertisement?</p>
              <Field
                name="attribution_advertisement_source"
                id="attribution_advertisement_source"
                component={ReduxFormSelectField}
                placeholder="Select"
                options={attributionAdvertisementOptions.map(option => ({
                  label: option,
                  value: option
                }))}
                className="input-fixed-width-wide margin-xx"
                multi
                showMultiLabelsBelow
              />
            </>
          )}

          {attribution === 'Marcus and Millichap Relationship' && (
            <>
              <p>Which describes you?</p>
              <Field
                name="attribution_detail_source"
                id="attribution_detail_source"
                component={ReduxFormSelectField}
                placeholder="Select"
                options={attributionDetailSourceOptions.map(option => ({
                  label: option,
                  value: option
                }))}
                className="input-fixed-width-wide margin-xx"
              />
            </>
          )}

          {showAdditionalInfluences && (
            <>
              <p>
                Did anything else influence your decision to start an account
                with EquityMultiple? (optional)
              </p>
              <Field
                id="attribution_additional_influences"
                component={ReduxFormInputField}
                name="attribution_additional_influences"
                placeholder="Type here"
                className="input-fixed-width-wide margin-xx"
                data-testid="additionalInfluences"
              />
            </>
          )}

          {error && <ErrorLabel message={error} className="margin-xxx" />}

          <div className="forwardBackButtonWrapCompact">
            <Button
              type="submit"
              loading={submitting}
              variant="orange"
              className="button-fixed-width margin-top-xx"
            >
              Continue
            </Button>
            <Button
              disabled={submitting}
              variant="outlined"
              className="button-fixed-width margin-top-xx arrowBackButton"
              wrapper={<Link to="/users/signup/phone" />}
              data-testid="backLink"
            >
              <Back />
            </Button>
          </div>
        </Form>
      </AccountProgressContainer>
    </RequireLoginWrap>
  );
};

function mapStateToProps(store) {
  let initialValues = {};

  if (store.auth.user) {
    const investorProfileAttributes = store.auth.user.investor_profile;

    initialValues = {
      attribution: investorProfileAttributes?.attribution,
      attribution_additional_influences:
        investorProfileAttributes?.attribution_additional_influences,
      attribution_advertisement_source:
        investorProfileAttributes?.attribution_advertisement_source || [],
      attribution_research_source:
        investorProfileAttributes?.attribution_research_source || [],
      attribution_detail_source:
        investorProfileAttributes?.attribution_detail_source
    };
  }

  return {
    initialValues,
    formValues: getFormValues('discover')(store) || {}
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'discover',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(discoverSchema)
  })(Discover)
);
