import { Button } from '@equitymultiple/react-eui';
import {
  ReduxFormCheckboxField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import {
  allResidencyStatuses,
  countryOptions
} from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { UpdateAlert } from '../types';
import { residenceSchema } from '../validation';

interface CustomProps {
  alert: UpdateAlert;
  handleSubmitAlert: (alert: UpdateAlert) => void;
  residenceStatus: string;
}

type Props = InjectedFormProps<object, CustomProps> & CustomProps;

const Residence = ({
  alert,
  handleSubmit,
  handleSubmitAlert,
  residenceStatus
}: Props) => {
  const onSubmit = values => {
    if (values.residence_status === 'U.S. Citizen')
      values.country_of_citizenship = 'United States';
    alert.action = values.residence_status;
    alert.values = values;
    handleSubmitAlert(alert);
  };

  const notUsCitizen = residenceStatus && residenceStatus !== 'U.S. Citizen';

  return (
    <>
      <h4 className="margin-xx">Residency Status</h4>
      <p className="size-16">Please confirm:</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="margin-xx">
          <Col sm={6}>
            <Field
              name="residence_status"
              id="residence-status"
              label="Residency Status"
              component={ReduxFormSelectField}
              options={allResidencyStatuses.map(key => ({
                label: key,
                value: key
              }))}
            />
          </Col>
          {notUsCitizen && (
            <Col sm={6}>
              <Field
                name="country_of_citizenship"
                id="country_of_citizenship"
                label="Country of Citizenship"
                component={ReduxFormSelectField}
                options={countryOptions}
              />
            </Col>
          )}
        </Row>
        {residenceStatus === 'Other' && (
          <div className="error-message margin-x">
            Foreign nationals can only invest with us through accredited US
            entities (either the entity itself has $5m+ in investable assets or
            all the members/owners of the entity qualify as accredited.)
          </div>
        )}
        {notUsCitizen && (
          <Field
            type="checkbox"
            name="accepted_residency_requirements"
            id="accepted_residency_requirements"
            className="margin-x"
            component={ReduxFormCheckboxField}
            label={<div>I intend to invest through a U.S. based entity</div>}
          />
        )}
        <Field
          type="checkbox"
          name="accepted_tos"
          id="accepted_tos"
          className="margin-xx"
          component={ReduxFormCheckboxField}
          label={
            <div>
              I agree to the latest{' '}
              <a
                href="https://www.equitymultiple.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://www.equitymultiple.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          }
        />
        <Button type="submit" style={{ width: '240px' }}>
          Update
        </Button>
      </form>
    </>
  );
};

const selector = formValueSelector('update_alert_residence');

function mapStateToProps(state) {
  const residenceStatus = selector(state, 'residence_status');

  return {
    residenceStatus
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, CustomProps>({
    form: 'update_alert_residence',
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(residenceSchema)
  })(Residence)
);
