import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import PhoneFields from 'components/forms/PhoneFields/PhoneFields';
import { ReduxFormCheckboxField } from 'components/ReduxFormFields';
import { PhoneFormValues } from 'containers/SignUp/types';
import Back from 'images/icons/arrow-back.svg';
import InfoIcon from 'images/icons/info.svg';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { phoneSchema } from '../../../validation';
import * as styles from './PhoneForm.module.scss';

export interface Props
  extends Partial<InjectedFormProps<PhoneFormValues, Props>> {
  onSubmit: (values: PhoneFormValues) => void;
}

const PhoneForm = ({ error, handleSubmit, onSubmit, submitting }: Props) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="margin-x">Provide your phone number</h3>
      <p className="margin-xx">
        Don't miss any updates with instant notifications that fit your
        schedule.
      </p>
      <PhoneFields />

      <div className={`${styles.disclaimer} input-fixed-width-wide`}>
        <InfoIcon />
        <p className="margin-xx size-12">
          By checking these boxes, you agree to receive text messages and/or
          phone calls sent by our offer marketing team. Agreeing to these terms
          is not a condition of purchase. EquityMultiple or its providers may
          send a max of 10 messages per month. Messages and data rates may
          apply. You may text STOP at any time to cancel. Text HELP for help.
          Read the full{' '}
          <a
            href="https://www.equitymultiple.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://www.equitymultiple.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>

      <Field
        name="opted_to_text"
        id="opted_to_text"
        component={ReduxFormCheckboxField}
        type="checkbox"
        className="margin-x"
        label="I am open to receiving SMS texts regarding offerings or my portfolio"
      />

      <Field
        name="enable_two_factor"
        id="enable_two_factor"
        component={ReduxFormCheckboxField}
        type="checkbox"
        className="margin-x"
        label="I want to improve the security of my account by turning on 2-factor authentication"
      />

      {error && <ErrorLabel message={error} className="margin-xxx" />}

      <div className="forwardBackButtonWrapCompact">
        <Button
          type="submit"
          loading={submitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
        <Button
          disabled={submitting}
          variant="outlined"
          className="button-fixed-width margin-top-xx arrowBackButton"
          wrapper={<Link to="/users/signup/continue" />}
        >
          <Back />
        </Button>
      </div>
    </Form>
  );
};

function mapStateToProps(store) {
  let initialValues = {};

  if (store.auth.user) {
    const { user } = store.auth;
    initialValues = {
      country_code: user.country_code || '1',
      phone: user.phone,
      opted_to_text: user.investor_profile?.opted_to_text,
      enable_two_factor: user.otp_required_for_login
    };
  }

  return {
    initialValues
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'phone',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(phoneSchema)
  })(PhoneForm)
);
