import history from 'browserHistory';
import { User } from 'types/api/user';

export const isAccredited = (user: User) => {
  if (user) {
    return (
      // Legacy users will be in the activate stage but not have the accreditation field set
      user.investor_profile.stage !== 'activate' ||
      (user.investor_profile.accreditation?.length > 0 &&
        user.investor_profile.accreditation[0] !== 'None')
    );
  }
  return false;
};

export const hasCompletedSignup = (user: User) =>
  // Legacy users will be in the activate stage but not have attribution or accreditation fields set
  user.investor_profile.stage !== 'activate' ||
  (!!user?.investor_profile?.attribution &&
    user?.confirmed &&
    user?.investor_profile?.accreditation.length > 0);

export const getLastCompletedStep = (user: User) => {
  let route = '/profile/document_identification';

  if (!user.first_name) {
    route = '/signup/password';
  } else if (!user.investor_profile?.accreditation.length) {
    route = '/signup/name';
  } else if (!user.phone) {
    route = '/signup/accreditation';
  } else if (!user.investor_profile?.attribution) {
    route = '/signup/phone';
  } else if (!user.confirmed) {
    route = '/signup/discover';
  } else if (!user.investor_profile?.investment_experience?.length) {
    route = '/signup/email_verify';
  } else if (!user.investor_profile?.investment_reason?.length) {
    route = '/profile/experience';
  } else if (!user.investor_profile?.risk_tolerance) {
    route = '/profile/objectives';
  } else if (user.middle_name === null) {
    route = '/profile/tolerance';
  } else if (
    !user.investor_profile?.ssn ||
    !user.investor_profile?.occupation
  ) {
    route = '/profile/name';
  } else if (!user.address?.address) {
    route = '/profile/kyc';
  } else if (!user.id_document?.name) {
    route = '/profile/address';
  }

  return route;
};

export const isInActivate = (user: User) =>
  user.investor_profile.stage === 'activate';

export const hasCompletedProfile = (user: User) =>
  hasCompletedSignup(user) &&
  getLastCompletedStep(user) === '/profile/document_identification';

// Excludes legacy users beyond the activate stage who are missing profile fields
export const mustCompleteProfile = (user: User) =>
  isInActivate(user) && !hasCompletedProfile(user);

export const canAccessPage = (page, user: User) => {
  if (!user) return false;
  const stage = user.investor_profile.stage;
  const confirmed = user.confirmed;

  switch (page) {
    case 'activity':
    case 'documents':
    case 'investment':
      return stage !== 'activate';
    case 'portfolio':
      return confirmed && isAccredited(user);
    case 'accounts':
      return !mustCompleteProfile(user) && isAccredited(user);
    case 'settings':
      return confirmed;
    default:
      return false;
  }
};

export const canInvest = (user: User) =>
  user?.investor_profile.stage === 'invest' ||
  user?.investor_profile.stage === 'multiply' ||
  false;

export const hasTwoFaEnabled = (user: User) =>
  user?.otp_required_for_login || false;

// Checks whether the user is in a state where they must now enable 2FA
export const twoFaSetupRequired = (user: User) => {
  if (process.env.REACT_APP_TWO_FACTOR_AUTH_DISABLED === 'true' || !user)
    return false;

  const userCanInvest = canInvest(user);
  const userHasTwoFaEnabled = hasTwoFaEnabled(user);
  // 2 FA setup is not required for users who just reached the invest stage in their current session, only on their next login
  const userReachedActivateInPreviousSession =
    user.investor_profile.activated_in_previous_session;

  return (
    !userHasTwoFaEnabled &&
    userCanInvest &&
    userReachedActivateInPreviousSession
  );
};

// Users who set an email and password but abandoned signup before completing all required forms
// They're logged in, but not authorized to access pages outside of the initial sign up forms
// We send them to the last required sign up form and let a helper function there redirect them to the correct form
export const redirectUsersWithIncompleteSignup = (user: User) => {
  if (
    user?.id &&
    !user?.confirmed &&
    !user?.investor_profile?.accreditation.length
  ) {
    history.replace('/users/signup/accreditation');
  }
};
