// extracted by mini-css-extract-plugin
var _1 = "eXqYkpWmolSTT1QGLGIG";
var _2 = "YdUZhMQZnjzhqOO9A1oz";
var _3 = "NTKuPiZP69ldarArtxqV";
var _4 = "K5dtL5lB81fmFBcJow2A";
var _5 = "zbJY_3otZ046AIy4dIH6";
var _6 = "RDrlMCZWIGgRAkPfr4by";
var _7 = "zz3hNi3fevoD7oYT12rX";
var _8 = "MTX4tBDu0UVySsi8G2HU";
var _9 = "UJZt18kNIA8k80bZ6GFw";
export { _1 as "accountDescription", _2 as "accountDetails", _3 as "accountsTable", _4 as "accreditationSuccessModal", _5 as "notVerified", _6 as "pending", _7 as "statusWrap", _8 as "verified", _9 as "verifyButton" }
