import { Card, Tab, TabPanel, Tabs, TabsList } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { Dispatch } from 'types/redux';

import history from '../../../browserHistory';
import { clearAccount, getAccount } from '../../../redux/actions/portfolio';
import { accountStatus } from '../../Accounts/contents';
import InvestmentSection from '../components/InvestmentSection/InvestmentSection';
import Overview from '../components/Overview/Overview';
import PaymentsTable from '../components/PaymentsTable/PaymentsTable';
import { Account as AccountType } from '../types';
import * as styles from './Account.module.scss';

interface HistoryState {
  activePortfolioTab?: string;
}

interface Props {
  account: AccountType;
  dispatch: Dispatch;
  loaded: boolean;
  loading: boolean;
  match: {
    params: {
      account_id: string;
    };
  };
}

const Account = ({ account, dispatch, match, loading, loaded }: Props) => {
  useEffect(() => {
    document.title = 'Account | EquityMultiple';

    const accountId = match.params.account_id;

    dispatch(getAccount(accountId))
      .then(res => {
        if (res.status !== accountStatus.created) history.replace('/portfolio');
      })
      .catch(() => history.replace('/portfolio'));

    return () => {
      dispatch(clearAccount());
    };
  }, [dispatch, match.params.account_id]);

  const getPaymentsInvestmentOptions = () => {
    let options = null;

    if (account?.investments) {
      const { earn, exited, grow, keep } = account.investments;
      const investmentPayments = [].concat(
        ...earn,
        ...exited,
        ...grow,
        ...keep
      );
      options = investmentPayments.map(investment => ({
        value: investment.id,
        label: investment.offering.title
      }));
      options.sort((a, b) => (a.label > b.label ? 1 : -1));
      options.unshift({
        value: 'all',
        label: 'All investments'
      });
    }

    return options;
  };

  const getTab = section => {
    let investmentCount = 0;
    let label: React.ReactNode | string = '';
    switch (section) {
      case 'keep':
        investmentCount = account?.investments?.keep.length;
        label = loading ? 'Keep' : `Keep (${investmentCount})`;
        break;
      case 'earn':
        investmentCount = account?.investments?.earn.length;
        label = loading ? 'Earn' : `Earn (${investmentCount})`;
        break;
      case 'grow':
        investmentCount = account?.investments?.grow.length;
        label = loading ? 'Grow' : `Grow (${investmentCount})`;
        break;
      case 'exited':
        investmentCount = account?.investments?.exited.length;
        label = loading ? 'Exited' : `Exited (${investmentCount})`;
        break;
    }

    if (section !== 'exited') {
      label = (
        <span className={`${styles.pillarIconLabel} ${styles[section]}`}>
          {label}
        </span>
      );
    }

    const disabled = loading || investmentCount === 0;

    return {
      disabled,
      label
    };
  };

  const getInitialSelectedTabIndex = () => {
    let tabIndex = 0;

    // If the user left the page with a tab other than the first active then set that tab to be active
    const lastActiveTab: string = (history.location?.state as HistoryState)
      ?.activePortfolioTab;

    if (lastActiveTab) {
      tabIndex = Number(lastActiveTab);
    } else if (account?.investments) {
      const investments = account.investments;
      tabIndex = Object.keys(investments).findIndex(
        key => investments[key].length > 0
      );
    }

    return tabIndex;
  };

  const handleTabClick = tabIndex => {
    window.history.replaceState(
      { key: history.location.key, state: { activePortfolioTab: tabIndex } },
      ''
    );
  };

  const investmentOptions = getPaymentsInvestmentOptions();
  const keep = loaded ? account?.investments?.keep : [];
  const earn = loaded ? account?.investments?.earn : [];
  const grow = loaded ? account?.investments?.grow : [];
  const exited = loaded ? account?.investments?.exited : [];

  const overviewAccountDetails = {
    id: account?.id,
    entity_name: account?.entity_name,
    type: account?.type,
    reference_id: account?.reference_id
  };

  const keepTab = getTab('keep');
  const earnTab = getTab('earn');
  const growTab = getTab('grow');
  const exitedTab = getTab('exited');

  return (
    <>
      <Overview
        heading={account?.entity_name}
        metrics={account?.metrics}
        loading={loading}
        account={overviewAccountDetails}
        page="account"
      />
      <Container>
        <Tabs
          defaultValue={getInitialSelectedTabIndex()}
          onChange={(_, newTabIndex) => handleTabClick(newTabIndex)}
        >
          <TabsList className={styles.tabsList} collapseBreakpoint="md">
            <Tab value={0} disabled={keepTab.disabled}>
              {keepTab.label}
            </Tab>
            <Tab value={1} disabled={earnTab.disabled}>
              {earnTab.label}
            </Tab>
            <Tab value={2} disabled={growTab.disabled}>
              {growTab.label}
            </Tab>
            <Tab value={3} disabled={exitedTab.disabled}>
              {exitedTab.label}
            </Tab>
          </TabsList>
          <TabPanel value={0}>
            <InvestmentSection
              section="keep"
              investments={keep}
              loading={loading}
              key="keep"
            />
          </TabPanel>
          <TabPanel value={1}>
            <InvestmentSection
              section="earn"
              investments={earn}
              loading={loading}
              key="earn"
            />
          </TabPanel>
          <TabPanel value={2}>
            <InvestmentSection
              section="grow"
              investments={grow}
              loading={loading}
              key="grow"
            />
          </TabPanel>
          <TabPanel value={3}>
            <InvestmentSection
              section="exited"
              investments={exited}
              loading={loading}
              key="exited"
            />
          </TabPanel>
        </Tabs>
        <Card className="padding0">
          <PaymentsTable
            accountIds={[+match.params.account_id]}
            investmentOptions={investmentOptions}
          />
        </Card>
      </Container>
    </>
  );
};

function mapStateToProps(store) {
  return {
    account: store.portfolio.account,
    loaded: store.portfolio.loadedAccount,
    loading: store.portfolio.loadingAccount
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Account);
