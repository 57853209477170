import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'types/redux';

import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import { getArticles } from '../../../../redux/actions/portfolio';
import * as styles from './Articles.module.scss';

interface Props {
  articles: {
    link: string;
    title: {
      rendered: string;
    };
  }[];
  dispatch: Dispatch;
  loaded: boolean;
  loading: boolean;
}

const Articles = ({ articles, dispatch, loading, loaded }: Props) => {
  useEffect(() => {
    if (!loaded) dispatch(getArticles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getArticleCard = index => {
    const article = articles[index];
    const showCard = loading || article;
    const articleLink = article?.link.replace(
      'cms.eqm-int.com',
      'equitymultiple.com'
    );

    return (
      showCard && (
        <ArticleCard
          loading={loading}
          link={articleLink}
          title={article?.title.rendered}
          className={styles.articleCard}
        />
      )
    );
  };

  const hasArticles = loaded && articles?.length > 0;
  const showArticleCards = loading || hasArticles;

  return showArticleCards ? (
    <div className={styles.articles}>
      <ArticleCard
        loading={loading}
        link="http://www.equitymultiple.com/wp-content/uploads/2023/01/Whitepaper-investments-overview-2.pdf"
        title="Behind the Screen: Investment Selection at EquityMultiple"
        className={styles.articleCard}
      />
      <ArticleCard
        loading={loading}
        link="https://www.equitymultiple.com/wp-content/uploads/2023/01/The-Lifecycle_Of_An_EquityMultiple_Investment.pdf"
        title="Asset Management & the Lifecycle of an EM Investment"
        className={styles.articleCard}
      />
      {getArticleCard(0)}
    </div>
  ) : null;
};

function mapStateToProps(store) {
  return {
    loading: store.portfolio.loadingArticles,
    loaded: store.portfolio.loadedArticles,
    articles: store.portfolio.articles
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Articles);
