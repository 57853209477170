import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Field, WrappedFieldProps } from 'redux-form';

import { ReduxFormInputField } from '../../../../../../components/ReduxFormFields';
import { SignatureStyle } from '../../../../../../types/api/investment';
import utils from '../../../../../../utilities/utils';
import { SignFormValues } from '../../types';
import * as styles from './Signature.module.scss';

const ReduxFormSimpleRadioField = ({ input, ...props }: WrappedFieldProps) => {
  delete props.meta;
  return <input {...input} {...props} />;
};

interface Props {
  completedName?: string;
  formValues?: SignFormValues;
  signatureStyle?: SignatureStyle;
}

const Signature = ({ completedName, formValues, signatureStyle }: Props) => {
  let selectedSignatureStyle = 'Kalam';
  if (signatureStyle) selectedSignatureStyle = signatureStyle;
  else if (formValues?.user_name)
    selectedSignatureStyle = formValues.signature_style;

  const renderedSignatureClassName =
    styles[utils.camelCase(selectedSignatureStyle)];
  const signatureName = formValues?.user_name || completedName || '-';

  return (
    <div>
      {!completedName && (
        <Row>
          <Col md={5}>
            <Field
              name="user_name"
              id="user_name"
              component={ReduxFormInputField}
              label="Signature"
            />
          </Col>
          <Col md={7}>
            <div className={styles.radioButtons}>
              <div className={styles.radioWrap}>
                <Field
                  type="radio"
                  name="signature_style"
                  id="kalam"
                  value="Kalam"
                  component={ReduxFormSimpleRadioField}
                />
                <label className={styles.kalam} htmlFor="kalam">
                  Basic
                </label>
              </div>
              <div className={styles.radioWrap}>
                <Field
                  type="radio"
                  name="signature_style"
                  id="theNautigal"
                  value="The Nautigal"
                  component={ReduxFormSimpleRadioField}
                />
                <label className={styles.theNautigal} htmlFor="theNautigal">
                  Classic
                </label>
              </div>
              <div className={styles.radioWrap}>
                <Field
                  type="radio"
                  name="signature_style"
                  id="jomolhari"
                  value="Jomolhari"
                  component={ReduxFormSimpleRadioField}
                />
                <label className={styles.jomolhari} htmlFor="jomolhari">
                  Modern
                </label>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <div
        className={`${styles.renderedSignature} ${renderedSignatureClassName}`}
        data-testid="renderedSignature"
      >
        {signatureName}
      </div>
    </div>
  );
};

export default Signature;
