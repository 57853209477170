import { validations, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields } = validations;

export const setPasswordSchema = yup.object().shape({
  confirmPassword: yupString.test({
    name: 'Matches password',
    test(value) {
      const { password } = this.parent;

      const hasError = value !== password;

      return hasError
        ? this.createError({
            message: 'Does not match the password entered above',
            path: 'confirmPassword'
          })
        : true;
    }
  }),
  password: fields.password
});
