import { Alert } from '@equitymultiple/react-eui';
import RecoveryCodes from 'components/RecoveryCodes/RecoveryCodes';
import SmsConfirmationForm from 'components/SmsConfirmationForm/SmsConfirmationForm';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import {
  clearRecoveryCodes,
  regenerateRecoveryCodes,
  requestPhoneVerificationCode,
  verifyPhoneVerificationCode
} from 'redux/actions/user-settings';
import { InjectedFormProps } from 'redux-form';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import humane from 'utilities/humane';
import { hasTwoFaEnabled } from 'utilities/user';
import { throwSubmissionErrors } from 'utilities/validation';

import { PhoneFormValues } from '../types';
import PhoneForm from './PhoneForm';

interface FormValues {
  country_code: string;
  phone: string;
}

interface Props extends Partial<InjectedFormProps<FormValues, Props>> {
  dispatch: Dispatch;
  loadingRecoveryCodes: boolean;
  recoveryCodes: string[];
  user: User;
}

const TwoFactorAuthentication: React.FC<Props> = ({
  recoveryCodes,
  dispatch,
  loadingRecoveryCodes,
  user
}) => {
  const [showPhoneForm, setShowPhoneForm] = useState(true);
  const [showSmsConfirmationForm, setShowSmsConfirmationForm] = useState(false);
  const [phoneFormValues, setPhoneFormValues] = useState<PhoneFormValues>();
  const [twoFaNowEnabled, setTwoFaNowEnabled] = useState(false);

  useRecaptcha();

  useEffect(() => {
    if (hasTwoFaEnabled(user)) {
      setShowPhoneForm(false);
    }
  }, [user]);

  const setTwoFaEnabledState = () => {
    setShowSmsConfirmationForm(false);
    setTwoFaNowEnabled(true);
  };

  const getFormattedPhoneNumber = () => {
    if (phoneFormValues) {
      return `+${phoneFormValues.country_code} ${phoneFormValues.phone}`;
    }
    return '';
  };

  const submitPhoneForm = async values => {
    const token = await getRecaptchaToken('requestSmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        phone: values.phone,
        country_code: values.country_code
      }
    };

    return dispatch(requestPhoneVerificationCode(submitValues))
      .then(() => {
        setPhoneFormValues(values);
        setShowPhoneForm(false);
        setShowSmsConfirmationForm(true);
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const submitSmsConfirmationForm = async values => {
    const token = await getRecaptchaToken('verifySmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        code: values.code,
        enable_two_factor: true,
        ...phoneFormValues
      }
    };

    return dispatch(verifyPhoneVerificationCode(submitValues))
      .then(() => {
        dispatch(loadAuth());
        setTwoFaEnabledState();
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const handleResendCode = async () => {
    const token = await getRecaptchaToken('requestSmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        phone: phoneFormValues.phone,
        country_code: phoneFormValues.country_code
      }
    };

    return dispatch(requestPhoneVerificationCode(submitValues))
      .then(() => {
        humane.notice(`Code resent to ${getFormattedPhoneNumber()}`);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const toggleShowPhoneForm = () => {
    setShowPhoneForm(!showPhoneForm);

    // If the user has already generated backup codes in this session then clear them to prevent cluttering the form
    dispatch(clearRecoveryCodes());
  };

  const handleRegenerateRecoveryCodes = () => {
    dispatch(regenerateRecoveryCodes());
  };

  const smsEnabled = user?.otp_required_for_login;
  const twoFaEnabled = hasTwoFaEnabled(user);
  const twoFaEnabledDefaultState =
    twoFaEnabled &&
    !twoFaNowEnabled &&
    !showPhoneForm &&
    !showSmsConfirmationForm;
  const hasRecoveryCodes = recoveryCodes?.length > 0;

  return (
    <>
      <div className="margin-xxx">
        {twoFaEnabled ? (
          <Alert type="positive">
            {twoFaNowEnabled
              ? 'Thank you, two-factor authentication is now enabled through SMS.'
              : 'Two-factor authentication is enabled through SMS.'}
          </Alert>
        ) : (
          <Alert type="warning">
            <strong>
              In order to secure your account, you're required to enable
              two-factor authentication through SMS before proceeding.
            </strong>
          </Alert>
        )}
      </div>

      {twoFaEnabledDefaultState && (
        <>
          {smsEnabled && (
            <button
              className="text-link underline margin-xx"
              onClick={toggleShowPhoneForm}
              type="button"
            >
              Change Phone Number
            </button>
          )}
          {!hasRecoveryCodes && (
            <>
              <p>
                You may regenerate recovery codes that can be used to access
                your account if you&apos;re unable to retrieve a one-time
                password.
              </p>
              <p>
                Existing recovery codes will be invalidated and can no longer be
                used.
              </p>
              <button
                className={`text-link underline ${
                  loadingRecoveryCodes ? 'loading' : ''
                }`}
                onClick={handleRegenerateRecoveryCodes}
                type="button"
                disabled={loadingRecoveryCodes}
              >
                Regenerate Recovery Codes
              </button>
            </>
          )}
        </>
      )}

      {showPhoneForm && <PhoneForm onSubmit={submitPhoneForm} />}

      {showSmsConfirmationForm && (
        <SmsConfirmationForm
          onSubmit={submitSmsConfirmationForm}
          phoneNumber={getFormattedPhoneNumber()}
          changePhone={() => setShowSmsConfirmationForm(false)}
          resendCode={handleResendCode}
        />
      )}

      {hasRecoveryCodes && <RecoveryCodes recoveryCodes={recoveryCodes} />}

      {showPhoneForm && twoFaEnabled && (
        <button
          className="text-link underline margin-top-xx"
          onClick={toggleShowPhoneForm}
          type="button"
        >
          Cancel
        </button>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    recoveryCodes: state.userSettings.twoFactorRecoveryCodes,
    loading: state.userSettings.loading,
    loadingRecoveryCodes: state.userSettings.loadingRecoveryCodes,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(TwoFactorAuthentication);
