import { ReduxFormCheckboxField } from 'components/ReduxFormFields';
import RichText from 'components/RichText';
import React from 'react';
import { Field } from 'redux-form';

interface Props {
  instructions: string;
}

const CustomFundingInstructions = ({ instructions }: Props) => {
  return (
    <>
      <h6>Funding Instructions</h6>
      <RichText text={instructions} sanitize />

      <Field
        name="payment_method"
        id="custom_funding_agreement"
        type="checkbox"
        component={ReduxFormCheckboxField}
        label="I understand that in order to participate in this offering, I must make accommodations to fund according to the funding instructions above."
      />
    </>
  );
};

export default CustomFundingInstructions;
