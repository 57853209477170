import { ActiveOfferingClosing, Closing } from 'types/api/closing';
import { Investment } from 'types/api/investment';

export const getOfferingCardValue = (
  closing: ActiveOfferingClosing | Closing
) => {
  if (closing?.investment) {
    const {
      status,
      transaction_status: transactionStatus,
      is_reinvestment: isReinvestment,
      is_funded: isFunded
    } = closing.investment;

    if (status === 'interest') {
      return 'Complete Your Investment';
    }

    if (status === 'waitlist') {
      if (closing.stage === 'interest') {
        return 'View Pledge';
      }
      return 'You Are On The Waitlist';
    }

    if (status === 'signed') {
      if (transactionStatus === 'failed') {
        return 'Update Payment';
      }
      if (transactionStatus === 'pending' && (isReinvestment || isFunded)) {
        return 'Review Your Investment';
      }
      return 'Complete Your Investment';
    }

    if (status === 'funded') {
      if (transactionStatus === 'pending') {
        return 'Investment Pending';
      }
      if (transactionStatus === 'posted' || isFunded) {
        return 'Review Your Investment';
      }
      return 'Complete Your Investment';
    }
  }
  return 'View Offering';
};

export const getInvestmentWizardRoute = (
  closingId: number | string,
  investment: Investment
) => {
  const {
    documentation_status: documentationStatus,
    status,
    transaction_status: transactionStatus,
    is_reinvestment: isReinvestment,
    is_funded: isFunded
  } = investment;

  const route = `/invest/${closingId}/investment/${investment.id}/`;
  let subRoute = '';

  if (status === 'interest') {
    subRoute = 'interest';

    if (documentationStatus === 'To Do') {
      subRoute = 'sign';
    }
  }

  if (status === 'waitlist') {
    subRoute = 'interest';
  }

  if (status === 'signed') {
    subRoute = 'fund';

    if (transactionStatus === 'pending' && (isReinvestment || isFunded)) {
      subRoute = 'confirm';
    }
  }

  if (status === 'funded') {
    subRoute = 'confirm';
  }

  return route + subRoute;
};
