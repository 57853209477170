import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

import { ReduxFormRadioField } from '../../../components/ReduxFormFields';
import { supportsBeneficialOwners } from '../../Accounts/helpers';

export const AccountSelection = accounts =>
  accounts.map((account, index) => {
    const pending =
      account.type === 'entity' &&
      supportsBeneficialOwners(account) &&
      account.needs_update;
    const accountUpdateLink = (
      <Link
        to={`/accounts/update/${account.type}/rep/new/${account.plain_reference_id}`}
      >
        [Confirm/Add Beneficial Owners]
      </Link>
    );
    const label = (
      <span>
        {account.entity_name} (
        {account.type === 'ira'
          ? 'IRA'
          : account.type.charAt(0).toUpperCase() + account.type.slice(1)}
        ) {pending ? accountUpdateLink : ''}
      </span>
    );

    return (
      <Field
        key={account.id}
        name="investment_account"
        id={`${account.id}`}
        component={ReduxFormRadioField}
        type="radio"
        disabled={pending}
        value={`${account.id}`}
        label={label}
        hideError={index !== accounts.length - 1}
      />
    );
  });
