import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormCheckboxField,
  ReduxFormDateSelectField,
  ReduxFormFileUploaderField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Field } from 'redux-form';
import { AccountDocument } from 'types/api/account';
import { maxUploadSize } from 'utilities/constants';

import { idTypes, nonUSResidentIds } from '../../contents';
import { getIdTypeLabel } from '../../helpers';

interface Props {
  existingDocument: AccountDocument;
  idType?: string;
  isSecondaryAccountHolder?: boolean;
  onRemove?: () => void;
  onUpload?: (file: File) => void;
  residenceStatus?: string;
  review?: boolean;
  uploading?: boolean;
}

const IdentificationDocumentFields = ({
  existingDocument,
  idType,
  isSecondaryAccountHolder,
  onRemove,
  onUpload,
  residenceStatus,
  uploading,
  review
}: Props) => {
  const [hasNewDoc, setHasNewDoc] = useState(false);
  const fieldPrefix = isSecondaryAccountHolder
    ? 'investment_account.account_holder_2_'
    : '';

  const acceptedFileTypes = ['JPG', 'PNG'];
  const expirationDateFormatted =
    existingDocument?.expiry &&
    moment.utc(existingDocument.expiry).format('MM/DD/YYYY');
  const documentType =
    existingDocument?.type && getIdTypeLabel({ type: existingDocument.type });

  const handleEdit = () => {
    history.push('/accounts/wizard/identification_document');
  };

  const handleRemove = () => {
    setHasNewDoc(true);
    onRemove();
  };

  const handleUpload = file => {
    setHasNewDoc(true);
    onUpload(file);
  };

  return (
    <>
      {review && <h5>Personal Identification</h5>}
      <Row className="margin-row">
        <Col md={12}>
          <Field
            name={`${fieldPrefix}id_document`}
            id="id_document"
            component={ReduxFormFileUploaderField}
            acceptedFileTypes={acceptedFileTypes}
            existingFile={!hasNewDoc && existingDocument}
            onRemove={handleRemove}
            upload={handleUpload}
            uploading={uploading}
            showImage={!review}
            maxSize={maxUploadSize}
            hideRemove={review}
          />
        </Col>
      </Row>
      {review ? (
        <Row className="margin-row">
          <Col md={4}>
            <div className="text-label">Document Type</div>
            <div className="text-dark margin-xx">{documentType}</div>
          </Col>
          <Col md={4}>
            <div className="text-label">Document Expiration Date</div>
            <div className="text-dark margin-x">{expirationDateFormatted}</div>
          </Col>
          <Col md={4}>
            <Button
              style={{ width: '160px' }}
              variant="outlined"
              onClick={handleEdit}
            >
              Edit
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Field
            name={`${fieldPrefix}id_type`}
            id="id_type"
            component={ReduxFormSelectField}
            label="Document Type"
            options={
              residenceStatus === 'Other'
                ? nonUSResidentIds.map(key => ({
                    label: key.label,
                    value: key.value
                  }))
                : idTypes.map(key => ({
                    label: key.label,
                    value: key.value
                  }))
            }
          />
          <Field
            name={`${fieldPrefix}id_expiration`}
            id="id_expiration"
            component={ReduxFormDateSelectField}
            futureDate
            label="Expiration Date"
          />

          {isSecondaryAccountHolder && (
            <Field
              name={`${fieldPrefix}dob`}
              id="dob"
              component={ReduxFormDateSelectField}
              label="Date of Birth"
            />
          )}

          {idType === 'passport' && (
            <Field
              name={`${fieldPrefix}passport`}
              id="passport"
              component={ReduxFormInputField}
              label="Passport Number"
            />
          )}
          <Field
            type="checkbox"
            name={`${fieldPrefix}confirm_valid_id`}
            className="margin-xx"
            id="confirm_valid_id"
            component={ReduxFormCheckboxField}
            label="I confirm that my identification document is not expired and the document is a color-copy."
            checked
          />
        </>
      )}
    </>
  );
};

export default IdentificationDocumentFields;
