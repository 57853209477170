import { validations } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields, tests } = validations;

export const personalAddressVerificationSchema = yup.object().shape({
  user_address_verification: yup.object().shape({
    address: fields.address.test(tests.noPoBox),
    address2: fields.address2.test(tests.noPoBox),
    city: fields.city,
    phone: fields.phone,
    postal_code: fields.postalCode.required('Postal code is required'),
    state: fields.state
  })
});

export const entityAddressVerificationSchema = yup.object().shape({
  investment_account: yup.object().shape({
    address: fields.address.test(tests.noPoBox),
    address2: fields.address2.test(tests.noPoBox),
    city: fields.city,
    postal_code: fields.postalCode.required('Postal code is required'),
    region: fields.state
  })
});
