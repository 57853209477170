import {
  Button,
  Checkbox,
  ErrorLabel,
  Input,
  RadioButton
} from '@equitymultiple/react-eui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';

import utils from '../../../../utilities/utils';

export default class CapitalCallInterest extends Component {
  static propTypes = {
    dateInvested: PropTypes.string,
    investment: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date_invested: PropTypes.string,
      id: PropTypes.number
    }),
    offering: PropTypes.shape({
      id: PropTypes.number,
      medium_banner_url: PropTypes.string,
      title: PropTypes.string
    }),
    onSubmit: PropTypes.func,
    optOut: PropTypes.bool,
    sending: PropTypes.bool,
    transaction: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    user: PropTypes.shape({})
  };

  state = {
    acceptedDisclaimer: false,
    optIn: true,
    optInDescriptionVisible: true,
    optOutDescriptionVisible: false,
    error: null
  };

  componentDidMount() {
    const { optOut } = this.props;
    if (optOut) {
      this.setState({
        optIn: false,
        optInDescriptionVisible: false,
        optOutDescriptionVisible: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { acceptedDisclaimer } = this.state;
    if (acceptedDisclaimer && !prevState.acceptedDisclaimer)
      this.setState({ error: null });
  }

  handleCheckClick = () => {
    const { acceptedDisclaimer } = this.state;
    this.setState({ acceptedDisclaimer: !acceptedDisclaimer });
  };

  handleOptIn = () => {
    this.setState({
      optIn: true,
      optInDescriptionVisible: true,
      optOutDescriptionVisible: false
    });
  };

  handleOptOut = () => {
    this.setState({
      optIn: false,
      optInDescriptionVisible: false,
      optOutDescriptionVisible: true
    });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { acceptedDisclaimer, optIn } = this.state;

    this.setState({ error: null });

    if (optIn && !acceptedDisclaimer)
      this.setState({
        error: 'You must confirm that you understand what the capital call is.'
      });
    else onSubmit(optIn);
  };

  render() {
    const { dateInvested, offering, investment, sending, transaction } =
      this.props;
    const {
      acceptedDisclaimer,
      error,
      optIn,
      optInDescriptionVisible,
      optOutDescriptionVisible
    } = this.state;
    const ccAmount = parseFloat(transaction.amount);

    return (
      <div id="capital-call-interest">
        <div
          id="hero-capital-call"
          style={{ backgroundImage: `url(${offering.medium_banner_url})` }}
        >
          <div className="text-wrap">
            <h1>Capital Call</h1>
            <div className="date">
              Original Investment on {utils.dateFormat(dateInvested)}
            </div>
            <Input
              label="Amount (Pro Rata)"
              value={utils.formatCurrency(ccAmount)}
              onBlur={() => {}}
              disabled
            />
          </div>
        </div>
        <div id="info" className="border-box">
          <h2 className="margin-xxx">What is a Capital Call?</h2>
          <p>
            For a wide variety of reasons, real estate projects can require
            additional cash in order to pay for unexpected costs. In some cases,
            the Sponsor or Lender that EquityMultiple investors are investing
            alongside will have the right to request additional investment from
            investors through a capital call. While the circumstances and
            conditions of such capital call will depend on both the structure of
            the investment (i.e., equity vs debt) and particularities of the
            transaction, certain features are typically consistent. Investors
            will be asked to contribute their pro rata share (based upon their
            percentage interest in the investment at the time of such request)
            of such capital call. Investors may decline to participate in the
            capital call but may ultimately see their percentage ownership in
            the overall project diluted, sometimes at a penalty rate. In some
            cases, capital calls can increase the value of the project and,
            therefore, your investment and, in other cases, they are done to
            help minimize the potential for loss. It is important to understand
            the rationale for the capital call and the value it aims to create
            or preserve.
          </p>
        </div>
        <Row className="box-row">
          <Col xs={4} className="border-box value-box interest-value-box">
            <div className="text-label">
              Original <br className="mobile-br" />
              Investment
            </div>
            <div className="amount">
              {utils.formatCurrency(investment.amount)}
            </div>
          </Col>
          <Col xs={4} className="border-box value-box cc-amount-box">
            <div className="text-label">Capital Call Amount</div>
            <div className="amount">{utils.formatCurrency(ccAmount)}</div>
          </Col>
          <Col xs={4} className="border-box value-box interest-value-box">
            <div className="text-label text-blue">
              New <br className="mobile-br" />
              Investment
            </div>
            <div className="amount text-blue">
              {utils.formatCurrency(parseFloat(investment.amount) + ccAmount)}
            </div>
          </Col>
        </Row>
        <Row className="box-row mobile-button-row">
          <Col xs={6} className="button-column">
            <button
              className={`radio-description-button border-box ${
                optInDescriptionVisible ? 'selected' : ''
              }`}
              onClick={this.handleOptIn}
              type="button"
            >
              Reasons to participate
            </button>
          </Col>
          <Col xs={6} className="button-column">
            <button
              className={`radio-description-button border-box ${
                optOutDescriptionVisible ? 'selected' : ''
              }`}
              onClick={this.handleOptOut}
              type="button"
            >
              If you decline...
            </button>
          </Col>
        </Row>
        <div className="mobile-radio-description border-box">
          {optInDescriptionVisible && (
            <ul>
              <li>Maintain same ownership percentage as a limited partner</li>
              <li>Increase your original investment</li>
              <li>
                Help increase or protect the current value of your investment
              </li>
            </ul>
          )}
          {optOutDescriptionVisible && (
            <ul>
              <li>Your ownership percentage will be diluted</li>
            </ul>
          )}
        </div>
        <Row className="box-row">
          <Col md={6} className="border-box radio-box">
            <div className={`radio-description ${optIn ? 'selected' : ''}`}>
              <h2>Reasons to participate</h2>
              <ul>
                <li>Maintain same ownership percentage as a limited partner</li>
                <li>Increase your original investment</li>
                <li>
                  Help increase or protect the current value of your investment
                </li>
              </ul>
            </div>
            <div className="radio-block">
              <RadioButton
                label="Yes, I'll fund"
                name="cc-optin"
                id="cc-optin-yes"
                onChange={this.handleOptIn}
                checked={optIn}
              />
            </div>
          </Col>
          <Col md={6} className="border-box radio-box">
            <div className={`radio-description ${!optIn ? 'selected' : ''}`}>
              <h2>If you decline...</h2>
              <ul>
                <li>Your ownership percentage will be diluted</li>
              </ul>
            </div>
            <div className="radio-block">
              <RadioButton
                label="No, don't fund Capital Call"
                name="cc-optin"
                id="cc-optin-no"
                onChange={this.handleOptOut}
                checked={!optIn}
              />
            </div>
          </Col>
        </Row>
        <div className="border-box submit-box">
          {optIn && (
            <div className="interest-disclaimer-wrap">
              <Checkbox
                id="disclaimer"
                name="disclaimer"
                onChange={this.handleCheckClick}
                checked={acceptedDisclaimer}
                label="I understand what the Capital Call is and what is requested of me. I understand the update to the budget and business plan associated with the capital call, acknowledge that the additional funds may not produce the intended results for my existing or new investment and reaffirm my understanding of the risks of this capital call and the investment generally, as originally set forth in the Investor Packet and otherwise."
              />
            </div>
          )}
          {error && <ErrorLabel message={error} />}
          <Button
            styleClassName="blue"
            className="submitButton"
            loading={sending}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
