import { ReduxFormSelectField } from 'components/ReduxFormFields';
import {
  experienceOptions,
  objectivesOptions,
  reasonOptions,
  riskToleranceOptions
} from 'containers/Profile/content';
import { accreditationOptions } from 'containers/SignUp/content';
import React from 'react';
import { Field } from 'redux-form';
import { UserStage } from 'types/api/user';

interface Props {
  userStage: UserStage;
}

const InvestorProfileForm = ({ userStage }: Props) => {
  return (
    <div className="clearfix">
      <p>My primary real-estate investing objective is:</p>
      <Field
        id="investment_objectives"
        className="input-fixed-width-wide margin-xx"
        component={ReduxFormSelectField}
        name="investor_profile_attributes.investment_objectives"
        placeholder="Select"
        options={objectivesOptions}
      />

      <p>Investment Experience</p>
      <Field
        id="investment_experience"
        className="input-fixed-width-wide margin-xx"
        component={ReduxFormSelectField}
        name="investor_profile_attributes.investment_experience"
        placeholder="Select"
        options={experienceOptions}
        multi
        showMultiLabelsBelow
      />

      <p>What are you investing for?</p>
      <Field
        id="investment_reason"
        className="input-fixed-width-wide margin-xx"
        component={ReduxFormSelectField}
        name="investor_profile_attributes.investment_reason"
        placeholder="Select"
        options={reasonOptions}
        multi
        showMultiLabelsBelow
      />

      <p>My risk tolerance is:</p>
      <Field
        id="risk_tolerance"
        className="input-fixed-width-wide margin-xx"
        component={ReduxFormSelectField}
        name="investor_profile_attributes.risk_tolerance"
        placeholder="Select"
        options={riskToleranceOptions}
      />

      {userStage === 'activate' && (
        <>
          <p>Select what describes you best</p>
          <Field
            name="investor_profile_attributes.accreditation"
            id="accreditation"
            className="input-fixed-width-wide margin-xx"
            component={ReduxFormSelectField}
            placeholder="Select"
            options={accreditationOptions}
            multi
            showMultiLabelsBelow
          />
        </>
      )}
    </div>
  );
};

export default InvestorProfileForm;
