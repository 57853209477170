import { EMLoadingIcon } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import PostCard from 'components/PostCard/PostCard';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as offeringActions from 'redux/actions/offerings';
import { loadPosts as loadAPIPosts, markPostsRead } from 'redux/actions/posts';
import type { Offering } from 'types/api/offering';
import type { Post } from 'types/api/post';
import type { User } from 'types/api/user';
import type { Dispatch } from 'types/redux';
import { canAccessPage } from 'utilities/user';
import utils from 'utilities/utils';

import * as styles from './Activities.module.scss';
import { PostFilters, PostList } from './components';

interface CustomProps {
  currentUser: User;
  dispatch: Dispatch;
  loading: boolean;
  loadingInBackground: boolean;
  offerings: Offering[];
  offeringsLoaded: boolean;
  posts: Post[];
  user: User;
}

type Props = CustomProps & RouteComponentProps;

const Activities = ({
  currentUser,
  dispatch,
  loading = true,
  loadingInBackground,
  location,
  offerings,
  offeringsLoaded = false,
  posts,
  user
}: Props) => {
  const [filters, setFilters] = useState<{
    label: string[];
    offering: number[];
  }>({ offering: [], label: [] });
  const [page, setPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);

  const checkScrollPosition = utils.debounce(
    useCallback(() => {
      const windowHeight = window.innerHeight;
      const footerOffsetTop =
        document?.getElementById('footer')?.getBoundingClientRect().top || 0;
      const distanceToFooter = footerOffsetTop - windowHeight;

      // If within X pixels of the footer, load the next page of posts
      // When total posts are added to API response, check if there's more here as well before loading
      if (
        distanceToFooter < 2000 &&
        !loading &&
        !loadingInBackground &&
        hasMorePages
      ) {
        setPage(page + 1);
      }
    }, [hasMorePages, loading, loadingInBackground, page]),
    100
  );

  useEffect(() => {
    const query = location.search ? queryString.parse(location.search) : null;
    document.title = 'My Activity | EquityMultiple';

    if (!canAccessPage('activity', user)) history.replace('/');

    if (!offeringsLoaded) {
      const queryStr =
        'status[]=funded&status[]=cashflowing&status[]=exited&user_only=true';
      dispatch(offeringActions.loadPostOfferings(queryStr));
    }

    const newFilters = {
      offering: [],
      label: []
    } as typeof filters;
    if (query) {
      if (query.oid) newFilters.offering = [parseInt(query.oid as string)];
      if (query.label) newFilters.label = [query.label as string];
      setFilters(newFilters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  useEffect(() => {
    // Reload posts when applicable
    const postQuery = {
      labels: filters.label,
      offeringIds: filters.offering,
      page
    };

    dispatch(loadAPIPosts(postQuery)).then(res => {
      if (res) {
        const morePages = res.count - res.page * res.page_size > 0;
        if (hasMorePages !== morePages) setHasMorePages(morePages);
      }
    });
  }, [filters.offering, filters.label, page, dispatch, hasMorePages]);

  useEffect(() => {
    // Set visible posts as read after 5 seconds
    setTimeout(() => {
      const unreadPosts = posts.filter(post => !post.read);
      if (unreadPosts.length > 0) {
        const unreadPostIds = unreadPosts.map(post => post.id);
        const data = {
          ids: unreadPostIds,
          user: currentUser.id
        };
        dispatch(markPostsRead(data));
      }
    }, 5000);
  }, [currentUser.id, dispatch, posts]);

  const setFilterId = newFilters => {
    if (newFilters.offering?.length === 1)
      history.replace(`/activity?oid=${newFilters.offering[0]}`);
    else history.replace('/activity');

    setFilters({
      offering: [...newFilters.offering] || [],
      label: [...newFilters.label] || []
    });
    setPage(1);
  };

  const readPosts: React.JSX.Element[] = [];
  const unreadPosts: React.JSX.Element[] = [];

  posts.forEach(post => {
    if (post.read) {
      readPosts.push(
        <PostCard
          post={post}
          key={post.id}
          className={styles.postCard}
          openByDefault={readPosts.length < 4}
        />
      );
    } else {
      unreadPosts.push(
        <PostCard
          className={styles.postCard}
          post={post}
          key={post.id}
          openByDefault={unreadPosts.length < 4}
        />
      );
    }
  });

  return (
    <div className={styles.container}>
      {!offeringsLoaded ? (
        <EMLoadingIcon />
      ) : (
        <Container data-testid="activityContainer">
          <div className={styles.activityHeader}>
            <Row>
              <Col lg={9} offset={{ lg: 3 }}>
                <h1 className="margin-top-0">
                  {unreadPosts.length === 0 ? 'Posts' : 'Recent Posts'}
                </h1>
              </Col>
            </Row>
          </div>

          <div className={styles.existingPostsWrapper}>
            <Row>
              <Col lg={3}>
                {offerings?.length > 0 && (
                  <PostFilters
                    offerings={offerings}
                    setFilterId={setFilterId}
                    selectedLabels={[...filters.label]}
                    selectedOfferings={[...filters.offering]}
                  />
                )}
              </Col>
              <Col lg={9}>
                <PostList
                  loading={loading}
                  filterByOfferings={filters.offering}
                  offerings={offerings}
                  hasPosts={posts.length > 0}
                  readPosts={readPosts}
                  unreadPosts={unreadPosts}
                />
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

function mapStateToProps(store) {
  return {
    closings: store.offerings.closings,
    offerings: store.offerings.offerings,
    posts: store.posts.posts,
    currentUser: store.auth.user,
    loading: store.posts.loading,
    loadingInBackground: store.posts.loadingInBackground,
    offeringsLoaded: store.offerings.loaded,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Activities);
