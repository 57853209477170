import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { getUserAttributes, profileFormValues } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const Name = ({
  dispatch,
  error,
  handleSubmit,
  submitting
}: SharedProfileValues) => {
  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'first_name',
      'last_name',
      'middle_name',
      'preferred_name'
    ]);

    // Submit empty strings for these fields so we can check for an empty string vs. null
    // to determine if the user has completed this form already or not
    if (!submitValues.middle_name) submitValues.middle_name = '';
    if (!submitValues.preferred_name) submitValues.preferred_name = '';

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          history.push('/users/profile/kyc');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Let's verify your identity</h3>
        <p className="margin-xx">
          We collect this information to comply with federal regulation such as
          know-your-customer (KYC) and anti-money laundering (AML) laws.
        </p>
        <Row>
          <Col xs={12} md={6}>
            <Field
              id="first_name"
              component={ReduxFormInputField}
              name="first_name"
              label="Legal First Name"
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              id="last_name"
              component={ReduxFormInputField}
              name="last_name"
              label="Legal Last Name"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Field
              id="middle_name"
              component={ReduxFormInputField}
              name="middle_name"
              label="Legal Middle Name (Optional)"
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              id="preferred_name"
              component={ReduxFormInputField}
              name="preferred_name"
              label="Preferred Name (Optional)"
            />
          </Col>
        </Row>

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/tolerance" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    initialValues: getUserAttributes(store.auth.user),
    submitting: store.userSettings.updatingUserProfile
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'name',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(Name)
);
