import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import {
  updatePersonalIdentification,
  updateUserProfile
} from 'redux/actions/user-settings';
import { change, formValueSelector, reset } from 'redux-form';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { throwSubmissionErrors } from 'utilities/validation';

import PersonalIdentificationForm from '../forms/PersonalIdentificationForm';

interface Props {
  dispatch?: Dispatch;
  idDoc?: {
    expiry: string;
    type: string;
  };
  idType?: string;
  initialValues: {
    id_document?: File;
    id_expiration?: Date;
    id_type?: string;
  };
  updatedPersonalIdentification?: boolean;
  updatingPersonalIdentification?: boolean;
}

const PersonalIdentification = ({
  dispatch,
  idDoc,
  idType,
  initialValues,
  updatingPersonalIdentification,
  updatedPersonalIdentification
}: Props) => {
  const handleSubmit = values => {
    // Delete the id_document if it's the placeholder value used for validation
    if (typeof values.id_document === 'string') delete values.id_document;

    if (values.passport) {
      const submitValues = {
        investor_profile_attributes: {
          passport: values.passport
        }
      };
      dispatch(updateUserProfile(submitValues));
    }

    return dispatch(updatePersonalIdentification({ user: values }))
      .then(res => {
        humane.notice(res.message);
        dispatch(loadAuth());
        dispatch(reset('personalIdentification'));
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const changeFieldValue = (field: string, value: string | Date | File) => {
    dispatch(change('personalIdentification', field, value));
  };

  return (
    <PersonalIdentificationForm
      changeFieldValue={changeFieldValue}
      existingDocument={idDoc}
      initialValues={initialValues}
      updating={updatingPersonalIdentification}
      updated={updatedPersonalIdentification}
      onSubmit={handleSubmit}
      idType={idType}
    />
  );
};

const selector = formValueSelector('personalIdentification');

const mapStateToProps = state => {
  const idDoc = state.auth.user.id_document;
  const idType = selector(state, 'id_type');
  let initialValues = {};

  if (idDoc) {
    const expirationFormatted = moment.utc(idDoc.expiry).format('MM/DD/YYYY');
    initialValues = {
      id_document: idDoc.name,
      id_expiration: expirationFormatted,
      id_type: idDoc.type,
      passport: state.auth.user.investor_profile?.passport
    };
  }

  return {
    idDoc,
    idType,
    initialValues,
    updatedPersonalIdentification:
      state.userSettings.updatedPersonalIdentification,
    updatingPersonalIdentification:
      state.userSettings.updatingPersonalIdentification
  };
};

export default connect(
  mapStateToProps
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(PersonalIdentification);
