import { redirectUserWithInterestAscentInvestment } from 'containers/Investment/helpers';
import { useEffect } from 'react';
import { ClosingOffering, Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';

const useRedirectUserWIthInterestAscentInvestment = (
  dispatch: Dispatch,
  offering: ClosingOffering | Offering
) => {
  useEffect(() => {
    if (offering) {
      redirectUserWithInterestAscentInvestment(dispatch, offering.id);
    }
  }, [dispatch, offering]);
};

export default useRedirectUserWIthInterestAscentInvestment;
