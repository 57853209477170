export const CLEAR_INVESTMENT = 'investments/CLEAR_INVESTMENT';

export const SEND_PLEDGE = 'investments/SEND_PLEDGE';
export const SEND_PLEDGE_SUCCESS = 'investments/SEND_PLEDGE_SUCCESS';
export const SEND_PLEDGE_FAIL = 'investments/SEND_PLEDGE_FAIL';

export const LOAD_INVESTMENT = 'investments/LOAD_INVESTMENT';
export const LOAD_INVESTMENT_SUCCESS = 'investments/LOAD_INVESTMENT_SUCCESS';
export const LOAD_INVESTMENT_FAIL = 'investments/LOAD_INVESTMENT_FAIL';

export const LOAD_FUNDING_OPTIONS = 'investments/LOAD_FUNDING_OPTIONS';
export const LOAD_FUNDING_OPTIONS_SUCCESS =
  'investments/LOAD_FUNDING_OPTIONS_SUCCESS';
export const LOAD_FUNDING_OPTIONS_FAIL =
  'investments/LOAD_FUNDING_OPTIONS_FAIL';

export const UPDATE_INVESTMENT = 'investments/UPDATE_INVESTMENT';
export const UPDATE_INVESTMENT_SUCCESS =
  'investments/UPDATE_INVESTMENT_SUCCESS';
export const UPDATE_INVESTMENT_FAIL = 'investments/UPDATE_INVESTMENT_FAIL';

export const SUBMIT_SIGN_AGREEMENT = 'investments/SUBMIT_SIGN_AGREEMENT';
export const SUBMIT_SIGN_AGREEMENT_SUCCESS =
  'investments/SUBMIT_SIGN_AGREEMENT_SUCCESS';
export const SUBMIT_SIGN_AGREEMENT_FAIL =
  'investments/SUBMIT_SIGN_AGREEMENT_FAIL';

export const FUND_INVESTMENT = 'investments/FUND_INVESTMENT';
export const FUND_INVESTMENT_SUCCESS = 'investments/FUND_INVESTMENT_SUCCESS';
export const FUND_INVESTMENT_FAIL = 'investments/FUND_INVESTMENT_FAIL';

export const DRIP_OPT = 'investments/DRIP_OPT';
export const DRIP_OPT_SUCCESS = 'investments/DRIP_OPT_SUCCESS';
export const DRIP_OPT_FAIL = 'investments/DRIP_OPT_FAIL';

export const LOAD_REINVESTMENT = 'investments/LOAD_REINVESTMENT';
export const LOAD_REINVESTMENT_SUCCESS =
  'investments/LOAD_REINVESTMENT_SUCCESS';
export const LOAD_REINVESTMENT_FAIL = 'investments/LOAD_REINVESTMENT_FAIL';

export const UPDATE_REINVESTMENT_ELECTIONS =
  'investments/UPDATE_REINVESTMENT_ELECTIONS';
export const UPDATE_REINVESTMENT_ELECTIONS_SUCCESS =
  'investments/UPDATE_REINVESTMENT_ELECTIONS_SUCCESS';
export const UPDATE_REINVESTMENT_ELECTIONS_FAIL =
  'investments/UPDATE_REINVESTMENT_ELECTIONS_FAIL';

export function clearInvestment() {
  return {
    type: CLEAR_INVESTMENT
  };
}

export function sendPledge(params) {
  return {
    types: [SEND_PLEDGE, SEND_PLEDGE_SUCCESS, SEND_PLEDGE_FAIL],
    promise: client => client.post('/mkt/investments', { data: params })
  };
}

export function loadInvestment(investmentId) {
  return {
    types: [LOAD_INVESTMENT, LOAD_INVESTMENT_SUCCESS, LOAD_INVESTMENT_FAIL],
    promise: client => client.get(`/mkt/investments/${investmentId}`)
  };
}

export function loadFundingOptions(investmentId) {
  return {
    types: [
      LOAD_FUNDING_OPTIONS,
      LOAD_FUNDING_OPTIONS_SUCCESS,
      LOAD_FUNDING_OPTIONS_FAIL
    ],
    promise: client =>
      client.get(`/mkt/investments/${investmentId}/funding_options`)
  };
}

export function updateInvestment(params, investmentId) {
  return {
    types: [
      UPDATE_INVESTMENT,
      UPDATE_INVESTMENT_SUCCESS,
      UPDATE_INVESTMENT_FAIL
    ],
    promise: client =>
      client.patch(`/mkt/investments/${investmentId}`, {
        data: params
      })
  };
}

export function cancelInvestment(investmentId, data = {}) {
  return {
    types: [
      UPDATE_INVESTMENT,
      UPDATE_INVESTMENT_SUCCESS,
      UPDATE_INVESTMENT_FAIL
    ],
    promise: client =>
      client.put(`/mkt/investments/${investmentId}/revert`, {
        data
      })
  };
}

export function signAgreement(investmentId, data) {
  return {
    types: [
      SUBMIT_SIGN_AGREEMENT,
      SUBMIT_SIGN_AGREEMENT_SUCCESS,
      SUBMIT_SIGN_AGREEMENT_FAIL
    ],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/sign`, {
        data
      })
  };
}

export function fundInvestment(investmentId, data) {
  return {
    types: [FUND_INVESTMENT, FUND_INVESTMENT_SUCCESS, FUND_INVESTMENT_FAIL],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/fund`, {
        data
      })
  };
}

export function dripOpt(investmentId, data) {
  return {
    types: [DRIP_OPT, DRIP_OPT_SUCCESS, DRIP_OPT_FAIL],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/drip_opt`, {
        data
      })
  };
}

export function loadReinvestment(investmentId) {
  return {
    types: [
      LOAD_REINVESTMENT,
      LOAD_REINVESTMENT_SUCCESS,
      LOAD_REINVESTMENT_FAIL
    ],
    promise: client =>
      client.get(`/mkt/investments/${investmentId}/reinvestment`)
  };
}

export function updateReinvestmentElections(investmentId, data) {
  return {
    types: [
      UPDATE_REINVESTMENT_ELECTIONS,
      UPDATE_REINVESTMENT_ELECTIONS_SUCCESS,
      UPDATE_REINVESTMENT_ELECTIONS_FAIL
    ],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/reinvestment_elections`, {
        data: {
          investment: data
        }
      })
  };
}
