import { Button } from '@equitymultiple/react-eui';
import {
  ReduxFormDateSelectField,
  ReduxFormFileUploaderField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import { idTypes } from 'containers/Accounts/contents';
import { getIdTypeLabel } from 'containers/Accounts/helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { maxUploadSize } from 'utilities/constants';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { personalIdSchema } from '../validation';

interface FormData {
  id_document: File | null;
  id_expiration: Date;
  id_type: string;
}

interface Props extends Partial<InjectedFormProps<FormData, Props>> {
  changeFieldValue: (field: string, value: string | Date | File) => void;
  existingDocument?: {
    expiry: string;
    type: string;
  };
  idType?: string;
  updated?: boolean;
  updating?: boolean;
}

const PersonalIdentificationForm = ({
  changeFieldValue,
  existingDocument,
  handleSubmit,
  idType,
  updated,
  updating
}: Props) => {
  const [formVisible, setFormVisible] = useState(false);
  const [hasNewDoc, setHasNewDoc] = useState(false);

  useEffect(() => {
    if (!existingDocument) {
      setFormVisible(true);
    }
  }, [existingDocument]);

  useEffect(() => {
    if (updated) {
      setFormVisible(false);
      setHasNewDoc(false);
    }
  }, [updated]);

  const handleFileRemoval = () => {
    changeFieldValue('id_document', null);
    setHasNewDoc(true);
  };

  const handleSelectInput = (val: string, name: string) => {
    changeFieldValue(name, val);
  };

  const upload = (file: File) => {
    changeFieldValue('id_document', file);
  };

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };

  const expirationDateFormatted =
    existingDocument?.expiry &&
    moment.utc(existingDocument.expiry).format('MM/DD/YYYY');
  const documentType = existingDocument && getIdTypeLabel(existingDocument);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col sm={6}>
          <Field
            name="id_document"
            id="id_document"
            component={ReduxFormFileUploaderField}
            acceptedFileTypes={['JPG', 'PNG']}
            existingFile={!hasNewDoc && existingDocument}
            hideRemove={!formVisible}
            onRemove={handleFileRemoval}
            showImage={hasNewDoc}
            uploading={updating}
            upload={upload}
            maxSize={maxUploadSize}
          />
        </Col>
        <Col sm={6} className="overflow-visible">
          {formVisible ? (
            <>
              <Field
                id="id_type"
                name="id_type"
                component={ReduxFormSelectField}
                label="Document Type"
                options={idTypes}
                handleChange={(val: string) =>
                  handleSelectInput(val, 'id_type')
                }
              />
              <Field
                id="id_expiration"
                name="id_expiration"
                component={ReduxFormDateSelectField}
                futureDate
                label="ID Expiration Date"
              />
              {idType === 'passport' && (
                <Field
                  name="passport"
                  id="passport"
                  component={ReduxFormInputField}
                  label="Passport Number"
                />
              )}
            </>
          ) : (
            <>
              <div className="text-label">Document Type</div>
              <div className="text-dark margin-xx">{documentType}</div>
              <div className="text-label">Document Expiration Date</div>
              <div className="text-dark margin-x">
                {expirationDateFormatted}
              </div>
              <Button
                style={{ width: '140px' }}
                variant="outlined"
                onClick={toggleFormVisibility}
              >
                Edit
              </Button>
            </>
          )}
        </Col>
      </Row>
      {formVisible && (
        <Button
          data-testid="profileIdSubmit"
          className="float-right"
          style={{ width: '140px' }}
          type="submit"
          loading={updating}
        >
          Update
        </Button>
      )}
    </form>
  );
};

export default reduxForm<FormData, Props>({
  form: 'personalIdentification',
  validate: validateSchema(personalIdSchema),
  enableReinitialize: true,
  touchOnBlur: false,
  onSubmitFail: scrollToError
})(PersonalIdentificationForm);
