import { EMLoadingIcon } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import React, { useEffect } from 'react';
import utils from 'utilities/utils';

const PlaidLinkOAuth = () => {
  const token = utils.getLocalStorage('plaidLinkToken');

  useEffect(() => {
    if (!token) {
      history.push('/');
    } else {
      utils.setLocalStorage('oauthRedirectUri', window.location.href);
      history.push(utils.getLocalStorage('plaidLinkRedirect'));
    }
  }, [token]);

  return <EMLoadingIcon data-testid="EmLoadingIcon" />;
};

export default PlaidLinkOAuth;
