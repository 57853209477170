import { ReduxFormInputField } from 'components/ReduxFormFields';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { phoneMask } from 'utilities/masks';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import AddressForm from './AddressForm';
import { personalAddressVerificationSchema } from './validation';

interface CustomProps {
  fullName: string;
  handleSubmit: () => void;
  investmentAccountTitle: string;
  previousWizard: () => void;
}

type Props = InjectedFormProps<object, CustomProps> & CustomProps;

const PersonalAccountAddressVerificationForm = ({
  previousWizard,
  investmentAccountTitle,
  handleSubmit,
  fullName
}: Props) => {
  return (
    <>
      <div>
        <h4 className="margin-x">{fullName} Contact Information</h4>
        <div className="margin-xx size-16">
          To comply with securities regulations and to prepare your tax
          documents, we are required to gather up-to-date information on your{' '}
          {investmentAccountTitle}.
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <h6 className="margin-xx">Update:</h6>
        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="user_address_verification.phone"
              mask={phoneMask}
              component={ReduxFormInputField}
              label="Phone Number"
            />
          </Col>
        </Row>
        <AddressForm
          formName="user_address_verification"
          previousWizard={previousWizard}
        />
      </Form>
    </>
  );
};

const selector = formValueSelector('user_address_verification');

function mapStateToProps(state, ownProps) {
  const fullName =
    selector(state, 'user_address_verification.first_name') +
    ' ' +
    selector(state, 'user_address_verification.last_name');
  const { previouslyEnteredUpdatedUserInformation } = ownProps;
  const accountDetails =
    previouslyEnteredUpdatedUserInformation || state.auth.user;

  return {
    fullName,
    initialValues: {
      user_address_verification: {
        ...accountDetails,
        ...(previouslyEnteredUpdatedUserInformation || state.auth.user?.address)
      }
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, CustomProps>({
    form: 'user_address_verification',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    validate: validateSchema(personalAddressVerificationSchema),
    onSubmitFail: scrollToError
  })(PersonalAccountAddressVerificationForm)
);
