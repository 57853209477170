import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const smsConfirmationSchema = yup.object().shape({
  code: yupString.required('Code is required')
});

interface FormValues {
  code?: string;
}

export interface Props extends Partial<InjectedFormProps<FormValues, Props>> {
  changePhone: () => void;
  heading?: string;
  onSubmit: (values: FormValues) => void;
  phoneNumber: string;
  requestingCode?: boolean;
  resendCode: () => void;
}

const SmsConfirmationForm = ({
  changePhone,
  error,
  handleSubmit,
  heading,
  onSubmit,
  phoneNumber,
  resendCode,
  requestingCode,
  submitting
}: Props) => {
  const loading = submitting || requestingCode;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {heading && <h3 className="margin-x">{heading}</h3>}
      <p data-testid="phoneSubheading">
        Please enter the code we've sent in the text message to
        <br />
        <strong>{phoneNumber}</strong>
      </p>
      <p className="margin-xx">
        <button
          className="text-link"
          type="button"
          onClick={changePhone}
          disabled={loading}
        >
          Change Phone Number
        </button>
      </p>
      <p className="margin-xx">
        Didn't receive the code?{' '}
        <button
          className="text-link"
          type="button"
          onClick={resendCode}
          disabled={loading}
        >
          Resend
        </button>
      </p>

      <Field
        id="code"
        component={ReduxFormInputField}
        name="code"
        label="Code from SMS"
        className="input-fixed-width-wide"
      />

      {error && <ErrorLabel message={error} className="margin-xxx" />}

      <div className="forwardBackButtonWrapCompact">
        <Button
          type="submit"
          loading={loading}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
        <Button
          disabled={loading}
          variant="outlined"
          className="button-fixed-width margin-top-xx arrowBackButton"
          onClick={changePhone}
        >
          <Back />
        </Button>
      </div>
    </Form>
  );
};

function mapStateToProps(store) {
  return {
    requestingCode: store.userSettings.requestingCode
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'smsConfirmation',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(smsConfirmationSchema)
  })(SmsConfirmationForm)
);
