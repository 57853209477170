import history from 'browserHistory';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { User } from 'types/api/user';

import HeaderSimple from '../../components/HeaderSimple/HeaderSimple';
import { canAccessPage, twoFaSetupRequired } from '../../utilities/user';
import {
  Accreditation,
  PreferenceCenter,
  Profile,
  Security,
  TabularMenu
} from './components';
import * as styles from './Settings.module.scss';

type Params = {
  tab: string;
};

interface CustomProps {
  user: User;
}

type Props = RouteComponentProps<Params> & CustomProps;

const Settings = ({ location, match: { params }, user }: Props) => {
  const { tab } = params;

  useEffect(() => {
    document.title = 'Settings | EquityMultiple';
    const rootElement = document.getElementById('root');
    if (rootElement) rootElement.style.backgroundColor = '#FFFFFF';

    if (!tab) history.replace('/settings/profile');

    return () => {
      if (rootElement) rootElement.style.backgroundColor = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!canAccessPage('settings', user)) history.replace('/');

    const twoFaSetupIsRequired = twoFaSetupRequired(user);

    if (twoFaSetupIsRequired && tab !== 'security')
      history.replace('/settings/security');
  }, [tab, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTab = () => {
    const query = queryString.parse(location?.search);
    const accreditationSuccessModalAccountName = query?.accreditation_success;

    let activeTab = <Profile />;
    if (tab === 'accreditation') {
      activeTab = (
        <Accreditation
          successModalAccountName={accreditationSuccessModalAccountName}
        />
      );
    } else if (tab === 'security') {
      activeTab = <Security />;
    } else if (tab === 'preference_center') {
      activeTab = <PreferenceCenter email={user.email} />;
    }

    return activeTab;
  };

  const twoFaSetupIsRequired = twoFaSetupRequired(user);

  return (
    <div data-testid="userSettings">
      {twoFaSetupIsRequired && <HeaderSimple className={styles.headerNav} />}
      <Container>
        <TabularMenu
          tabs={['Profile', 'Accreditation', 'Security', 'Preference Center']}
          twoFaSetupRequired={twoFaSetupIsRequired}
        />
        {getCurrentTab()}
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(Settings);
