import {
  ReduxFormDateSelectField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import {
  allResidencyStatuses,
  countryOptions
} from 'containers/Accounts/contents';
import { occupationOptions } from 'containers/Profile/content';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Field } from 'redux-form';
import { phoneMask, ssn, ssnMaskOptions } from 'utilities/masks';

interface Props {
  disableSsn?: boolean;
  residenceStatus: string;
}

const ProfileForm = ({ disableSsn, residenceStatus }: Props) => {
  return (
    <div className="clearfix">
      <Row>
        <Col sm={6}>
          <Field
            id="first_name"
            component={ReduxFormInputField}
            name="first_name"
            label="Legal First Name"
          />
        </Col>
        <Col sm={6}>
          <Field
            id="last_name"
            component={ReduxFormInputField}
            name="last_name"
            label="Legal Last Name"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            id="phone"
            component={ReduxFormInputField}
            name="phone"
            mask={phoneMask}
            label="Phone"
          />
        </Col>
        <Col sm={6}>
          <Field
            disabled
            id="email"
            placeholder="email@example.com"
            component={ReduxFormInputField}
            name="email"
            label="Email"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="overflow-visible">
          <Field
            id="residence_status"
            name="investor_profile_attributes.residence_status"
            component={ReduxFormSelectField}
            label="Residence Status"
            options={allResidencyStatuses.map(key => ({
              label: key,
              value: key
            }))}
          />
        </Col>
        <Col sm={6} className="overflow-visible">
          <Field
            id="country_of_citizenship"
            name="investor_profile_attributes.country_of_citizenship"
            component={ReduxFormSelectField}
            label="Country of Citizenship"
            options={countryOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="overflow-visible">
          <Field
            id="dob"
            name="investor_profile_attributes.dob"
            component={ReduxFormDateSelectField}
            label="DOB"
          />
        </Col>
        <Col sm={6}>
          <Field
            id="ssn"
            name="investor_profile_attributes.ssn"
            mask={ssn}
            inputMaskOptions={ssnMaskOptions}
            disabled={disableSsn}
            component={ReduxFormInputField}
            label={`${
              residenceStatus === 'U.S. Permanent Resident' ||
              residenceStatus === 'U.S. Resident'
                ? 'SSN/ITIN'
                : 'Social Security Number'
            }`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            id="occupation"
            component={ReduxFormSelectField}
            name="investor_profile_attributes.occupation"
            label="Occupation"
            options={occupationOptions}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileForm;
