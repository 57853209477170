import countries from 'config/countries';
import states from 'config/states';
import React from 'react';

export const netWorths = [
  '< $1,000,000',
  '$1,000,000 - $2,500,000',
  '$2,500,001 - $5,000,000',
  '$5,000,001 - $10,000,000',
  '$10,000,000 +'
];

export const portfolioValues = [
  '< $5 million',
  '$5 - 10 million',
  '$10 - 50 million'
];

export const investmentAccountTypes = [
  'individual',
  'entity',
  'joint account',
  'ira'
];

export const entityBusinessTypes = [
  { value: 'llc', label: 'LLC' },
  { value: 'single_member_llc', label: 'Single-Member LLC' },
  { value: 'partnership', label: 'Partnership' },
  { value: 'corporation', label: 'Corporation' },
  { value: 'soleproprietorship', label: 'Sole Proprietorship' },
  { value: 'trust_irrevocable', label: 'Trust - Irrevocable' },
  { value: 'trust_revocable', label: 'Trust - Revocable' }
];

export const nonUSResidentBusinessTypes = [
  { value: 'llc', label: 'LLC' },
  { value: 'single_member_llc', label: 'Single-Member LLC' },
  { value: 'partnership', label: 'Partnership' },
  { value: 'corporation', label: 'Corporation' }
];

export const allResidencyStatuses = [
  'U.S. Citizen',
  'U.S. Permanent Resident',
  'U.S. Resident',
  'Other'
];

export const usResidentResidencyStatuses = [
  'U.S. Citizen',
  'U.S. Permanent Resident',
  'U.S. Resident'
];

export const idTypes = [
  { label: "State Issued Driver's License", value: 'license' },
  { label: 'U.S. Government Issued Passport', value: 'passport' },
  { label: 'Other U.S. Government Issued Identification', value: 'idCard' }
];

export const nonUSResidentIds = [{ label: 'Passport', value: 'passport' }];

export const accountFlag = type => {
  let cleanType = type;
  if (type === 'joint account') cleanType = 'joint';
  return `To invest with your ${cleanType} account, please contact support`;
};

export const iraAccountTypes = [
  'Traditional',
  'Roth',
  'SEP',
  'SIMPLE',
  'Other'
];

export const iraCustodians = [
  'Alto IRA',
  'Equity Trust Company',
  'IRA Services Trust Company',
  'Millennium Trust Company',
  'STRATA Trust Company',
  'Other'
];

export const maritalStatuses = ['Single', 'Married', 'Separated', 'Divorced'];

export const accountStatus = {
  created: 'created',
  pending: 'pending',
  flagged: 'flagged',
  accepted: 'accepted'
};

export const maxFinancialDependents = 99;

export const minFinancialDependents = 0;

export const taxFilingStatuses = [
  { value: 'TF-0001', label: 'Single' },
  { value: 'TF-0002', label: 'Married Filing Jointly' },
  { value: 'TF-0003', label: 'Married Filing Separately' },
  { value: 'TF-0004', label: 'Head of Household' },
  { value: 'TF-0005', label: 'Qualifying Widow(er) with Dependent Child' }
];

export const entityTrustOrLlcTypes = [
  'trust_revocable',
  'trust_irrevocable',
  'llc'
];

export const completeAccreditedInvestorDefinition = (
  <span>
    For individuals, the two most common ways to qualify as accredited are i)
    your income exceeds $200,000 (or $300,000 combined with your spouse in each
    of the two most recent years and you reasonably expect to exceed this
    threshold again this year and / or ii) your net worth exceeds $1 million,
    excluding the value of your primary residence.
    <br />
    <br />
    For entities, including LLCs, the most common way to qualify is if all of
    the owners of the entity are accredited investors.
    <br />
    <br />
    For financial professionals, the most common ways to qualify is to hold in
    good standing the General Securities Representative license (Series 7), the
    Private Securities Offerings Representative license (Series 82), or the
    Uniform Investment Adviser Representative license (Series 65).
    <br />
    <br />
    For more qualifications, including the more complex rules for trusts,{' '}
    <a
      href="https://www.investor.gov/additional-resources/news-alerts/alerts-bulletins/updated-investor-bulletin-accredited-investors"
      rel="noopener noreferrer"
      target="_blank"
    >
      click here.
    </a>
  </span>
);

export const stateOptions = Object.keys(states).map(key => ({
  label: key,
  value: key
}));

export const countryOptions = countries.map(country => ({
  label: country.name,
  value: country.name
}));
