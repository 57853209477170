import 'utilities/sessionTimeout';
import 'react-loading-skeleton/dist/skeleton.css';
import 'scss/application.scss';

import * as Sentry from '@sentry/react';
import dompurify from 'dompurify';
import { setConfiguration } from 'react-grid-system';

const packageJson = require('../package.json');

const isSentryEnvironment =
  process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV !== 'local' &&
  process.env.NODE_ENV !== 'test';

// Sentry configuration
const errorsToIgnoreOnSentry = [
  'Request has been terminated',
  "Failed to execute 'send' on 'XMLHttpRequest':",
  'Failed to fetch',
  'A network error occurred.',
  'ResizeObserver loop limit exceeded',
  'You have been logged out due to inactivity',
  'UnhandledPromiseRejectionWarning: TypeError: Illegal invocation',
  'node_modules',
  'lastSetTimerId'
];

if (isSentryEnvironment) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: errorsToIgnoreOnSentry,
    release: packageJson.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['equitymultiple-marketplace'],
        behaviour: 'apply-tag-if-contains-third-party-frames'
      })
    ]
  });
}

// Configure dompurify to allow the target attribute on anchors,
// anywhere we're using it to sanitize HTML
dompurify.setConfig({ ADD_ATTR: ['target'] });

// Set custom config for react-grid-system
setConfiguration({
  containerWidths: [540, 720, 960, 1140, 1540],
  maxScreenClass: 'xl'
});
