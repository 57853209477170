import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import SetPasswordFields from 'components/forms/SetPassword/fields/SetPasswordFields';
import { setPasswordSchema } from 'components/forms/SetPassword/validation';
import phone from 'images/signup-phone.png';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { submitSignupForm, updateSignupValues } from 'redux/actions/onboarding';
import { Form, reduxForm } from 'redux-form';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import * as sharedStyles from '../SignUpShared.module.scss';
import { SharedSignupProps } from '../types';
import * as styles from './Password.module.scss';

const Password = ({
  dispatch,
  error,
  handleSubmit,
  submitting,
  initialValues
}: SharedSignupProps) => {
  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    redirectIfPreviousStepsIncomplete(initialValues, 2);
  }, [initialValues]);

  const isInvite = !!initialValues.invitation_token;

  const onSubmit = values => {
    const submitValues = utils.cloneObject(values);

    delete submitValues.confirmPassword;

    return dispatch(
      submitSignupForm({
        step: 2,
        user: {
          ...submitValues,
          email: initialValues.email
        }
      })
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        history.push('/users/signup/name');
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <SignUpWrap
      introBackgroundColor="blue"
      introContent={
        <>
          <img
            src={phone}
            className={styles.phone}
            alt="EquityMultiple on your phone"
          />
          <p className={sharedStyles.quote}>“</p>
          <p className={styles.quoteText}>
            It's an incredible opportunity to be able to invest in cities across
            the country without leaving our home. At this point I'm 100%
            allocated to alternatives. I sleep easy at night.
          </p>
        </>
      }
      step={isInvite ? 1 : 2}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Set your password...</h3>
        <SetPasswordFields />
        {error && <ErrorLabel message={error} className="margin-xxx" />}
        <Button
          type="submit"
          loading={submitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
      </Form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  return {
    initialValues: store.auth.signupValues
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'password',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(setPasswordSchema)
  })(Password)
);
