import { Button, Modal } from '@equitymultiple/react-eui';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { validateSchema } from 'utilities/yupValidations';

import emailInviteModalSchema from './validation';

type FormValues = {
  email: string;
};

interface Props extends Partial<InjectedFormProps<FormValues, Props>> {
  hideEmailInviteModal: () => void;
  sendEmailInvitation: (arg: object) => void;
  sending: boolean;
  showEmailModal: boolean;
}

const EmailInviteModal = ({
  hideEmailInviteModal,
  sendEmailInvitation,
  sending,
  showEmailModal,
  handleSubmit
}: Props) => {
  const onSubmit = values => {
    const formData = {
      user: {
        email: values.email
      }
    };
    return sendEmailInvitation(formData);
  };

  return (
    <Modal
      open={showEmailModal}
      onClose={hideEmailInviteModal}
      title={<h6>Invite via Email</h6>}
    >
      <form
        className="margin-xxx margin-top-xxx"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          name="email"
          id="email"
          component={ReduxFormInputField}
          placeholder="email@example.com"
          label="Friend's Email Address"
        />
        <Button
          disabled={sending}
          type="submit"
          className="pull-left"
          style={{ width: '140px' }}
        >
          Invite
        </Button>
      </form>
    </Modal>
  );
};

export default reduxForm<object, Props>({
  form: 'emailInvite',
  touchOnBlur: false,
  validate: validateSchema(emailInviteModalSchema)
})(EmailInviteModal);
