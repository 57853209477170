export const GET_DASHBOARD = 'portfolio/GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'portfolio/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'portfolio/GET_DASHBOARD_FAIL';

export const GET_ACCOUNT = 'portfolio/GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'portfolio/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAIL = 'portfolio/GET_ACCOUNT_FAIL';

export const CLEAR_ACCOUNT = 'portfolio/CLEAR_ACCOUNT';

export const GET_INVESTMENT = 'portfolio/GET_INVESTMENT';
export const GET_INVESTMENT_SUCCESS = 'portfolio/GET_INVESTMENT_SUCCESS';
export const GET_INVESTMENT_FAIL = 'portfolio/GET_INVESTMENT_FAIL';

export const CLEAR_INVESTMENT = 'portfolio/CLEAR_INVESTMENT';

export const GENERATE_ACCOUNT_STATEMENT =
  'portfolio/GENERATE_ACCOUNT_STATEMENT';
export const GENERATE_ACCOUNT_STATEMENT_SUCCESS =
  'portfolio/GENERATE_ACCOUNT_STATEMENT_SUCCESS';
export const GENERATE_ACCOUNT_STATEMENT_FAIL =
  'portfolio/GENERATE_ACCOUNT_STATEMENT_FAIL';

export const CLEAR_ACCOUNT_STATEMENT = 'portfolio/CLEAR_ACCOUNT_STATEMENT';

export const GET_ARTICLES = 'portfolio/GET_ARTICLES';
export const GET_ARTICLES_SUCCESS = 'portfolio/GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAIL = 'portfolio/GET_ARTICLES_FAIL';

export function getDashboard() {
  return {
    types: [GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAIL],
    promise: client => client.get('/mkt/portfolio')
  };
}

export function getAccount(id) {
  return {
    types: [GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAIL],
    promise: client => client.get(`/mkt/portfolio/accounts/${id}`)
  };
}

export function clearAccount() {
  return {
    type: CLEAR_ACCOUNT
  };
}

export function getInvestment(id) {
  return {
    types: [GET_INVESTMENT, GET_INVESTMENT_SUCCESS, GET_INVESTMENT_FAIL],
    promise: client => client.get(`/mkt/portfolio/investments/${id}`)
  };
}

export function clearInvestment() {
  return {
    type: CLEAR_INVESTMENT
  };
}

export function generateAccountStatement(data) {
  return {
    types: [
      GENERATE_ACCOUNT_STATEMENT,
      GENERATE_ACCOUNT_STATEMENT_SUCCESS,
      GENERATE_ACCOUNT_STATEMENT_FAIL
    ],
    promise: client => client.post('/mkt/users/statement', { data })
  };
}

export function clearAccountStatement() {
  return {
    type: CLEAR_ACCOUNT_STATEMENT
  };
}

export function getArticles() {
  return {
    types: [GET_ARTICLES, GET_ARTICLES_SUCCESS, GET_ARTICLES_FAIL],
    promise: client =>
      client.get('https://cms.eqm-int.com/wp-json/wp/v2/posts?include=47745', {
        excludeToken: true
      })
  };
}
