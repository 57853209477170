import { Alert, Button } from '@equitymultiple/react-eui';
import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';

import { iraAccountTypes, iraCustodians } from '../contents';

interface Props {
  businessType: string;
  entityName: string;
  irRep: string;
  submitting: boolean;
}

const IraAccount = ({ businessType, entityName, irRep, submitting }: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Link IRA';
  }, []);

  return (
    <>
      <h4 className="margin-top-0">Link Your Individual Retirement Account</h4>

      <Field
        name="investment_account.entity_name"
        component={ReduxFormSelectField}
        id="investment_account_entity_name"
        label="Custodian"
        options={iraCustodians.map(el => ({
          label: el,
          value: el
        }))}
      />
      {entityName === 'Other' && (
        <Alert type="warning">
          We work with a variety of custodians. If you have an existing IRA with
          a custodian not on the list, please schedule a call with us to talk
          about your options
        </Alert>
      )}

      {entityName !== 'Other' && (
        <div>
          <Field
            name="investment_account.business_type"
            component={ReduxFormSelectField}
            id="investment_account_business_type"
            label="Account Type"
            options={iraAccountTypes.map(el => ({
              label: el,
              value: el
            }))}
          />
          {businessType === 'Other' && (
            <Field
              name="investment_account.business_type_other"
              component={ReduxFormInputField}
              label="Other Account Type"
            />
          )}
          <Field
            name="investment_account.ira_account_number"
            component={ReduxFormInputField}
            label="Account Number"
          />
        </div>
      )}

      {entityName === 'Other' ? (
        <Button
          loading={submitting}
          className="float-right"
          style={{ width: 160 }}
          href={irRep}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <i className="fa fa-phone" />
            Schedule
          </span>
        </Button>
      ) : (
        <Button
          loading={submitting}
          variant="orange"
          type="submit"
          style={{ width: 160 }}
        >
          Save
        </Button>
      )}
    </>
  );
};

export default IraAccount;
