import { Button } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { loadInvestmentAccount } from 'redux/actions/account';
import {
  AccountDocuments as AccountDocumentsType,
  SingleInvestmentAccount
} from 'types/api/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';

import { getIdTypeLabel, isComplete } from '../helpers';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import DocumentPreview from './DocumentPreview/DocumentPreview';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  documents: AccountDocumentsType;
  investmentAccount: SingleInvestmentAccount;
}

type Props = CustomProps & RouteComponentProps<Params>;

const AccountDocuments = ({
  investmentAccount,
  dispatch,
  documents,
  match: { params }
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Documents';

    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
  }, [dispatch, params]);

  const expirationDateFormatted =
    documents?.id_document?.expiry &&
    moment.utc(documents.id_document.expiry).format('MM/DD/YYYY');

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">Documents</h4>
          <p className="margin-xx">
            Below is a list of documents you have uploaded to identify your
            Investment Account.
          </p>
          <h5>
            {investmentAccount.type === 'joint account' && 'Account Holder 1 '}
            Identification Document
          </h5>
          {documents.id_document?.type && (
            <div>
              <div className="text-label">Document Type</div>
              <div className="text-dark margin-xx">
                {getIdTypeLabel(documents.id_document)}
              </div>
              <div className="text-label">Document Expiration Date</div>
              <div className="text-dark margin-x">
                {expirationDateFormatted}
              </div>
              <DocumentPreview
                document={documents.id_document}
                documentType="ID"
              />
              <Button
                variant="outlined"
                className="fitContent"
                wrapper={<Link to="/settings/profile#personalIdentification" />}
              >
                Upload New Document
              </Button>
              {investmentAccount.type === 'joint account' &&
                documents.account_holder_2_id_document?.type && (
                  <div>
                    <h5>Account Holder 2 Identification Document</h5>
                    <div className="text-label margin-x">
                      {documents.account_holder_2_id_document.type}
                    </div>
                    <DocumentPreview
                      document={documents.account_holder_2_id_document}
                      documentType="Account Holder 2 ID"
                    />
                    <Button
                      variant="outlined"
                      className="fitContent"
                      wrapper={
                        <Link
                          to={`/accounts/update/joint/secondary_account/identification_document/${investmentAccount.reference_id}`}
                        />
                      }
                    >
                      Upload New Document
                    </Button>
                  </div>
                )}
              <hr className="margin-top-x" />
            </div>
          )}
          {investmentAccount.type === 'entity' && (
            <div>
              <h5>Organizational Document</h5>
              {documents.entity_evidence_attachment?.name && (
                <div>
                  <div className="text-label margin-x">
                    {documents.entity_evidence_attachment.name}
                  </div>
                  <DocumentPreview
                    document={documents.entity_evidence_attachment}
                    documentType="Entity Document"
                  />
                </div>
              )}
              <Button
                variant="outlined"
                className="fitContent"
                wrapper={
                  <Link
                    to={`/accounts/update/entity/documents/organizational_document/${investmentAccount.reference_id}`}
                  />
                }
              >
                Upload New Document
              </Button>
              <hr className="margin-top-x" />
              <h5>Tax Document</h5>
              {documents.ein_evidence_attachment?.name ? (
                <div>
                  <div className="text-label margin-x">
                    {documents.ein_evidence_attachment.name}
                  </div>
                  <DocumentPreview
                    document={documents.ein_evidence_attachment}
                    documentType="Tax Document"
                  />
                </div>
              ) : (
                <div className="margin-x">No tax document</div>
              )}
              <Button
                variant="outlined"
                className="fitContent"
                wrapper={
                  <Link
                    to={`/accounts/update/entity/documents/tax_document/${investmentAccount.reference_id}`}
                  />
                }
              >
                Upload New Document
              </Button>
              <hr className="margin-top-x" />
            </div>
          )}
          {investmentAccount.type === 'joint account' && (
            <div>
              <h5 className="clearfix">Power of Attorney Document</h5>
              <div className="margin-xx">
                <Button
                  variant="outlined"
                  className="fitContent"
                  wrapper={
                    <Link
                      to={`/accounts/update/${investmentAccount.account_type}/documents/poa/${investmentAccount.reference_id}`}
                    />
                  }
                >
                  View
                </Button>
              </div>
              <hr className="margin-top-x" />
            </div>
          )}
          <h5>W9 Form</h5>
          <div className="margin-xx">
            <Button
              variant="outlined"
              className="fitContent"
              wrapper={
                <Link
                  to={`/accounts/update/${investmentAccount.account_type}/documents/w9/${investmentAccount.reference_id}`}
                />
              }
            >
              View and Update W9
            </Button>
            <hr className="margin-top-x" />
          </div>
          {isComplete(investmentAccount) && (
            <BackLink investmentAccount={investmentAccount} />
          )}
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    documents: state.account.documents,
    investmentAccount: state.account.investmentAccount
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(AccountDocuments);
