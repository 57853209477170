import utils from 'utilities/utils';
import * as yup from 'yup';

import {
  validations,
  yupBool,
  yupNumber,
  yupString
} from '../../utilities/yupValidations';

const { fields } = validations;

const sanitizeNameString = (name: string) => {
  if (name) {
    return (
      name
        .toLowerCase()
        .trim()
        // Replace left single quote (which iOS and possibly others use for an apostrophe) with apostrophe
        .replace(/\u2018/g, "'")
    );
  }

  return null;
};

export const cancelInvestmentSchema = yup.object().shape({
  cancel_detail: yupString.required(
    "Please provide more detail about why you're cancelling this investment"
  ),
  cancel_reason: yupString.required('Reason for cancellation is required')
});

export const interestSchema = yup.object().shape({
  investment_account: fields.investmentAccount,
  investment_amount: fields.investmentAmount
});

export const signSchema = yup.object().shape({
  pledge1: fields.requireTrue,
  pledge2: fields.requireTrue,
  pledge3: fields.requireTrue,
  user_name: yupString
    .required('Signature is required')
    .test(
      'Signature matches registered name',
      'Please type your name exactly as it is registered with us',
      function (value) {
        const sanitizedName = sanitizeNameString(this.parent.registeredName);
        const sanitizedValue = sanitizeNameString(value);
        if (sanitizedName !== sanitizedValue) return false;
        return true;
      }
    )
});

export const fundSchema = yup.object().shape({
  payment_method: fields.paymentMethod,
  source: yupNumber.notRequired().when('payment_method', {
    is: val => val === 'ACH',
    then: () => yupString.required('Bank account is required')
  })
});

export const stnElectionsSchema = yup.object().shape({
  opt_out_reason: yupNumber.notRequired().when('reinvestment_option', {
    is: val => val === 'opt_out',
    then: () => yupString.required('Opt-out reason is required')
  }),
  other_reason: yupNumber.notRequired().when('opt_out_reason', {
    is: val => val === 'Other',
    then: () => yupString.required('Opt-out reason is required')
  }),
  reinvest_interest: yupBool.test({
    name: 'Is required when reinvestment_option is not "opt_out"',
    test(value) {
      const { reinvestment_option: reinvestmentOption } = this.parent;
      let message;

      if (
        reinvestmentOption !== 'opt_out' &&
        value !== true &&
        value !== false
      ) {
        message = 'Interest choice is required';
      }

      return message
        ? this.createError({
            message,
            path: 'reinvest_interest'
          })
        : true;
    }
  }),
  reinvestment_amount: yupString
    .test({
      name: 'Is required when reinvestment_option is "partial"',
      test(value) {
        const { reinvestment_option: reinvestmentOption } = this.parent;
        let message;

        if (reinvestmentOption === 'partial' && !value) {
          message = 'Partial reinvestment amount is required';
        }

        return message
          ? this.createError({
              message,
              path: 'reinvestment_amount'
            })
          : true;
      }
    })
    .test({
      name: 'Is in a valid of increment of the minimum amount',
      test(value) {
        const { increment } = this.parent;
        const amount = utils.currencyStringToNumber(value);
        let message = null;

        if (increment) {
          if (amount % increment !== 0) {
            const incrementFormatted = utils.formatCurrency(increment, 0);
            message = `You must select a value in increments of ${incrementFormatted}`;
          }
        }

        return message
          ? this.createError({
              message,
              path: 'reinvestment_amount'
            })
          : true;
      }
    })
    .test({
      name: 'Is less than the original investment amount and greater than zero',
      test(value) {
        const { amount } = this.parent;
        const originalAmount = utils.currencyStringToNumber(amount);
        const newAmount = utils.currencyStringToNumber(value);
        let message = null;

        if (originalAmount && newAmount !== null && newAmount !== undefined) {
          if (newAmount === 0) {
            message = 'Partial reinvestment amount must be greater than zero';
          }
          if (newAmount >= originalAmount) {
            message =
              'Partial reinvestment amount must be less than your original investment amount';
          }
        }

        return message
          ? this.createError({
              message,
              path: 'reinvestment_amount'
            })
          : true;
      }
    }),
  reinvestment_option: yupString.required('Reinvestment option is required')
});

const pledgeValidation = yupBool.test(
  'Is required when opt_in is true',
  'Acceptance is required',
  function (value) {
    const { opt_in: optIn } = this.parent;

    return !optIn || (optIn && value);
  }
);

export const distributionReinvestmentElectionsSchema = yup.object().shape({
  opt_in: yupString.required('Reinvestment option is required'),
  pledge1: pledgeValidation,
  pledge2: pledgeValidation,
  pledge3: pledgeValidation,
  user_name: yupString
    .notRequired()
    .test(
      'Signature matches registered name',
      'Please type your name exactly as it is registered with us',
      function (value) {
        const { opt_in: optIn } = this.parent;

        const sanitizedName = sanitizeNameString(this.parent.registeredName);
        const sanitizedValue = sanitizeNameString(value);
        if (optIn && sanitizedName !== sanitizedValue) return false;

        return true;
      }
    )
});
