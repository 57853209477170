import { Alert, Tooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';
import { Closing } from 'types/api/closing';
import { Offering } from 'types/api/offering';

import InvestButton from '../InvestButton/InvestButton';
import * as styles from './Infobar.module.scss';

interface Props {
  closing: Closing;
  mustVerifyToInvest: boolean;
  offering: Offering;
}

const Infobar = ({ offering, closing, mustVerifyToInvest }: Props) => {
  let addressList;

  const alternateLocation = offering.alternate_location?.trim();
  const closingAllowsInvestment = closing?.is_investment_allowed;
  const closingInInterest = closing?.stage === 'interest';

  if (offering.addresses?.length > 0) {
    addressList = offering.addresses.map(address => (
      <ul key={address.address}>
        <li>{address.address}</li>
      </ul>
    ));
  }

  return (
    <div className={styles.infobar} data-testid="infobar">
      {closingInInterest && closingAllowsInvestment && !closing?.investment && (
        <Alert>
          <span>
            <strong>Want to invest in this offering?</strong> Indicate interest
            to get priority access to invest when funding becomes available. If
            we are unable to accommodate your investment in this series, we will
            prioritize investments on a first-come, first-served basis in
            subsequent series.
          </span>
        </Alert>
      )}
      {closingAllowsInvestment && mustVerifyToInvest && (
        <Alert type="warning" data-testid="mustVerifyMessage">
          <div>
            This offering is no longer accepting investments from investors that
            have not verified their accreditation. Please{' '}
            <Link
              to="/settings/accreditation"
              className="text-link"
              data-testid="mustVerifyLink"
            >
              verify your accreditation
            </Link>{' '}
            to invest.
          </div>
        </Alert>
      )}
      <div className={styles.contentRow}>
        {offering.sponsor && (
          <div className={styles.sponsor}>
            <div className={styles.companyLogo}>
              <img src={offering.sponsor.logo_thumb_url} alt="" />
            </div>
            <div className={styles.companyName}>{offering.sponsor.name}</div>
          </div>
        )}
        <div className={styles.addressWrap}>
          <Tooltip
            tooltipContent={
              <div className={styles.tooltipContent}>
                {alternateLocation
                  ? addressList || alternateLocation
                  : offering.addresses?.[0] && addressList}
              </div>
            }
            placement="top"
          >
            <div className={styles.address}>
              <i className="fa fa-map-marker" />
              {alternateLocation ||
                (offering.addresses?.[0]
                  ? offering.addresses[0].city +
                    ', ' +
                    offering.addresses[0].state
                  : 'unavailable')}
            </div>
          </Tooltip>
        </div>

        <InvestButton
          className={styles.investButton}
          mustVerifyToInvest={mustVerifyToInvest}
        />
      </div>
    </div>
  );
};

export default Infobar;
