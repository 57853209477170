import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React from 'react';
import { Field } from 'redux-form';
import PHONE_CODES from 'utilities/countryCodes';
import { phoneMask } from 'utilities/masks';

import * as styles from './PhoneFields.module.scss';

const PhoneFields = () => (
  <div className={`${styles.phoneFieldWrap} input-fixed-width-wide`}>
    <Field
      id="country_code"
      name="country_code"
      component={ReduxFormSelectField}
      label="Country Code"
      options={PHONE_CODES}
    />
    <Field
      id="phone"
      component={ReduxFormInputField}
      name="phone"
      mask={phoneMask}
      label="Phone Number"
    />
  </div>
);

export default PhoneFields;
