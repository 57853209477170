import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { updatePassword, updateUsername } from 'redux/actions/user-settings';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { twoFaSetupRequired } from 'utilities/user';
import { throwSubmissionErrors } from 'utilities/validation';

import * as sharedStyles from '../../SettingsShared.module.scss';
import SettingUpdateEmailForm from '../forms/SettingUpdateEmailForm';
import SettingUpdatePasswordForm from '../forms/SettingUpdatePasswordForm';
import TwoFactorAuthentication from '../forms/TwoFactorAuthentication';
import UserDevice from '../forms/UserDevice/UserDevice';

interface Props {
  dispatch: Dispatch;
  initialValues: {
    unconfirmed_email?: string;
  };
  updatingPassword: boolean;
  updatingUsername: boolean;
  user: User;
}

const Security = ({
  dispatch,
  initialValues,
  updatingPassword,
  updatingUsername,
  user
}: Props) => {
  const twoFaSetupIsRequired = twoFaSetupRequired(user);

  const handleSubmitUsername = values => {
    return dispatch(updateUsername({ user: values }))
      .then(res => {
        humane.notice(res.message);
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const handleSubmitPassword = values => {
    return dispatch(updatePassword({ user: values }))
      .then(res => {
        humane.notice(res.message);
      })
      .catch(res => throwSubmissionErrors(res));
  };

  return (
    <div className="margin-top-xxx" data-testid="securityTab">
      <div className="margin-xxx">
        <Row className="margin-xxxx">
          <Col lg={3}>
            <h3 className={sharedStyles.h3}>Two-factor Authentication</h3>
          </Col>
          <Col lg={9}>
            <TwoFactorAuthentication />
          </Col>
        </Row>
      </div>

      {!twoFaSetupIsRequired && (
        <>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Change Your Password</h3>
            </Col>
            <Col lg={9}>
              <SettingUpdatePasswordForm
                onSubmit={handleSubmitPassword}
                updatingPassword={updatingPassword}
              />
            </Col>
          </Row>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Update Email</h3>
            </Col>
            <Col lg={9}>
              <SettingUpdateEmailForm
                updatingUsername={updatingUsername}
                initialValues={initialValues}
                onSubmit={handleSubmitUsername}
              />
            </Col>
          </Row>
          <Row className="margin-xxxx">
            <Col lg={3}>
              <h3 className={sharedStyles.h3}>Your Devices</h3>
            </Col>
            <Col lg={9}>
              <UserDevice />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

function mapStateToProps(store) {
  return {
    loading: store.userSettings.loading,
    updateUsername: store.userSettings.updateUsername,
    updatePassword: store.userSettings.updatePassword,
    updatingUsername: store.userSettings.updatingUsername,
    updatingPassword: store.userSettings.updatingPassword,
    initialValues: {
      unconfirmed_email: store.auth.user.unconfirmed_email
    },
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Security);
