import { ReduxFormCheckboxField } from 'components/ReduxFormFields';
import iconInfo from 'images/icons/info.svg?url';
import React from 'react';
import { Field } from 'redux-form';
import { OfferingDocument } from 'types/api/offering';
import utils from 'utilities/utils';

import { SignFormValues } from '../../types';
import DocumentDownload from '../DocumentDownload/DocumentDownload';
import Signature from '../Signature/Signature';
import * as styles from './AcknowledgeAndSign.module.scss';

interface Props {
  anyTouched: boolean;
  documentsNotDownloaded: boolean;
  formValues: SignFormValues;
  offeringId: number;
  setDownloadedField: (documentIndex: number) => void;
  signRequiredDocuments: OfferingDocument[];
}

export const AcknowledgeAndSign = ({
  anyTouched,
  documentsNotDownloaded,
  formValues,
  offeringId,
  signRequiredDocuments,
  setDownloadedField
}: Props) => {
  let documentNamesString = '';
  if (signRequiredDocuments) {
    const documentNames = signRequiredDocuments.map(document => document.name);
    documentNamesString = utils.arrayToCommaSeparatedString(documentNames);
  }

  const showDocumentError = anyTouched && documentsNotDownloaded;

  return (
    <>
      <h4>Acknowledge & Sign</h4>
      <div className="margin-x">
        <Field
          name="pledge1"
          id="pledge1"
          type="checkbox"
          className="margin-xxx"
          component={ReduxFormCheckboxField}
          label={
            <>
              I am an accredited investor according to{' '}
              <a
                className="underline"
                href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501"
                target="_blank"
                rel="noreferrer"
              >
                Rule 501
              </a>
              , and hereby confirm that all representations previously made
              during my account registration process with respect to my
              Accredited Investor status remain true, accurate and correct.
            </>
          }
        />
      </div>
      <p className={styles.documentInstructions}>
        <img
          className={showDocumentError ? styles.documentError : ''}
          alt=""
          src={iconInfo}
        />
        Attached documents must be downloaded
      </p>
      <div className="margin-xx">
        {signRequiredDocuments?.map((document, index) => (
          <DocumentDownload
            key={document.name}
            document={document}
            documentIndex={index}
            setDownloadedField={setDownloadedField}
            showErrors={anyTouched}
            downloaded={formValues?.documents?.[`doc${index}`]}
            offeringId={offeringId}
          />
        ))}
      </div>
      <div className="margin-x">
        <Field
          name="pledge2"
          id="pledge2"
          type="checkbox"
          component={ReduxFormCheckboxField}
          disabled={documentsNotDownloaded}
          label={`I have read and agree to the terms of the ${documentNamesString} attached thereto.`}
        />
      </div>
      <div className="margin-x">
        <Field
          name="pledge3"
          id="pledge3"
          type="checkbox"
          component={ReduxFormCheckboxField}
          disabled={!formValues?.pledge2}
          label="I have read and understood the provided documents in detail, including without limitation, all risk factors and conflicts of interest set forth therein."
        />
      </div>
      <p className="margin-xxx">
        By my signature below, I hereby execute the Subscription Agreement,
        certify that I have read and agree to the{' '}
        <a
          className="underline"
          href="https://equitymultiple.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        .
      </p>

      <h4>Sign and choose the style</h4>
      <Signature formValues={formValues} />
    </>
  );
};
