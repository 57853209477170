import { ReduxFormPasswordField } from 'components/ReduxFormFields';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { lowerCase, number, symbols, upperCase } from 'utilities/regex';
import utils from 'utilities/utils';

import * as styles from './SetPasswordFields.module.scss';

type PasswordStrength = 'strong' | 'medium' | 'low';

type Props = {
  changePassword?: boolean;
  hidePasswordDescription?: boolean;
};

const SetPasswordFields: React.FC<Props> = ({
  changePassword,
  hidePasswordDescription
}) => {
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [charLength, setCharLength] = useState(0);
  const [strength, setStrength] = useState<PasswordStrength>(null);

  const checkValidation = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const passwordHasNumber = number.test(value);
    const passwordHasUpperCase = upperCase.test(value);
    const passwordHasLowerCase = lowerCase.test(value);
    const passwordHasSymbol = symbols.test(value);

    let strengthLevel = 0;

    if (value.length >= 8) strengthLevel += 2;
    if (passwordHasNumber) strengthLevel += 1;
    if (passwordHasUpperCase) strengthLevel += 1;
    if (passwordHasLowerCase) strengthLevel += 1;
    if (passwordHasSymbol) strengthLevel += 1;

    let passwordStrength: PasswordStrength = 'low';

    if (strengthLevel > 3) passwordStrength = 'medium';
    if (strengthLevel > 4) passwordStrength = 'strong';

    setHasNumber(passwordHasNumber);
    setHasUpperCase(passwordHasUpperCase);
    setHasLowerCase(passwordHasLowerCase);
    setHasSymbol(passwordHasSymbol);
    setCharLength(value.length);
    setStrength(passwordStrength);
  };

  const label = changePassword ? 'New Password' : 'Password';

  const renderHelperText = () => (
    <>
      <div>
        Password strength:{' '}
        <span className={`${styles.strengthText} ${styles[strength]}`}>
          {utils.capitalize(strength)}
        </span>
      </div>
      <div className={`${styles.strengthMeter} ${styles[strength]}`}>
        <div />
        {strength === 'medium' && <div />}
        {strength === 'strong' && (
          <>
            <div />
            <div />
          </>
        )}
      </div>

      {strength !== 'strong' && (
        <div className={styles.requirementsList}>
          <div
            className={`${styles.requirement} ${
              charLength >= 8 ? styles.met : ''
            }`}
          >
            8 Character Min.
          </div>
          <div
            className={`${styles.requirement} ${
              hasLowerCase ? styles.met : ''
            }`}
          >
            1 Lowercase
          </div>
          <div
            className={`${styles.requirement} ${
              hasUpperCase ? styles.met : ''
            }`}
          >
            1 Uppercase
          </div>
          <div
            className={`${styles.requirement} ${hasNumber ? styles.met : ''}`}
          >
            1 Number
          </div>
          <div
            className={`${styles.requirement} ${hasSymbol ? styles.met : ''}`}
          >
            1 Symbol
          </div>
        </div>
      )}
    </>
  );

  return (
    <div>
      {!hidePasswordDescription && (
        <div className="description margin-xx">
          Provide a secure password for your account. After this step, you'll be
          able to log in to EquityMultiple with your email and password anytime.
        </div>
      )}

      <Field
        name="password"
        id="password"
        data-testid="password"
        onChange={checkValidation}
        component={ReduxFormPasswordField}
        label={label}
        helperText={charLength > 0 && renderHelperText()}
        helperTextAlwaysVisible
        className={`input-fixed-width-wide ${styles.passwordField}`}
      />
      <Field
        name="confirmPassword"
        id="confirmPassword"
        data-testid="confirmPassword"
        component={ReduxFormPasswordField}
        label="Confirm Password"
        className="input-fixed-width-wide margin-top-xx"
      />
    </div>
  );
};

export default SetPasswordFields;
