import { AnyAction } from 'redux';

import * as actions from '../actions/account';

const initialState = {
  investmentAccount: null,
  investmentAccounts: null,
  pendingInvestments: [],
  loaded: true,
  loading: false,
  submitting: false,
  uploading: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_ALL:
    case actions.LOAD_BANK_ACCOUNT:
    case actions.LOAD_CLASSIFICATIONS:
    case actions.LOAD_INVESTMENT_ACCOUNT:
    case actions.LOAD_BENEFICIAL_OWNER:
    case actions.REMOVE_BENEFICIAL_OWNER:
    case actions.LOAD_PENDING_INVESTMENTS:
    case actions.REMOVE_BANK_ACCOUNT:
      return {
        ...state,
        loading: true,
        loaded: false,
        submitting: false,
        submitted: false,
        uploading: false
      };

    case actions.LOAD_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        investmentAccounts: action.result
      };

    case actions.LOAD_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        businessClassifications: action.result.business_classifications
      };

    case actions.LOAD_INVESTMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        investmentAccount: action.result.investment_account,
        dwollaCustomerStatus: action.result.dwolla_customer_status,
        documents: action.result.documents,
        bankAccounts: action.result.bank_accounts,
        beneficialOwners: action.result.beneficial_owners
      };

    case actions.LOAD_BENEFICIAL_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        beneficialOwner: action.result.beneficial_owner
      };

    case actions.LOAD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: false,
        bankAccount: action.result
      };

    case actions.LOAD_PENDING_INVESTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingInvestments: action.result
      };

    case actions.LOAD_ALL_FAIL:
    case actions.LOAD_BANK_ACCOUNT_FAIL:
    case actions.LOAD_CLASSIFICATIONS_FAIL:
    case actions.LOAD_INVESTMENT_ACCOUNT_FAIL:
    case actions.LOAD_BENEFICIAL_OWNER_FAIL:
    case actions.REMOVE_BENEFICIAL_OWNER_FAIL:
    case actions.LOAD_PENDING_INVESTMENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loadErrors: action.result
      };

    case actions.SUBMIT_INVESTMENT_ACCOUNT:
    case actions.CREATE_BENEFICIAL_OWNER:
    case actions.UPDATE_BENEFICIAL_OWNER:
    case actions.CREATE_BANK_ACCOUNT:
    case actions.UPDATE_POA_FORM:
    case actions.UPDATE_W9_FORM:
      return {
        ...state,
        submitting: true,
        submitted: false
      };

    case actions.SUBMIT_INVESTMENT_ACCOUNT_SUCCESS:
    case actions.CREATE_BENEFICIAL_OWNER_SUCCESS:
    case actions.UPDATE_BENEFICIAL_OWNER_SUCCESS:
    case actions.CREATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: true
      };

    case actions.UPDATE_W9_FORM_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: true,
        w9SignUrl: action.result.signing_url
      };

    case actions.UPDATE_POA_FORM_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitted: true,
        poaAgreementSigningUrl: action.result
      };

    case actions.SUBMIT_INVESTMENT_ACCOUNT_FAIL:
    case actions.CREATE_BENEFICIAL_OWNER_FAIL:
    case actions.UPDATE_BENEFICIAL_OWNER_FAIL:
    case actions.CREATE_BANK_ACCOUNT_FAIL:
    case actions.UPDATE_W9_FORM_FAIL:
    case actions.UPDATE_POA_FORM_FAIL:
    case actions.REMOVE_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        submitting: false,
        submitted: false,
        errors: action.result
      };

    case actions.UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT:
    case actions.UPLOAD_W9_OFFLINE:
      return {
        ...state,
        uploading: true
      };

    case actions.UPLOAD_PROFESSIONAL_LETTER:
      return {
        ...state,
        uploading: true
      };

    case actions.UPLOAD_W9_OFFLINE_SUCCESS:
      return {
        ...state,
        uploading: false,
        documents: {
          w9_signed_url: action.result.signing_url
        }
      };

    case actions.UPLOAD_W9_OFFLINE_FAIL:
    case actions.UPLOAD_PROFESSIONAL_LETTER_FAIL:
      return {
        ...state,
        uploading: false,
        errors: action.result
      };

    case actions.UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_SUCCESS:
    case actions.UPLOAD_INVESTMENT_ACCOUNT_DOCUMENT_FAIL:
      return {
        ...state,
        uploading: false
      };

    case actions.UPLOAD_PROFESSIONAL_LETTER_SUCCESS:
      return {
        ...state,
        uploading: false
      };

    case actions.REMOVE_BENEFICIAL_OWNER_SUCCESS:
    case actions.REMOVE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actions.CLEAR_INVESTMENT_ACCOUNT:
      return {
        ...state,
        investmentAccount: null
      };

    default:
      return state;
  }
};
