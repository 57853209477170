import { Button, Card, ErrorLabel } from '@equitymultiple/react-eui';
import useRedirectUserWIthInterestAscentInvestment from 'hooks/useRedirectUserWIthInterestAscentInvestment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import history from '../../../../browserHistory';
import Contact from '../../../../components/Contact/Contact';
import ProgressBarBorder from '../../../../components/ProgressBarBorder/ProgressBarBorder';
import { ReduxFormInputField } from '../../../../components/ReduxFormFields';
import { loadCompletedAccounts } from '../../../../redux/actions/investment-account';
import {
  clearInvestment,
  loadInvestment,
  sendPledge,
  updateInvestment
} from '../../../../redux/actions/investments';
import { loadClosing } from '../../../../redux/actions/offerings';
import { InvestmentAccount } from '../../../../types/api/account';
import { Closing } from '../../../../types/api/closing';
import { Investment } from '../../../../types/api/investment';
import { ClosingOffering } from '../../../../types/api/offering';
import { User } from '../../../../types/api/user';
import { Dispatch } from '../../../../types/redux';
import EmAnalytics from '../../../../utilities/em_analytics';
import { handleErrorResponse } from '../../../../utilities/errorHandlers';
import { numberMaskOptions } from '../../../../utilities/masks';
import utils from '../../../../utilities/utils';
import {
  scrollToError,
  throwSubmissionErrors
} from '../../../../utilities/validation';
import { validateSchema } from '../../../../utilities/yupValidations';
import { AccountSelection } from '../../components/constants';
import InterestOrWaitlistSummary from '../../components/InterestOrWaitlistSummary';
import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import InvestmentTitle from '../../components/InvestmentTitle/InvestmentTitle';
import {
  checkIfEditing,
  redirectIfInvestmentIsClosed,
  redirectIfPendingRollover,
  redirectIfUserCannotInvest
} from '../../helpers';
import { interestSchema } from '../../validations';
import * as styles from './../../Investment.module.scss';

const LoadingSkeleton = () => (
  <Card className="border-top-card">
    <div data-testid="loadingSkeleton">
      <h5>
        <Skeleton width="30%" />
      </h5>
      <p>
        <Skeleton width="70%" />
      </p>
      <h6>
        <Skeleton width="30%" />
      </h6>
      <p className="margin-xxx">
        <Skeleton width="50%" />
      </p>
      <h6>
        <Skeleton width="40%" />
      </h6>
      <p>
        <Skeleton width="90%" />
      </p>
      <p>
        <Skeleton width="30%" />
      </p>
    </div>
  </Card>
);

type Params = {
  closing_id: string;
  investment_id: string;
};

interface CustomProps {
  accounts: InvestmentAccount[];
  closing: Closing;
  dispatch: Dispatch;
  editingInvestment: boolean;
  investment: Investment;
  loading: boolean;
  offering: ClosingOffering;
  sending: boolean;
  user: User;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const IndicateInterest = ({
  accounts,
  dispatch,
  editingInvestment,
  error: errorMessage,
  handleSubmit,
  investment,
  match: { params, path },
  offering,
  closing,
  loading,
  sending,
  user
}: Props) => {
  useRedirectUserWIthInterestAscentInvestment(dispatch, offering);

  const [editRoute, setEditRoute] = useState(false);

  useEffect(() => {
    redirectIfUserCannotInvest(user);

    document.title = 'Indicate Interest | EquityMultiple';

    if (!params.closing_id) {
      history.push('/');
    } else {
      if (path === '/invest/:closing_id/investment/:investment_id/edit')
        setEditRoute(true);
      dispatch(loadClosing(params.closing_id))
        .then(res => {
          redirectIfInvestmentIsClosed(res.closing);
          if (res.investment?.id) {
            dispatch(loadInvestment(res.investment.id))
              .then(investmentResponse => {
                redirectIfPendingRollover(
                  investmentResponse,
                  params.closing_id
                );
              })
              .catch(error =>
                handleErrorResponse(
                  error,
                  `/invest/${params.closing_id}/investment/new`
                )
              );
          } else {
            dispatch(clearInvestment());
          }
        })
        .catch(error => handleErrorResponse(error));
      dispatch(loadCompletedAccounts()).catch(error =>
        handleErrorResponse(error)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    if (values.id) {
      const data = {
        closing_id: closing.id,
        investment: {
          investment_account_id: values.investment_account,
          amount: utils.currencyStringToNumber(values.investment_amount)
        }
      };

      return dispatch(updateInvestment(data, values.id))
        .then(res => {
          if (res.status === 'waitlist')
            history.push(`/invest/${closing.id}/investment/${res.id}/interest`);
          else history.push(`/invest/${closing.id}/investment/${res.id}/sign`);
        })
        .catch(error => throwSubmissionErrors(error));
    } else {
      const data = {
        offering_id: offering.id,
        closing_id: closing.id,
        investment: {
          investment_account_id: values.investment_account,
          amount: values.investment_amount
        }
      };
      return dispatch(sendPledge(data))
        .then(res => {
          if (res.status === 'interest') {
            EmAnalytics.track('Investment Started', 'Investment', {
              amount: res.amount,
              currency: 'USD',
              first_time_investment: res.first_time_investment,
              investment_account_type: res.investment_account_type,
              investmentId: res.id,
              label: 'Amount',
              orderId: `${res.user_id}.${res.id}`,
              value: res.amount,
              offering_title: res.offering_title,
              close_on: res.close_on,
              offering_type: res.offering_type
            });
          }
          if (res.status !== 'waitlist') {
            history.push(`/invest/${closing.id}/investment/${res.id}/sign`);
          } else {
            history.push(`/invest/${closing.id}/investment/${res.id}/interest`);
          }
        })
        .catch(error => throwSubmissionErrors(error));
    }
  };

  const stepDisabled = investment?.id && investment?.status === 'waitlist';
  const notEdit = !(editRoute || editingInvestment);

  const closingInInterestOrWaitlist =
    closing?.stage === 'interest' || closing?.stage === 'waitlist';
  const investmentOnWaitlist = investment?.status === 'waitlist';
  const showInterestOrWaitlistSummary =
    closingInInterestOrWaitlist && investmentOnWaitlist;

  // TODO: Remove the investment class and convert this to modules once all child components have been refactored to use modules
  return (
    <div className="investment">
      <Container className="container-narrow">
        <InvestmentTitle
          title={offering.title}
          stage={closing.stage}
          loading={loading}
        />
        <InvestmentStatus
          step={1}
          investment={investment}
          closing={closing}
          editingInterestPage={!notEdit}
          loading={loading}
        />
        {loading ? (
          <LoadingSkeleton />
        ) : showInterestOrWaitlistSummary ? (
          <InterestOrWaitlistSummary
            closingStage={closing?.stage}
            investmentAmount={investment?.amount}
          />
        ) : (
          <Card className="border-top-card" data-testid="indicateInterestForm">
            <ProgressBarBorder currentStep={1} steps={4} />
            <h3 className="margin-top-0 margin-x">
              {notEdit ? 'Indicate Interest' : 'Select New Investment Amount'}
            </h3>
            <p>
              A Pledge is an indication of interest, and does not reserve your
              position in the offering.
            </p>
            <h4>How much are you investing?</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Field
                    className="dollar-amount"
                    name="investment_amount"
                    id="investment-amount"
                    component={ReduxFormInputField}
                    label="Investment Amount"
                    maxLength={12}
                    dollarMask
                    inputMaskOptions={numberMaskOptions}
                  />
                </Col>
              </Row>
              {notEdit && (
                <div>
                  <div className="margin-xx">
                    <h4>Select how you&apos;ll be investing</h4>
                    <p>
                      Select the Account to use for your investment. Note that
                      under SEC rules, we are required to verify the current
                      accreditation status of the account.
                    </p>
                    {AccountSelection(accounts)}
                  </div>
                </div>
              )}
              <div className="margin-xx">
                {errorMessage && <ErrorLabel message={errorMessage} />}
              </div>
              <div
                className={`forwardBackButtonWrapCompact float-right ${styles.formButtons}`}
              >
                <Button
                  type="submit"
                  className={styles.submitButton}
                  variant="orange"
                  disabled={stepDisabled || !!errorMessage}
                  loading={sending}
                >
                  Next
                </Button>
              </div>
            </Form>
          </Card>
        )}

        <Contact />
      </Container>
    </div>
  );
};

interface InitialFormValues {
  allowPartialShares?: boolean;
  id?: number;
  increment?: number;
  investment_account?: string;
  investment_amount?: string;
  range?: {
    maxRange?: number;
    minRange?: number;
  };
}

function mapStateToProps(state, ownProps) {
  const investment = state.investments.investment;
  let initialValues: InitialFormValues = {};
  let isEditing = false;
  let totalFunded = 0;
  let amount = 0;
  if (
    state.offerings.offering &&
    state.offerings.closing &&
    state.investmentAccount.account
  ) {
    const { account } = state.investmentAccount;
    const minimumInvestmentAmount =
      state.offerings.closing.min_investment_amount;
    const maximumInvestmentAmount =
      state.offerings.closing.max_investment_amount;
    const {
      partial_shares: partialShares,
      investment_increment: investmentIncrement
    } = state.offerings.offering;
    let minRange = minimumInvestmentAmount;
    if (investment?.override_minimum)
      minRange = investment.custom_minimum_investment_amount;
    if (investment) amount = investment.amount;
    if (investment?.transactions?.length > 0) {
      isEditing = checkIfEditing(
        investment.amount,
        investment.transactions.contributions,
        investment.transactions.refunds
      );
      if (isEditing) {
        investment.transactions.contributions.forEach(transaction => {
          totalFunded += parseFloat(transaction.amount);
        });
        if (investment.transactions.refunds) {
          investment.transactions.refunds.forEach(transaction => {
            totalFunded -= parseFloat(transaction.amount);
          });
        }
        amount = totalFunded;
      }
    }

    if (
      (ownProps.match.path &&
        ownProps.match.path ===
          '/invest/:closing_id/investment/:investment_id/edit') ||
      isEditing
    )
      minRange = amount + parseFloat(investmentIncrement);

    initialValues = {
      allowPartialShares: partialShares,
      investment_account: account.length === 1 ? `${account[0].id}` : undefined,
      range: {
        minRange,
        maxRange: parseFloat(maximumInvestmentAmount)
      },
      increment: investmentIncrement
    };

    if (investment?.id) {
      initialValues.id = investment.id;
      initialValues.investment_amount = utils.formatCurrency(
        investment.amount,
        0
      );
      initialValues.investment_account = `${investment.investment_account_id}`;
    }
  }

  return {
    accounts: state.investmentAccount.account,
    editingInvestment: isEditing,
    investment: state.investments.investment || state.offerings.investment,
    loading:
      state.offerings.loadingClosing ||
      state.investments.loading ||
      state.investmentAccount.loading,
    offering: state.offerings.offering,
    closing: state.offerings.closing,
    sending: state.investments.sending,
    initialValues,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'interest',
    enableReinitialize: true,
    validate: validateSchema(interestSchema),
    onSubmitFail: scrollToError
  })(IndicateInterest)
);
