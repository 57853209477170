/* eslint-disable @typescript-eslint/naming-convention */

import { Button, Tooltip } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormDateSelectField,
  ReduxFormInputField,
  ReduxFormRadioField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getBusinessClassifications,
  loadInvestmentAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import {
  entityBusinessTypes,
  nonUSResidentBusinessTypes,
  portfolioValues,
  stateOptions
} from '../contents';
import { formatDate, isComplete } from '../helpers';
import { entitySchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

interface Params {
  reference_id: string;
}

interface CustomProps {
  businessClass: string;
  businessClassifications: {
    industries: [
      {
        id: string;
        name: string;
      }
    ];
    name: string;
  }[];
  dispatch: Dispatch;
  einVerificationOptional: boolean;
  investmentAccount: SingleInvestmentAccount;
  loading: boolean;
  residenceStatus: string;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const Entity = ({
  businessClassifications,
  businessClass,
  dispatch,
  einVerificationOptional,
  handleSubmit,
  investmentAccount,
  loading,
  match: { params },
  residenceStatus,
  submitting
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Entity Account';
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
    dispatch(getBusinessClassifications()).catch(error =>
      handleErrorResponse(error)
    );
  }, [dispatch, params]);

  const onSubmit = values => {
    values.investment_account.id = investmentAccount.id;
    return dispatch(updateInvestmentAccount(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          history.push(
            `/accounts/update/entity/${investmentAccount.reference_id}`
          );
        } else {
          EmAnalytics.track('Submits Entity Account Information', 'Onboarding');
          history.push(
            `/accounts/wizard/entity/organizational_document/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(err => throwSubmissionErrors(err));
  };

  const isLoading = loading || !businessClassifications?.length;

  const businessTypes =
    residenceStatus === 'Other'
      ? nonUSResidentBusinessTypes
      : entityBusinessTypes;

  let industries = [];
  if (!isLoading) {
    industries = businessClass
      ? businessClassifications.filter(
          businessClassification =>
            businessClassification.name === businessClass
        )[0].industries
      : businessClassifications[0].industries;
  }

  return (
    <AccountWrap loading={isLoading} account={investmentAccount}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">Tell Us About Your Entity</h4>
        <p className="margin-xx">
          We'll collect your Entity account information. All of the information
          you provide is kept private and secure.
        </p>
        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.entity_name"
              id="entity_name"
              component={ReduxFormInputField}
              label="Entity Name"
            />
          </Col>
          <Col md={6} className="overflow-visible">
            <Field
              name="investment_account.business_type"
              id="business_type"
              component={ReduxFormSelectField}
              label="Entity Type"
              options={businessTypes.map(key => ({
                label: key.label,
                value: key.value
              }))}
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.officer_title"
              id="officer_title"
              component={ReduxFormInputField}
              label="Title"
            />
          </Col>
          <Col md={6}>
            <Field
              name="investment_account.ein"
              id="ein"
              mask="99-9999999"
              component={ReduxFormInputField}
              label={`EIN ${einVerificationOptional ? '(Optional)' : ''}`}
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col>
            <Field
              name="investment_account.date_of_registration"
              id="date_of_registration"
              component={ReduxFormDateSelectField}
              label="State Business Registration Date"
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col>
            <Field
              name="investment_account.date_of_formation"
              id="date_of_formation"
              component={ReduxFormDateSelectField}
              label="Date of Formation"
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.jurisdiction_of_business_registration"
              id="jurisdiction_of_business_registration"
              component={ReduxFormSelectField}
              label="State Where Organized"
              options={stateOptions}
            />
          </Col>
          <Col md={6}>
            <Field
              name="investment_account.business_class"
              id="business_class"
              component={ReduxFormSelectField}
              label="Business Class"
              options={businessClassifications?.map(key => ({
                label: key.name,
                value: key.name
              }))}
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.industry_class"
              id="industry_class"
              component={ReduxFormSelectField}
              label="Industry"
              options={industries.map(key => ({
                label: key.name,
                value: key.name
              }))}
            />
          </Col>
        </Row>

        <h4>What is the address of your Entity?</h4>
        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.address"
              id="address"
              component={ReduxFormInputField}
              label="Address"
            />
          </Col>
          <Col md={6}>
            <Field
              name="investment_account.address2"
              id="address2"
              component={ReduxFormInputField}
              label="Address 2"
            />
          </Col>
        </Row>
        <Row className="margin-row">
          <Col md={4}>
            <Field
              name="investment_account.city"
              id="city"
              component={ReduxFormInputField}
              label="City"
            />
          </Col>
          <Col md={4} className="overflow-visible">
            <Field
              name="investment_account.region"
              id="state"
              component={ReduxFormSelectField}
              label="State"
              options={stateOptions}
            />
          </Col>
          <Col md={4}>
            <Field
              name="investment_account.postal_code"
              id="postal_code"
              mask="99999"
              component={ReduxFormInputField}
              label="Postal Code"
            />
          </Col>
        </Row>

        <h4>
          Next, we'll collect information about the entity through which you'd
          like to invest
        </h4>

        <h6>
          Is this Entity a retirement purpose vehicle?
          <Tooltip
            tooltipContent="Please select this option if the entity is a 401K Plan or a checkbook access Individual Retirement Account."
            className="info-icon-margin-left"
            infoIcon
          />
        </h6>
        <div className="margin-xx radio-group">
          <Field
            type="radio"
            name="investment_account.retirement_purpose_vehicle"
            id="retirement_purpose_vehicle_true"
            label="Yes"
            value="true"
            hideError
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
          <Field
            type="radio"
            name="investment_account.retirement_purpose_vehicle"
            id="retirement_purpose_vehicle_false"
            label="No"
            value="false"
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
        </div>

        <h6>Is this entity publicly traded?</h6>
        <div className="margin-xx radio-group">
          <Field
            type="radio"
            name="investment_account.entity_publicly_traded"
            id="entity_publicly_traded_true"
            label="Yes"
            value="true"
            hideError
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
          <Field
            type="radio"
            name="investment_account.entity_publicly_traded"
            id="entity_publicly_traded_false"
            label="No"
            value="false"
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
        </div>

        <h6>
          Is this entity presently involved in a bankruptcy, insolvency, or
          reorganization?
        </h6>
        <div className="margin-xx radio-group">
          <Field
            type="radio"
            name="investment_account.entity_current_bankruptcy"
            id="entity_current_bankruptcy_true"
            label="Yes"
            value="true"
            hideError
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
          <Field
            type="radio"
            name="investment_account.entity_current_bankruptcy"
            id="entity_current_bankruptcy_false"
            label="No"
            value="false"
            component={ReduxFormRadioField}
            normalize={utils.normalizeBoolean}
            format={utils.formatBoolean}
          />
        </div>

        <Row className="margin-row overflow-visible">
          <Col md={6}>
            <Field
              name="investment_account.entity_portfolio_value"
              id="entity_portfolio_value"
              component={ReduxFormSelectField}
              label="Portfolio Value"
              options={portfolioValues.map(key => ({
                label: key,
                value: key
              }))}
            />
          </Col>
        </Row>

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            variant="orange"
            loading={submitting}
            style={{ width: 160 }}
          >
            {isComplete(investmentAccount) ? 'Update' : 'Continue'}
          </Button>
          <BackLink investmentAccount={investmentAccount} page="Entity" />
        </div>
      </Form>
    </AccountWrap>
  );
};

const selector = formValueSelector('investment_account');

function mapStateToProps(state) {
  const residenceStatus = state.auth.user?.investor_profile?.residence_status;
  const investmentAccount = state.account?.investmentAccount;
  const einVerificationOptional = [
    'trust_revocable',
    'soleproprietorship'
  ].includes(investmentAccount?.business_type);
  let businessClass = selector(state, 'investment_account.business_class');
  if (businessClass) businessClass = businessClass.split(':')[0];

  let investmentAccountInitialValues = {};
  if (investmentAccount) {
    const {
      entity_name,
      business_type,
      officer_title,
      ein,
      jurisdiction_of_business_registration,
      date_of_registration,
      date_of_formation,
      business_class,
      industry_class,
      address,
      address2,
      city,
      postal_code,
      region,
      retirement_purpose_vehicle,
      entity_publicly_traded,
      entity_portfolio_value,
      entity_current_bankruptcy
    } = investmentAccount;
    investmentAccountInitialValues = {
      entity_name,
      business_type,
      officer_title,
      ein,
      jurisdiction_of_business_registration,
      date_of_registration: formatDate(date_of_registration),
      date_of_formation: formatDate(date_of_formation),
      business_class,
      industry_class,
      address,
      address2,
      city,
      postal_code,
      region,
      retirement_purpose_vehicle,
      entity_publicly_traded,
      entity_portfolio_value,
      entity_current_bankruptcy
    };
  }

  return {
    businessClass,
    businessClassifications: state.account?.businessClassifications,
    einVerificationOptional,
    initialValues: {
      investment_account: investmentAccountInitialValues
    },
    investmentAccount,
    loading: state.account.loading,
    residenceStatus,
    submitting: state.account.submitting
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(entitySchema)
  })(Entity)
);
