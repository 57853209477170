import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormCheckboxField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  loadInvestmentAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { phoneMask } from 'utilities/masks';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { stateOptions } from '../contents';
import { isComplete } from '../helpers';
import { jointSecondaryHolderSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
  loading: boolean;
  sameAddress: boolean;
  submitting: boolean;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const JointSecondaryHolder = ({
  dispatch,
  handleSubmit,
  investmentAccount,
  loading,
  match,
  sameAddress,
  submitting
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Joint Account Secondary Account Holder';
    dispatch(loadInvestmentAccount(match.params.reference_id));
  }, [dispatch, match]);

  const onSubmit = values => {
    values.investment_account.id = investmentAccount.id;
    return dispatch(updateInvestmentAccount(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          history.push(
            `/accounts/update/joint/${investmentAccount.reference_id}`
          );
        } else {
          EmAnalytics.track('Submits Joint Profile Information', 'Onboarding');
          history.push(
            `/accounts/wizard/joint/secondary_account/identification_document/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(err => throwSubmissionErrors(err));
  };

  return (
    <AccountWrap loading={loading} account={investmentAccount}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">
          Tell Us About Your Secondary Account Holder
        </h4>
        <p className="margin-xx">
          We'll collect your secondary account holder's personal information.
          All of the information you provide is kept private and secure.
        </p>
        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.account_holder_2_first_name"
              component={ReduxFormInputField}
              label="Legal First Name"
            />
          </Col>
          <Col md={6}>
            <Field
              name="investment_account.account_holder_2_last_name"
              component={ReduxFormInputField}
              label="Legal Last Name"
            />
          </Col>
        </Row>

        <Row className="margin-row">
          <Col md={6}>
            <Field
              id="phone"
              component={ReduxFormInputField}
              name="investment_account.account_holder_2_phone"
              mask={phoneMask}
              label="Phone"
            />
          </Col>
          <Col md={6}>
            <Field
              id="email"
              name="investment_account.account_holder_2_email"
              component={ReduxFormInputField}
              label="Email"
            />
          </Col>
        </Row>

        <h5 className="margin-x">
          What is the secondary account holder's address?
        </h5>

        <Field
          name="investment_account.account_holder_2_address_is_same"
          id="account_holder_2_address_is_same"
          component={ReduxFormCheckboxField}
          type="checkbox"
          className="margin-x"
          label="The secondary account holder associated with this joint account has the same address"
        />

        {!sameAddress && (
          <div>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="investment_account.account_holder_2_address"
                  component={ReduxFormInputField}
                  label="Address"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="investment_account.account_holder_2_address2"
                  component={ReduxFormInputField}
                  label="Address 2"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={4}>
                <Field
                  name="investment_account.account_holder_2_city"
                  component={ReduxFormInputField}
                  label="City"
                />
              </Col>
              <Col md={4} className="overflow-visible">
                <Field
                  name="investment_account.account_holder_2_region"
                  id="account_holder_2_region"
                  component={ReduxFormSelectField}
                  label="State"
                  options={stateOptions}
                />
              </Col>
              <Col md={4}>
                <Field
                  name="investment_account.account_holder_2_postal_code"
                  mask="99999"
                  component={ReduxFormInputField}
                  label="Postal Code"
                />
              </Col>
            </Row>
          </div>
        )}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            style={{ width: 160 }}
            variant="orange"
          >
            {isComplete(investmentAccount) ? 'Update' : 'Continue'}
          </Button>
          <BackLink
            investmentAccount={investmentAccount}
            page="Secondary Holder"
          />
        </div>
      </Form>
    </AccountWrap>
  );
};

const selector = formValueSelector('investment_account');

function mapStateToProps(state) {
  const sameAddress = selector(
    state,
    'investment_account.account_holder_2_address_is_same'
  );
  const investmentAccount = state.account.investmentAccount;

  const investmentAccountInitialValues = investmentAccount
    ? {
        account_holder_2_address: investmentAccount.account_holder_2_address,
        account_holder_2_address2: investmentAccount.account_holder_2_address2,
        account_holder_2_address_is_same:
          investmentAccount.account_holder_2_address_is_same,
        account_holder_2_city: investmentAccount.account_holder_2_city,
        account_holder_2_email: investmentAccount.account_holder_2_email,
        account_holder_2_first_name:
          investmentAccount.account_holder_2_first_name,
        account_holder_2_last_name:
          investmentAccount.account_holder_2_last_name,
        account_holder_2_phone: investmentAccount.account_holder_2_phone,
        account_holder_2_postal_code:
          investmentAccount.account_holder_2_postal_code,
        account_holder_2_region: investmentAccount.account_holder_2_region
      }
    : {
        account_holder_2_address_is_same: false
      };

  return {
    initialValues: {
      investment_account: investmentAccountInitialValues
    },
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading,
    sameAddress: sameAddress || false,
    submitting: state.account.submitting
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(jointSecondaryHolderSchema)
  })(JointSecondaryHolder)
);
