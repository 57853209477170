import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import { ReduxFormSelectField } from 'components/ReduxFormFields';
import Illustration from 'images/signup-illustration.svg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import { submitSignupForm } from 'redux/actions/onboarding';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { accreditationOptions } from '../content';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import { SharedSignupProps } from '../types';
import { accreditationSchema } from '../validation';
import * as styles from './Accreditation.module.scss';

const Accreditation = ({
  dispatch,
  error,
  handleSubmit,
  loadingAuth,
  submitting,
  initialValues,
  user
}: SharedSignupProps) => {
  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    if (!loadingAuth) redirectIfPreviousStepsIncomplete(initialValues, 4, user);
  }, [initialValues, loadingAuth, user]);

  const onSubmit = values => {
    return dispatch(
      user
        ? updateUserProfile({
            investor_profile_attributes: {
              accreditation: values.accreditation
            }
          })
        : submitSignupForm({
            step: 4,
            user: {
              ...values,
              email: initialValues.email
            }
          })
    )
      .then(() => {
        dispatch(loadAuth()).then(() => {
          history.push('/users/signup/continue');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <SignUpWrap
      introBackgroundColor="blue"
      introContent={
        <>
          <Illustration className={styles.illustration} />
          <h1 className={styles.headingWithIllustration}>
            Helping You Climb Above Uncertainty.
          </h1>
          <p>
            Streamlined access to a range of real estate strategies. Easily
            diversify away from public markets. Invest in minutes, then relax
            and enjoy.
          </p>
        </>
      }
      step={4}
      user={user}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Are you an Accredited Investor?</h3>
        <p className="margin-xx">
          EquitMultiple accepts investments from Accredited Investors only. To
          help us understand which investment opportunities are best for you,
          please provide this information about your accredited status.
        </p>
        <Field
          name="accreditation"
          id="accreditation"
          component={ReduxFormSelectField}
          label="Select what describes you best"
          options={accreditationOptions}
          multi
          showMultiLabelsBelow
          data-testid="accreditation"
        />
        {error && <ErrorLabel message={error} className="margin-xxx" />}
        <Button
          type="submit"
          loading={submitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
      </Form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const signupValues = store.auth.signupValues;
  const user = store.auth.user;
  let initialValues = signupValues;
  if (!store.auth.loading && user) {
    initialValues = {
      email: user.email,
      accreditation: user.investor_profile.accreditation,
      first_name: user.first_name || signupValues.first_name,
      last_name: user.last_name || signupValues.last_name
    };
  }

  return {
    initialValues,
    loadingAuth: store.auth.loading,
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'accreditation',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(accreditationSchema)
  })(Accreditation)
);
