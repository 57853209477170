import {
  Alert,
  Button,
  DocumentIframe,
  FileIcon,
  FileUploader,
  RadioButton
} from '@equitymultiple/react-eui';
import history from 'browserHistory';
import { ReduxFormSelectField } from 'components/ReduxFormFields';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  checkDocumentSignStatus,
  generateW9Form,
  loadInvestmentAccount,
  offlineW9Upload,
  updateInvestmentAccount
} from 'redux/actions/account';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import * as sharedStyles from '../AccountShared.module.scss';
import { taxFilingStatuses } from '../contents';
import { isComplete } from '../helpers';
import { w9Schema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

interface Params {
  reference_id: string;
}

interface CustomProps {
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
  uploading: boolean;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const W9DocumentUpdate = ({
  investmentAccount,
  submitting,
  uploading,
  dispatch,
  handleSubmit,
  match: { params }
}: Props) => {
  const [loadingW9, setLoadingW9] = useState(true);
  const [w9Signed, setW9Signed] = useState(false);
  const [w9DownloadUrl, setW9DownloadUrl] = useState(null);
  const [w9SignUrl, setW9SignUrl] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [signSelected, setSignSelected] = useState(false);
  const [uploadSelected, setUploadSelected] = useState(false);

  const loadSignedW9 = () => {
    dispatch(checkDocumentSignStatus(params.reference_id, 'w9_form')).then(
      doc => {
        if (doc.status === 'Complete' && doc.download_url) {
          setW9Signed(true);
          setW9DownloadUrl(doc.download_url);
        }
      }
    );
  };

  useEffect(() => {
    document.title = 'My Accounts | Organizational Document';

    let checkDocumentInterval;

    dispatch(loadInvestmentAccount(params.reference_id))
      .then(res => {
        if (res?.investment_account) {
          loadSignedW9();
          setLoadingW9(false);
          checkDocumentInterval = setInterval(loadSignedW9, 5000);
        }
      })
      .catch(error => handleErrorResponse(error));

    return () => {
      clearInterval(checkDocumentInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpload = file => {
    setUploadSuccess(false);
    dispatch(
      offlineW9Upload({
        data: {
          account_id: params.reference_id,
          type: 'w9_form'
        },
        attachment: file
      })
    ).then(res => {
      if (res.signing_url) setUploadSuccess(true);
    });
  };

  const onSubmit = values => {
    return dispatch(
      updateInvestmentAccount({
        investment_account: {
          id: investmentAccount.id,
          ...values.investment_account
        }
      })
    )
      .then(() => {
        if (
          isComplete(investmentAccount) &&
          !location.pathname.includes('/accounts/wizard')
        ) {
          history.push(
            `/accounts/update/${investmentAccount.account_type}/documents/${params.reference_id}`
          );
        } else {
          EmAnalytics.track('Submits W9 Document', 'Onboarding', {
            account_type: utils.startCase(investmentAccount.account_type)
          });
          if (investmentAccount.type === 'joint account')
            history.push(
              `/accounts/wizard/${investmentAccount.account_type}/poa/${params.reference_id}`
            );
          else
            history.push(
              `/accounts/wizard/${investmentAccount.account_type}/link_bank/${params.reference_id}`
            );
        }
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const handleTypeChange = type => {
    setW9Signed(false);
    setW9DownloadUrl(null);

    if (type === 'sign') {
      dispatch(
        generateW9Form(investmentAccount.user_id, investmentAccount.id)
      ).then(doc => {
        setW9SignUrl(doc.Investor);
      });

      setSignSelected(true);
      setUploadSelected(false);
    } else if (type === 'upload') {
      setSignSelected(false);
      setUploadSelected(true);
    }
  };

  let buttonDisabled = false;
  let completeAccount = false;

  if (investmentAccount) {
    completeAccount = investmentAccount && isComplete(investmentAccount);
    if (
      uploading ||
      submitting ||
      (!completeAccount && !w9Signed && !uploadSuccess)
    )
      buttonDisabled = true;
  }

  const isEntity = investmentAccount?.account_type === 'entity';

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {investmentAccount && (
          <>
            <h4 className="margin-top-0">
              We are required to collect a W-9 tax document for each Investment
              Account
            </h4>
            {!isEntity && (
              <>
                <h6>How do you file your taxes?</h6>
                <Field
                  name="investment_account.tax_filing_status"
                  id="tax_filing_status"
                  className="input-fixed-width"
                  component={ReduxFormSelectField}
                  label="Tax Filing Status"
                  options={taxFilingStatuses.map(status => ({
                    label: status.label,
                    value: status.value
                  }))}
                />
              </>
            )}

            <p className="margin-xx">
              To submit a W-9 form for this account, please sign electronically
              or upload a recent copy.
            </p>
            {w9Signed && w9DownloadUrl && (
              <div className="margin-xx">
                {loadingW9 ? (
                  <p>Loading W9...</p>
                ) : (
                  <>
                    <FileIcon
                      url="agreement.pdf"
                      className={sharedStyles.docIcon}
                    />
                    <a
                      href={w9DownloadUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={sharedStyles.docLink}
                    >
                      Current W9
                    </a>
                  </>
                )}
              </div>
            )}
            <div className="sign-upload-select">
              <h5>Submit W-9 form</h5>
              <div className="margin-xx radio-group">
                <RadioButton
                  name="w9-type"
                  id="sign"
                  onChange={() => {
                    handleTypeChange('sign');
                  }}
                  label="Sign Electronically"
                  checked={signSelected}
                  hideError
                />
                <RadioButton
                  name="w9-type"
                  id="upload"
                  onChange={() => {
                    handleTypeChange('upload');
                  }}
                  label="Upload"
                  checked={uploadSelected}
                />
              </div>
            </div>
            {uploadSelected && (
              <div>
                <div className="margin-xx">
                  <FileUploader
                    acceptedFileTypes={['PDF']}
                    maxSize={maxUploadSize}
                    uploading={uploading}
                    upload={handleUpload}
                    onRemove={() => {}}
                  />
                </div>
              </div>
            )}
            {signSelected && (
              <div>
                <div className="text-center">
                  {submitting ? (
                    <p>Loading W9...</p>
                  ) : (
                    <DocumentIframe src={w9SignUrl} title="Sign W9" />
                  )}
                </div>
              </div>
            )}
            {w9DownloadUrl && signSelected && (
              <Alert type="positive">W9 successfully submitted.</Alert>
            )}

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                disabled={buttonDisabled}
                loading={submitting}
                variant="orange"
                className="button-fixed-width"
              >
                {completeAccount ? 'Update' : 'Continue'}
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="W9 Document"
              />
            </div>
          </>
        )}
      </Form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  let investmentAccountInitialValues = {};
  if (state.account?.investmentAccount?.type !== 'entity')
    investmentAccountInitialValues = {
      tax_filing_status: state.account?.investmentAccount?.tax_filing_status
    };

  return {
    investmentAccount: state.account.investmentAccount,
    uploading: state.account.uploading,
    submitting: state.account.submitting,
    initialValues: {
      investment_account: investmentAccountInitialValues
    }
  };
}

interface ValidateValues {
  investment_account: {
    tax_filing_status?: string;
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: (values: ValidateValues) => {
      if (
        values.investment_account &&
        Object.prototype.hasOwnProperty.call(
          values.investment_account,
          'tax_filing_status'
        )
      )
        return validateSchema(w9Schema)(values);
      return {};
    }
  })(W9DocumentUpdate)
);
