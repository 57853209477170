import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormCheckboxField,
  ReduxFormInputField
} from 'components/ReduxFormFields';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logout, setAuthSubmitting } from 'redux/actions/auth';
import {
  getInviteeData,
  invitationInit,
  submitInviteSignupForm,
  submitSignupForm,
  updateSignupValues
} from 'redux/actions/onboarding';
import { change, Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import humane from 'utilities/humane';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { SignupValues } from '../types';
import { startSchema } from '../validation';

interface Props extends Partial<InjectedFormProps<SignupValues, Props>> {
  dispatch: Dispatch;
  invitationData?: {
    invited_by: string;
    signup_link_name: string;
  };
  invitee?: {
    name: string;
  };
  location: {
    pathname: string;
    search: string;
  };
  match: {
    params: {
      referral_token?: string;
      tab: string;
    };
  };
  submittingForm: boolean;
  user: User;
}

const SignUp = ({
  error,
  dispatch,
  handleSubmit,
  invitee,
  invitationData,
  location,
  match: { params },
  submittingForm,
  user
}: Props) => {
  const invitedByEmail = !!invitationData?.invited_by;
  const referralToken = params?.referral_token;

  useRecaptcha();

  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';
    const query = queryString.parse(location?.search);
    if (referralToken) {
      dispatch(getInviteeData(referralToken));
    }

    if (query) {
      if (
        query.invitation_token &&
        location.pathname === '/users/invitation/accept'
      )
        dispatch(invitationInit(query.invitation_token)).catch(err => {
          humane.error(err.body.message);
          history.push('/users/signup/start');
        });

      if (query.email) dispatch(change('signup', 'email', query.email));
    }
  }, [dispatch, location, referralToken]);

  useEffect(() => {
    if (user && referralToken) {
      dispatch(logout());
    }
  }, [dispatch, user, referralToken]);

  const onSubmit = async values => {
    dispatch(setAuthSubmitting());

    const query = queryString.parse(location.search);

    const irClickId = utils.getCookie('irclickid');
    if (irClickId) values.irclickid = irClickId;

    if (query.invitation_token)
      values.invitation_token = query.invitation_token;

    if (referralToken) values.signup_link_name = referralToken;

    const token = await getRecaptchaToken('signup');

    const submitValues = {
      step: 1,
      user: {
        ...values,
        captcha_response: token
      }
    };
    const inviteSubmitValues = {
      user: {
        accepted_tos_and_pp: values.accepted_tos_and_pp,
        irclickid: values.irclickid,
        invitation_token: values.invitation_token
      }
    };

    return dispatch(
      invitedByEmail
        ? submitInviteSignupForm(inviteSubmitValues)
        : submitSignupForm(submitValues)
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        history.push('/users/signup/password');
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  let heading = 'Start your free account in seconds.';
  let inviteeName = null;

  const isInvite = invitee || invitationData?.invited_by;

  if (isInvite) {
    if (invitedByEmail) {
      inviteeName = invitationData.invited_by;
    } else {
      inviteeName = invitee.name;
    }

    heading = `Sign Up, and earn a better return on your first investment
    courtesy of
    ${inviteeName}`;
  }

  return (
    <SignUpWrap
      introBackgroundColor="gray"
      introContent={
        <>
          <h1>
            Welcome to Modern Real Estate Investing.
            <sup className="size-12">TM</sup>
          </h1>
          <p>
            You're moments away from elevating your portfolio to new heights.
          </p>
        </>
      }
    >
      <h3>{heading}</h3>

      {isInvite ? (
        <>
          <p>
            Join {inviteeName} on EQUITYMULTIPLE, and build a diversified
            portfolio of pre-vetted, institutional-grade commercial real estate.
          </p>

          <p className="margin-xx">
            Because you are accepting their invite, we will give you a credit.
            On your first investment, this credit is automatically applied and
            you'll earn a yield bump. Invite others, and when they invest, you
            earn additional credits.
          </p>
        </>
      ) : (
        <p className="margin-xx">
          This email is how we'll communicate details about your account,
          announce new investment opportunities, share new investor resources,
          and more.
        </p>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          id="email"
          name="email"
          component={ReduxFormInputField}
          label="Email Address"
          className="margin-xx"
          disabled={invitedByEmail}
        />
        <Field
          type="checkbox"
          name="accepted_tos_and_pp"
          id="accepted_tos_and_pp"
          data-testid="acceptCheckbox"
          className="margin-xxx"
          component={ReduxFormCheckboxField}
          topAligned
          label={
            <span>
              I agree to the latest{' '}
              <a
                href="https://www.equitymultiple.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://www.equitymultiple.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Privacy Policy
              </a>
              , and I understand that the platform may contain information
              regarding hypothetical investment returns (including target
              returns) and that such performance should be viewed as
              hypothetical and not as an indication of future performance. There
              can be no assurance that such returns will be realized. I
              represent and acknowledge that I have (1) access to the resources
              to independently analyze the information that may be provided and
              (2) the financial expertise to understand that any hypothetical
              performance results provided have many inherent risks and
              limitations. Further, I understand that additional information
              with respect to such risks and limitations is available from EM
              Advisor, LLC upon request.
            </span>
          }
        />
        {error && <ErrorLabel message={error} className="margin-xxx" />}
        <Button
          type="submit"
          loading={submittingForm}
          variant="orange"
          className="button-fixed-width"
        >
          Continue
        </Button>
      </Form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const initialValues: SignupValues = store.auth.signupValues;
  // The email isn't submitted (it's deleted in onSubmit), just adding it to prefill the field and make validation easier
  if (store.auth?.invitationData?.invited_by) {
    initialValues.email = store.auth.invitationData.email;
  }

  return {
    submittingForm: store.auth.submitting,
    invitationData: store.auth.invitationData,
    initialValues,
    invitee: store.auth.invitee,
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'signup',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(startSchema)
  })(SignUp)
);
