import {
  Address,
  DocumentIdentification,
  Experience,
  Identity,
  Objectives,
  ProfileName,
  ProfileStart,
  Tolerance
} from 'containers/Profile';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import history from './browserHistory';
import {
  Activities,
  App,
  CancelInvestment,
  CapitalCall,
  ConfidentialityAgreement,
  DebtFundElections,
  DistributionReinvestmentElections,
  ForgotPassword,
  Fund,
  IndicateInterest,
  Invest,
  Invite,
  MyDocuments,
  NotFound,
  Offering,
  Portfolio,
  ResetPassword,
  Review,
  Setting,
  Sign,
  SignIn,
  STNElections,
  UnlockInstructions,
  UserTaxTracker,
  UserUpdateAlert,
  VerifyAccreditation
} from './containers';
import accountRoutes from './containers/Accounts/routes';
import {
  Account as PortfolioAccount,
  Investment as PortfolioInvestment
} from './containers/Portfolio/containers';
import {
  Accreditation,
  Continue,
  Discover,
  EmailVerify,
  Name,
  Password,
  Phone,
  Start
} from './containers/SignUp';
import {
  RequireAccreditedUser,
  RequireCompletedSignup,
  RequireLogin
} from './routerHelpers';

export default class AppRoutes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <App>
          <Switch>
            <Route path="/users/invitation/accept" component={Start} />
            <Route path="/users/signup/start" exact component={Start} />
            <Route path="/users/signup/password" exact component={Password} />
            <Route path="/users/signup/name" exact component={Name} />
            <Route
              path="/users/signup/accreditation"
              exact
              component={Accreditation}
            />
            {
              // Start sign up routes that require login
            }
            <Route path="/users/signup/continue" exact component={Continue} />
            <Route path="/users/signup/phone" exact component={Phone} />
            <Route path="/users/signup/discover" exact component={Discover} />
            <Route
              path="/users/signup/email_verify"
              exact
              component={EmailVerify}
            />
            {
              // End sign up routes that require login
            }
            <Route path="/users/signup/:referral_token" component={Start} />
            <Redirect
              from="/users/sign_up/:referral_token"
              to="/users/signup/:referral_token"
            />
            <Route
              path="/users/sign_up"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: location.pathname.replace(
                      '/sign_up',
                      '/signup/start'
                    )
                  }}
                />
              )}
            />
            <Route path="/users/sign_in" component={SignIn} />
            <Route path="/users/locked" component={UnlockInstructions} />
            <Route path="/users/password/new" component={ForgotPassword} />
            <Route path="/users/password/edit" component={ResetPassword} />
            <RequireLogin>
              <Switch>
                <Route path="/" exact component={Invest} />
                <Route path="/invest" exact>
                  <Redirect to="/" />
                </Route>
                <Route
                  exact
                  path="/invest/:offering_id/closings/:closing_id"
                  component={Offering}
                />
                <Route exact path="/invest/:offering_id" component={Offering} />
                <Route path="/users/invitation/new" component={Invite} />
                <Route
                  path="/users/update_account"
                  component={UserUpdateAlert}
                />
                {
                  // Start profile routes
                }
                <Route
                  path="/users/profile/start"
                  exact
                  component={ProfileStart}
                />
                <Route
                  path="/users/profile/experience"
                  exact
                  component={Experience}
                />
                <Route
                  path="/users/profile/objectives"
                  exact
                  component={Objectives}
                />
                <Route
                  path="/users/profile/tolerance"
                  exact
                  component={Tolerance}
                />
                <Route
                  path="/users/profile/name"
                  exact
                  component={ProfileName}
                />
                <Route path="/users/profile/kyc" exact component={Identity} />
                <Route
                  path="/users/profile/address"
                  exact
                  component={Address}
                />
                <Route
                  path="/users/profile/document_identification"
                  exact
                  component={DocumentIdentification}
                />
                {
                  // End profile routes
                }
                <RequireCompletedSignup>
                  <Switch>
                    <Route path="/activity" component={Activities} />
                    <Route path="/portfolio" exact component={Portfolio} />
                    <Route
                      path="/portfolio/account/:account_id"
                      exact
                      component={PortfolioAccount}
                    />
                    <Route
                      path="/portfolio/account/:account_id/investment/:investment_id"
                      component={PortfolioInvestment}
                    />
                    <Route
                      path="/investments/:offering_id/:closing_id/cashflow/:investment_id"
                      exact
                    >
                      <Redirect to="/investments/payment_activity/:investment_id" />
                    </Route>
                    <Route
                      path="/invest/:closing_id/investment/new"
                      component={IndicateInterest}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/interest"
                      component={IndicateInterest}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/sign"
                      component={Sign}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/fund"
                      component={Fund}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/capital_call/:transaction_id/fund"
                      component={Fund}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/confirm"
                      component={Review}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/edit"
                      component={IndicateInterest}
                    />
                    <Route
                      path="/offerings/:offering_id/elections"
                      component={DebtFundElections}
                      exact
                    />
                    <Route
                      path="/offerings/:offering_id/elections/:investment_id"
                      component={STNElections}
                    />
                    <Route
                      path="/offerings/:offering_id/distribution_reinvestment"
                      component={DistributionReinvestmentElections}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/cancel"
                      component={CancelInvestment}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/capital_call/interest"
                      component={CapitalCall}
                    />
                    <Route
                      path="/invest/:closing_id/investment/:investment_id/capital_call/allocation"
                      component={CapitalCall}
                    />
                    <Route
                      path="/users/offerings/:offering_id/closings/:closing_id/confidentiality"
                      component={ConfidentialityAgreement}
                    />
                    <Route
                      path="/verify_accreditation/:reference_id"
                      component={VerifyAccreditation}
                    />
                    <Route path="/accounts">
                      <RequireAccreditedUser>
                        {accountRoutes}
                      </RequireAccreditedUser>
                    </Route>
                    <Route path="/tax_tracker" component={UserTaxTracker} />
                    <Route path="/settings/:tab?" component={Setting} />
                    <Route path="/documents" component={MyDocuments} />
                    <Route component={NotFound} />
                  </Switch>
                </RequireCompletedSignup>
                <Route path="/invest/new" exact component={NotFound} />
                <Route path="/invest/:id*" exact />
                <Route component={NotFound} />
              </Switch>
            </RequireLogin>
          </Switch>
        </App>
      </Router>
    );
  }
}
