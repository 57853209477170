import { Alert } from '@equitymultiple/react-eui';
import { ReduxFormCheckboxField } from 'components/ReduxFormFields';
import alpineNoteIcon from 'images/icons/alpine-note.png';
import InfoIcon from 'images/icons/info.svg';
import React from 'react';
import { Field } from 'redux-form';
import { FundingOptions, Investment } from 'types/api/investment';
import utils from 'utilities/utils';

import * as styles from './AlpineNoteBalance.module.scss';

interface Props {
  fundingOptions: FundingOptions;
  investment: Investment;
  minAvailableBalance: number;
  selectedAlpineNotes: Record<number, boolean>;
  selectedBalance: number;
}

const AlpineNoteBalance = ({
  fundingOptions,
  investment,
  selectedBalance,
  minAvailableBalance,
  selectedAlpineNotes
}: Props) => {
  const totalAvailableBalance = fundingOptions.alpine_note_balance.reduce(
    (partialSum, note) =>
      partialSum + (note.redeemed ? 0 : parseInt(note.balance)),
    0
  );

  const minimumAmountToUseBalance =
    parseInt(investment.amount) -
    parseInt(investment.unfunded_amount) +
    minAvailableBalance;

  const renderInlineMessage = () => {
    if (minAvailableBalance <= parseInt(investment.unfunded_amount)) {
      return (
        <Alert type="neutral">
          You have funds available on your Alpine Note Principal Balance. You
          can use it as a source for this investment. If you don&apos;t want to
          do so, click "Skip".
        </Alert>
      );
    }
    return (
      <Alert type="warning">
        Your Alpine Note Principal Balance is higher than your intended
        investment amount. Please update the investment to at least{' '}
        {utils.formatCurrency(minimumAmountToUseBalance)} to use this funding
        source. If not, click "Skip".
      </Alert>
    );
  };

  const renderAvailableAlpineBalance = () => {
    const alpineNoteBalances = fundingOptions.alpine_note_balance;
    const remainingAmount =
      parseInt(investment.unfunded_amount) - selectedBalance;

    return alpineNoteBalances
      ? alpineNoteBalances.map(alpineNoteBalance => {
          const alpineBalanceIsHigher =
            remainingAmount < parseInt(alpineNoteBalance.balance) ||
            parseInt(investment.unfunded_amount) <
              parseInt(alpineNoteBalance.balance);

          const shouldBeDisabled =
            alpineNoteBalance.redeemed ||
            (!selectedAlpineNotes[alpineNoteBalance.id] &&
              alpineBalanceIsHigher);

          return (
            <div
              key={alpineNoteBalance.id}
              className={`${styles.checkboxWrapper} ${shouldBeDisabled && styles.checkboxDisabled} ${alpineNoteBalance.redeemed && styles.checked}`}
            >
              <Field
                id={`alpine_note_balance_${alpineNoteBalance.id}`}
                name={`alpine_note_balance[${alpineNoteBalance.id}]`}
                type="checkbox"
                component={ReduxFormCheckboxField}
                checked={alpineNoteBalance.redeemed}
                disabled={shouldBeDisabled}
                label={alpineNoteBalance.title}
                topAligned
              />
              <label
                htmlFor={`alpine_note_balance_${alpineNoteBalance.id}`}
                className={styles.secondaryText}
              >
                <div>
                  Maturity Date:{' '}
                  {utils.dateFormat(alpineNoteBalance.matures_on) || 'N/A'}
                </div>
                <div>{utils.formatCurrency(alpineNoteBalance.balance)}</div>
              </label>
            </div>
          );
        })
      : null;
  };

  return (
    <div>
      {minAvailableBalance > 0 && renderInlineMessage()}
      <div className={styles.wrapper}>
        <div className={styles.alpineBalanceIconWrapper}>
          <img src={alpineNoteIcon} alt="Alpine Note Balance" />
        </div>
        <div className={styles.container}>
          Alpine Note Principal Balance
          <div className={styles.secondaryText}>Instant</div>
          <div className={styles.dividerSmall} />
          <div className="margin-xx">
            Total Balance: {utils.formatCurrency(totalAvailableBalance)}
          </div>
          <div className={`margin-top-x ${styles.infoText}`}>
            {selectedBalance === totalAvailableBalance && (
              <>
                <InfoIcon height="16px" />
                You are going to use the total amount of your Alpine Note
                investments.
              </>
            )}
            {selectedBalance < totalAvailableBalance &&
              fundingOptions.alpine_note_balance.length > 1 && (
                <>
                  <InfoIcon height="16px" />
                  You currently have funds allocated to{' '}
                  {fundingOptions.alpine_note_balance.length} Alpine Note
                  offerings. Please choose which one you would like to utilize,
                  ensuring that the selected amount does not exceed your initial
                  investment.
                </>
              )}
          </div>
          <div className={`margin-row ${styles.checkboxContainer}`}>
            {renderAvailableAlpineBalance()}
          </div>
          <div className={styles.infoText}>
            {selectedBalance > 0 &&
              selectedBalance < parseInt(investment.unfunded_amount) && (
                <>
                  <InfoIcon height="16px" />
                  Insufficient amount. Click "Next" to proceed with partial
                  payment using another payment method.
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlpineNoteBalance;
