import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound/NotFound';
import {
  AccountDocuments,
  Accounts,
  AccountStatus,
  BankAccounts,
  BeneficialEdit,
  BeneficialNew,
  BeneficialOwners,
  Entity,
  InvestmentAccount,
  IraUpdate,
  JointSecondaryHolder,
  JointSecondaryHolderIdentification,
  NewAccount,
  OrganizationalDocument,
  PlaidLinkOAuth,
  PoaDocumentUpdate,
  TaxDocument,
  W9DocumentUpdate
} from '.';

const accountRoutes = (
  <Switch>
    <Route path="/accounts" exact component={Accounts} />
    <Route path="/accounts/new" component={NewAccount} />
    <Route path="/accounts/:type/affordances" component={NewAccount} />
    {/* Routes for creating new Investment Account */}
    <Route
      path="/accounts/wizard/ira/:reference_id"
      exact
      component={IraUpdate}
    />
    <Route path="/accounts/wizard/:account_type/new" component={NewAccount} />
    <Route
      path="/accounts/wizard/:account_type/:reference_id"
      exact
      component={InvestmentAccount}
    />
    <Route
      path="/accounts/wizard/entity/information/:reference_id"
      exact
      component={Entity}
    />
    <Route
      path="/accounts/wizard/joint/secondary_account/:reference_id"
      exact
      component={JointSecondaryHolder}
    />
    <Route
      path="/accounts/wizard/joint/secondary_account/identification_document/:reference_id"
      component={JointSecondaryHolderIdentification}
    />
    <Route
      path="/accounts/wizard/:account_type/organizational_document/:reference_id"
      component={OrganizationalDocument}
    />
    <Route
      path="/accounts/wizard/:account_type/tax_document/:reference_id"
      component={TaxDocument}
    />
    <Route
      path="/accounts/wizard/:account_type/w9/:reference_id"
      component={W9DocumentUpdate}
    />
    <Route
      path="/accounts/wizard/:account_type/poa/:reference_id"
      component={PoaDocumentUpdate}
    />
    <Route
      path="/accounts/wizard/:account_type/thanks/:reference_id"
      component={AccountStatus}
    />
    <Route
      path="/accounts/wizard/:account_type/link_bank/:reference_id"
      exact
      component={BankAccounts}
    />
    <Route
      path="/accounts/wizard/:account_type/beneficial_owners/:reference_id"
      exact
      component={BeneficialOwners}
    />
    <Route
      path="/accounts/wizard/:account_type/beneficial_owners/new/:reference_id"
      component={BeneficialNew}
    />
    <Route
      path="/accounts/wizard/:account_type/beneficial_owners/:id/:reference_id"
      component={BeneficialEdit}
    />
    <Route
      path="/accounts/update/:account_type/:reference_id"
      exact
      component={InvestmentAccount}
    />
    <Route
      path="/accounts/update/entity/information/:reference_id"
      exact
      component={Entity}
    />
    <Route
      path="/accounts/update/entity/rep/new/:reference_id"
      component={BeneficialNew}
    />
    <Route
      path="/accounts/update/entity/rep/:id/:reference_id"
      component={BeneficialEdit}
    />
    <Route
      path="/accounts/update/joint/secondary_account/:reference_id"
      exact
      component={JointSecondaryHolder}
    />
    <Route
      path="/accounts/update/joint/secondary_account/identification_document/:reference_id"
      component={JointSecondaryHolderIdentification}
    />
    <Route
      path="/accounts/update/:account_type/linkedbanks/:reference_id"
      exact
      component={BankAccounts}
    />
    <Route
      path="/accounts/update/:account_type/documents/:reference_id"
      exact
      component={AccountDocuments}
    />
    <Route
      path="/accounts/update/:account_type/documents/organizational_document/:reference_id"
      component={OrganizationalDocument}
    />
    <Route
      path="/accounts/update/:account_type/documents/tax_document/:reference_id"
      component={TaxDocument}
    />
    <Route
      path="/accounts/update/:account_type/documents/poa/:reference_id"
      exact
      component={PoaDocumentUpdate}
    />
    <Route
      path="/accounts/update/:account_type/documents/poa/update/:reference_id"
      component={PoaDocumentUpdate}
    />
    <Route
      path="/accounts/update/:account_type/documents/w9/:reference_id"
      exact
      component={W9DocumentUpdate}
    />
    <Route
      path="/accounts/update/:account_type/documents/w9/update/:reference_id"
      component={W9DocumentUpdate}
    />
    <Route path="/accounts/bank-linking/oauth" component={PlaidLinkOAuth} />
    <Route component={NotFound} />
  </Switch>
);

export default accountRoutes;
