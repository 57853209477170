import { Button, EMLoadingIcon } from '@equitymultiple/react-eui';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import done from 'images/done.svg?url';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { loadInvestmentAccount } from 'redux/actions/account';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { Dispatch } from 'types/redux';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import AddressForm from '../AddressForm';
import { entityAddressVerificationSchema } from '../validation';
import * as styles from './EntityAddressVerificationForm.module.scss';

interface CustomProps {
  accountSelectedForUpdate?: number;
  dispatch: Dispatch;
  entityName?: string;
  handleSubmit?: () => void;
  loading?: boolean;
  noteUserInterestInUpdatingEntityName?: (accountId: number) => void;
  previousWizard?: () => void;
  userElectedToUpdateInvestmentAccountNamePreviously?: boolean;
}

type Props = InjectedFormProps<object, CustomProps> & CustomProps;

const EntityAddressVerificationForm = ({
  dispatch,
  previousWizard,
  accountSelectedForUpdate,
  entityName,
  loading,
  handleSubmit,
  noteUserInterestInUpdatingEntityName,
  userElectedToUpdateInvestmentAccountNamePreviously
}: Props) => {
  const [hoveringField, setHoveringField] = useState(false);

  useEffect(() => {
    dispatch(loadInvestmentAccount(accountSelectedForUpdate));
  }, [dispatch, accountSelectedForUpdate]);

  return !accountSelectedForUpdate && !entityName && loading ? (
    <EMLoadingIcon />
  ) : (
    <>
      <div>
        <h4 className="margin-x">{entityName} Contact Information</h4>
        <div className="margin-xx size-16">
          To comply with securities regulations and to prepare your tax
          documents, we are required to gather up-to-date information on your{' '}
          {entityName} Investment Account.
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <h6 className="margin-xx">Update:</h6>
        <Row className="margin-row">
          <Col md={6}>
            <Field
              name="investment_account.entity_name"
              component={ReduxFormInputField}
              label="Entity Name"
              disabled
              helperText={
                userElectedToUpdateInvestmentAccountNamePreviously ? (
                  <div className={styles.updatedText}>
                    <img src={done} alt="Success" />
                    Request Sent
                  </div>
                ) : (
                  <button
                    onMouseEnter={() => setHoveringField(true)}
                    onMouseLeave={() => setHoveringField(false)}
                    className="text-link"
                    onClick={() => {
                      noteUserInterestInUpdatingEntityName(
                        accountSelectedForUpdate
                      );
                    }}
                    type="button"
                  >
                    Request Change
                  </button>
                )
              }
              helperTextAlwaysVisible
            />
            {!userElectedToUpdateInvestmentAccountNamePreviously && (
              <Button
                className={styles.requestChangeMobile}
                onClick={() => {
                  noteUserInterestInUpdatingEntityName(
                    accountSelectedForUpdate
                  );
                }}
              >
                Request Change
              </Button>
            )}
          </Col>
          <Col md={6}>
            {(userElectedToUpdateInvestmentAccountNamePreviously ||
              hoveringField) && (
              <div className={styles.updatedDetails}>
                Your request will be managed by a member from our Investor
                Relations team. They will be in touch with you via email.
              </div>
            )}
          </Col>
        </Row>
        <AddressForm
          previousWizard={previousWizard}
          formName="investment_account"
        />
      </Form>
    </>
  );
};

const selector = formValueSelector('investment_account');

function mapStateToProps(state, ownProps) {
  const entityName = selector(state, 'investment_account.entity_name');
  const { previouslyEnteredAccountDetails } = ownProps;
  const accountDetails =
    previouslyEnteredAccountDetails || state.account.investmentAccount;

  return {
    entityName,
    loading: state.account.loading,
    initialValues: {
      investment_account: {
        ...accountDetails
      }
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    validate: validateSchema(entityAddressVerificationSchema),
    onSubmitFail: scrollToError
  })(EntityAddressVerificationForm)
);
