import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import {
  ReduxFormDateSelectField,
  ReduxFormFileUploaderField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import {
  updatePersonalIdentification,
  updateUserProfile
} from 'redux/actions/user-settings';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { maxUploadSize } from 'utilities/constants';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { identificationDocumentOptions } from '../content';
import { getUserAttributes } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const DocumentIdentification = ({
  dispatch,
  error,
  handleSubmit,
  idType,
  initialValues,
  submitting
}: SharedProfileValues) => {
  const [hasNewDoc, setHasNewDoc] = useState(false);

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const handleRemove = () => {
    setHasNewDoc(true);
    dispatch(change('documentIdentification', 'id_document.attachment', null));
  };

  const handleUpload = file => {
    setHasNewDoc(true);
    dispatch(change('documentIdentification', 'id_document.attachment', file));
  };

  const onSubmit = values => {
    if (!(values.id_document.attachment instanceof File))
      delete values.id_document.attachment;

    const attachmentValues = {
      user: {
        id_document: values.id_document.attachment,
        id_expiration: values.id_document.expiry,
        id_type: values.id_document.type
      }
    };

    if (values.id_document.passport) {
      const submitValues = {
        investor_profile_attributes: {
          passport: values.id_document.passport
        }
      };
      dispatch(updateUserProfile(submitValues));
    }

    return dispatch(updatePersonalIdentification(attachmentValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          history.push('/accounts/new');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Identification</h3>
        <p className="margin-xx">
          Upload documents to confirm your identity securely. Documents are used
          solely for verification and kept confidential.
        </p>
        <Field
          id="attachment"
          name="id_document.attachment"
          component={ReduxFormFileUploaderField}
          acceptedFileTypes={['JPG', 'PNG']}
          existingFile={!hasNewDoc && initialValues.id_document?.attachment}
          onRemove={handleRemove}
          upload={handleUpload}
          uploading={submitting}
          showImage
          maxSize={maxUploadSize}
        />
        <Field
          id="type"
          component={ReduxFormSelectField}
          name="id_document.type"
          label="Document Type"
          options={identificationDocumentOptions}
        />
        {idType === 'passport' && (
          <Field
            id="passport"
            component={ReduxFormInputField}
            name="id_document.passport"
            label="Passport Number"
          />
        )}
        <Field
          id="expiry"
          component={ReduxFormDateSelectField}
          name="id_document.expiry"
          label="ID Expiration Date"
          futureDate
        />

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/address" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

const selector = formValueSelector('documentIdentification');

function mapStateToProps(store) {
  const idType = selector(store, 'id_document.type');

  return {
    idType,
    initialValues: getUserAttributes(store.auth.user),
    submitting:
      store.userSettings.updatingUserProfile ||
      store.userSettings.updatingPersonalIdentification
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'documentIdentification',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(DocumentIdentification)
);
