import history from '../browserHistory';
import humane from './humane';

interface Error {
  body: {
    errors?: Record<string, string[]>;
    message?: string;
  };
  status_code: number;
}

export const handleErrorResponse = (
  error: Error,
  redirectPath?: string,
  message?: string
) => {
  const errorMessage =
    message || error?.body?.message || 'Something went wrong';
  const redirect = redirectPath || '/';

  humane.error(errorMessage);
  history.push(redirect);
};
