import { Button, Checkbox, ErrorLabel, Input } from '@equitymultiple/react-eui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';

import { numberMaskOptions } from '../../../../utilities/masks';
import utils from '../../../../utilities/utils';

export default class CapitalCallAllocation extends Component {
  static propTypes = {
    investment: PropTypes.shape({
      amount: PropTypes.string
    }),
    maxAmount: PropTypes.number,
    offering: PropTypes.shape({}),
    onMount: PropTypes.func,
    onSubmit: PropTypes.func,
    sending: PropTypes.bool,
    transaction: PropTypes.shape({
      amount: PropTypes.string
    })
  };

  state = {
    acceptedDisclaimer: false,
    additionalAllocation: null,
    errorAmount: null,
    errorDisclaimer: false
  };

  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  handleSubmit = () => {
    const { acceptedDisclaimer, additionalAllocation, errorAmount } =
      this.state;
    const { onSubmit } = this.props;
    if (!errorAmount) {
      const allocationParsed = additionalAllocation
        ? parseFloat(additionalAllocation)
        : 0;
      this.setState({ errorDisclaimer: false }, () => {
        if (allocationParsed > 0 && !acceptedDisclaimer)
          this.setState({ errorDisclaimer: true });
        else onSubmit(allocationParsed);
      });
    }
  };

  handleAllocationChange = value => {
    const { maxAmount } = this.props;
    const val = value ? value.replace(/[^0-9.]/g, '') : 0;
    this.setState({ additionalAllocation: val }, () => {
      if (parseFloat(val) > maxAmount) {
        this.setState({
          errorAmount:
            'The maximum additional allocation is ' +
            utils.formatCurrency(maxAmount)
        });
      } else {
        this.setState({ errorAmount: null });
      }
    });
  };

  render() {
    const { investment, sending, transaction, maxAmount } = this.props;
    const {
      acceptedDisclaimer,
      additionalAllocation,
      errorAmount,
      errorDisclaimer
    } = this.state;
    const ccAmount = parseFloat(transaction.amount);
    const newAmount = parseFloat(investment.amount) + parseFloat(ccAmount);
    const projectedAmount =
      parseFloat(newAmount) + parseFloat(additionalAllocation);

    return (
      <div id="capital-call-allocation">
        <Row className="box-row">
          <Col xs={4} className="border-box value-box">
            <div className="text-label">Original Investment</div>
            <div className="amount">
              {' '}
              {utils.formatCurrency(investment.amount)}{' '}
            </div>
          </Col>
          <Col xs={4} className="border-box value-box">
            <div className="text-label">Capital Call Amount</div>
            <div className="amount"> {utils.formatCurrency(ccAmount)} </div>
          </Col>
          <Col xs={4} className="border-box value-box">
            <div className="text-label text-blue">
              New <br className="mobile-br" />
              Investment
            </div>
            <div className="amount text-blue">
              {' '}
              {utils.formatCurrency(newAmount)}{' '}
            </div>
          </Col>
        </Row>
        <div id="info" className="border-box">
          <div className="text-blue-light">
            Capital Call: Additional Allocation
          </div>
          <h2>Do you want to further increase your ownership investment?</h2>
          <p>
            On occasion, there is an unclaimed allocation in a capital call. If
            this is the case, you may have the opportunity to increase your
            percentage ownership in the investment by investing more than your
            pro rata share of the capital call. Please note that we cannot
            guarantee the availability of additional allocations, nor can we
            always meet the desired increase in investment. Availability will be
            determined based on the final participation rate of the investor
            group.
          </p>
        </div>
        <div id="increase-allocation" className="border-box">
          <h3>By how much more?</h3>
          <p className="margin-xx">
            If such additional allocation becomes available, I am interested in
            investing the following amount, in addition to my pro rata
            allocation.
          </p>
          <Row>
            <Col md={4} className="col-new-amount">
              <Input
                label="New Investment Amount"
                value={utils.formatCurrency(newAmount)}
                readOnly
              />
            </Col>
            <Col md={4}>
              <Input
                label="Additional Allocation"
                id="additional-allocation"
                value={additionalAllocation}
                onChange={e => this.handleAllocationChange(e.target.value)}
                dollarMask
                inputMaskOptions={numberMaskOptions}
                maxLength={10}
                style={{
                  marginBottom: 10
                }}
                allowDecimal
              />
              <button
                className="text-link"
                id="additional-allocation-btn"
                onClick={() => {
                  if (maxAmount)
                    this.setState({ additionalAllocation: maxAmount });
                  return true;
                }}
                style={{
                  display: errorAmount ? 'none' : 'block',
                  float: 'right',
                  marginBottom: '20px'
                }}
                type="button"
              >
                {maxAmount && utils.formatCurrency(maxAmount)} max
              </button>
              {errorAmount && (
                <ErrorLabel
                  message={errorAmount}
                  style={{ marginTop: '15px' }}
                />
              )}
            </Col>
            <Col md={4}>
              {additionalAllocation > 0 && (
                <Input
                  label="Projected Investment Amount"
                  value={utils.formatCurrency(projectedAmount)}
                  readOnly
                />
              )}
            </Col>
          </Row>
        </div>
        <div className="border-box submit-box">
          {additionalAllocation > 0 && (
            <Checkbox
              id="disclaimer"
              name="disclaimer"
              className="disclaimerCheckbox"
              onChange={() =>
                this.setState({ acceptedDisclaimer: !acceptedDisclaimer })
              }
              checked={acceptedDisclaimer}
              label="I understand that this is an indication of interest for additional allocation only."
            />
          )}

          {errorDisclaimer && (
            <ErrorLabel message="You must confirm that you stated this as an additional allocation." />
          )}
          <Button
            styleClassName="blue"
            className="submitButton"
            disabled={
              (!acceptedDisclaimer && additionalAllocation > 0) || errorAmount
            }
            loading={sending}
            onClick={this.handleSubmit}
          >
            {additionalAllocation > 0 ? 'Indicate Interest' : 'Not Interested'}
          </Button>
        </div>
      </div>
    );
  }
}
