import {
  Checkbox,
  DateSelect,
  FileUploader,
  Input,
  Password,
  RadioButton,
  Select
} from '@equitymultiple/react-eui';
import { Props as CheckboxProps } from '@equitymultiple/react-eui/dist/components/Checkbox/Checkbox';
import { Props as DateSelectProps } from '@equitymultiple/react-eui/dist/components/DateSelect/DateSelect';
import { Props as FileUploaderProps } from '@equitymultiple/react-eui/dist/components/FileUploader/FileUploader';
import { Props as InputProps } from '@equitymultiple/react-eui/dist/components/Input/Input';
import { Props as PasswordProps } from '@equitymultiple/react-eui/dist/components/Password/Password';
import { Props as RadioButtonProps } from '@equitymultiple/react-eui/dist/components/RadioButton/RadioButton';
import { Props as SelectProps } from '@equitymultiple/react-eui/dist/components/Select/Select';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { Captcha } from './formControls';
import { Props as CaptchaProps } from './formControls/Captcha/Captcha';

// Used as the "component" prop of a redux-form Field component
// Takes field props and modifies them for usage with our components

type WrappedField<Props> = WrappedFieldProps & Props;

export const ReduxFormCheckboxField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<CheckboxProps>) => (
  <Checkbox
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormDateSelectField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<DateSelectProps>) => (
  <DateSelect
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormFileUploaderField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<FileUploaderProps>) => (
  <FileUploader
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormInputField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<InputProps>) => (
  <Input
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormPasswordField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<PasswordProps | { input }>) => (
  <Password
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormRadioField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<RadioButtonProps>) => (
  <RadioButton
    {...fieldProps}
    input={input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormSelectField = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<SelectProps>) => (
  <Select
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
  />
);

export const ReduxFormCaptcha = ({
  input,
  meta,
  ...fieldProps
}: WrappedField<CaptchaProps>) => (
  <Captcha
    {...fieldProps}
    {...input}
    errorMessage={meta.touched ? meta.error : ''}
    valueReset={meta.touched && input.value === ''}
  />
);
