// Validation helper functions
import { FormErrors, SubmissionError } from 'redux-form';

export const scrollToError = () => {
  setTimeout(() => {
    const errorDiv = document.querySelector('.fieldWithErrors + div');
    if (errorDiv) {
      const errorTop =
        errorDiv.getBoundingClientRect().top + window.scrollY - 150;
      if (window.scrollY > errorTop) {
        window.scrollTo({
          top: errorTop,
          behavior: 'smooth'
        });
      }
    }
  }, 0);
};

export const throwSubmissionErrors = response => {
  const errors = response?.body?.errors;
  const errorMessage = response?.body?.message;
  let formErrors: FormErrors = {};

  if (errors && Object.keys(errors).length > 0) formErrors = errors;
  // Don't populate _error if there are other errors, since the value is most likely just a redundant "Validation errors" message
  else if (errorMessage) formErrors._error = errorMessage;

  throw new SubmissionError(formErrors);
};

export const betweenMinMax = (minValue, maxValue) => (value, previousValue) =>
  value >= minValue && value <= maxValue ? value : previousValue;
