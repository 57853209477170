import history from 'browserHistory';
import { getFirstIncompleteStepRoute } from 'containers/Accounts/helpers';
import { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { User } from 'types/api/user';
import { hasCompletedSignup } from 'utilities/user';

interface Props {
  children: ReactElement;
  user: User;
}

const RequireCompletedSignup = ({ children, user }: Props) => {
  useEffect(() => {
    if (!hasCompletedSignup(user)) {
      const nextSignUpRoute = getFirstIncompleteStepRoute(user);
      history.replace(nextSignUpRoute);
    }
  }, [user]);

  return children;
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(RequireCompletedSignup);
