import { Button } from '@equitymultiple/react-eui';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import { Field, Form, SubmitHandler } from 'redux-form';

import {
  ReduxFormRadioField,
  ReduxFormSelectField
} from '../../../../components/ReduxFormFields';
import { dateRangeOptions } from '../../constants';
import * as styles from './PaymentsTableFilters.module.scss';

const getDateTypeLabel = dateType =>
  dateType === 'date_posted' ? 'Transfer Date' : 'Effective Date';

const getFilterLabels = (activeFilters, investmentOptions) => {
  const { investmentId, dateRange, dateType } = activeFilters;

  const filterLabels = [];

  if (activeFilters) {
    if (investmentOptions && investmentId !== 'all') {
      const selectedInvestment = investmentOptions.find(
        option => option.value === investmentId
      );
      if (selectedInvestment) filterLabels.push(selectedInvestment.label);
    }

    if (dateRange && dateRange !== 'all') {
      filterLabels.push(getDateTypeLabel(dateType));
      const selectedDateOption = dateRangeOptions.find(
        option => option.value === dateRange
      );
      if (selectedDateOption) filterLabels.push(selectedDateOption.label);
    }
  }

  return filterLabels.length > 0 ? filterLabels : null;
};

interface Props {
  activeFilters: {
    dateRange: string;
    investmentId: number;
  };
  clearFilter: () => void;
  currentValues: {
    dateRange: string;
    dateType: 'effective_date' | 'date_posted';
    investmentId: number | string;
  };
  filterFieldsOpen: boolean;
  handleSubmit: SubmitHandler<object, any, any>; // eslint-disable-line
  investmentOptions: {
    label: string;
    value: string;
  }[];
  onSubmit: () => void;
  toggleFilterFields: () => void;
}

const PaymentsTableFilters = ({
  activeFilters,
  clearFilter,
  currentValues,
  handleSubmit,
  onSubmit,
  investmentOptions,
  filterFieldsOpen,
  toggleFilterFields
}: Props) => {
  const filterLabels = getFilterLabels(activeFilters, investmentOptions);

  return (
    <>
      <Collapse in={filterFieldsOpen} timeout="auto" unmountOnExit>
        <p>Filter by payment details:</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {investmentOptions && (
            <>
              <Field
                name="investmentId"
                id="payments-investment"
                className="input-fixed-width"
                component={ReduxFormSelectField}
                label="Investment"
                options={investmentOptions}
              />
              <p>and/or:</p>
            </>
          )}
          <div className="margin-x">
            <Field
              type="radio"
              name="dateType"
              id="payments-transfer-date"
              value="date_posted"
              component={ReduxFormRadioField}
              label={
                <>
                  Transfer Date
                  <div className="subtext">
                    Filter transactions by the date they were transferred.
                    Recommended for seeing activity on a cash basis.
                  </div>
                </>
              }
            />
          </div>
          <div className="margin-xx">
            <Field
              type="radio"
              name="dateType"
              id="payments-effective-date"
              value="effective_date"
              component={ReduxFormRadioField}
              label={
                <>
                  Effective Date
                  <div className="subtext">
                    Filter transactions by the date they are effective.
                    Recommended for performance calculations.
                  </div>
                </>
              }
            />
          </div>

          <Field
            name="dateRange"
            id="payments-date-range"
            className="input-fixed-width"
            component={ReduxFormSelectField}
            label={getDateTypeLabel(currentValues.dateType)}
            options={dateRangeOptions}
          />

          <div className="button-wrap margin-top-xx margin-xx">
            <Button
              variant="ghost"
              onClick={toggleFilterFields}
              className={styles.filterButton}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              className={styles.filterButton}
              type="submit"
              title="Filter Payment Activity"
            >
              Filter
            </Button>
          </div>
        </Form>
      </Collapse>
      {filterLabels && (
        <div className={styles.filterActive}>
          <div>
            <div className={styles.filteredBy}>Filtered by:</div>
            {filterLabels.map(label => (
              <div className={styles.filterLabel} key={label}>
                {label}
              </div>
            ))}
          </div>
          <div className={styles.actions}>
            <button
              className="text-link underline"
              type="button"
              onClick={toggleFilterFields}
            >
              Edit
            </button>
            <div className={styles.separator}>|</div>
            <button
              className="text-link underline"
              type="button"
              onClick={clearFilter}
            >
              Clear Filter
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentsTableFilters;
