import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import IrImages from 'components/IrImages/IrImages';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { submitSignupForm, updateSignupValues } from 'redux/actions/onboarding';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import { SharedSignupProps } from '../types';
import { nameSchema } from '../validation';
import * as styles from './Name.module.scss';

const Name = ({
  dispatch,
  error,
  handleSubmit,
  loadingAuth,
  submitting,
  initialValues,
  user
}: SharedSignupProps) => {
  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    if (!loadingAuth) redirectIfPreviousStepsIncomplete(initialValues, 3, user);
  }, [loadingAuth, initialValues, user]);

  const onSubmit = values => {
    return dispatch(
      user
        ? updateUserProfile(values)
        : submitSignupForm({
            step: 3,
            user: {
              ...values,
              email: initialValues.email
            }
          })
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        history.push('/users/signup/accreditation');
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <SignUpWrap
      introBackgroundColor="bark"
      introContent={
        <>
          <h1>Precision Investing, Personalized Support.</h1>
          <p>
            You'll also have a team of experienced asset managers working to
            maximize your returns on each investment and full access to our
            Investor Relations team, any time, anywhere.
          </p>
          <IrImages className={styles.irImages} />
        </>
      }
      step={3}
      user={user}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Tell us your name</h3>
        <Field
          name="first_name"
          id="first_name"
          component={ReduxFormInputField}
          label="First Name"
        />
        <Field
          name="last_name"
          id="last_name"
          component={ReduxFormInputField}
          label="Last Name"
        />
        {error && <ErrorLabel message={error} className="margin-xxx" />}
        <Button
          type="submit"
          loading={submitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
      </Form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const signupValues = store.auth.signupValues;
  const user = store.auth.user;
  let initialValues = signupValues;
  if (!store.auth.loading && user) {
    initialValues = {
      email: user.email,
      first_name: user.first_name || signupValues.first_name,
      last_name: user.last_name || signupValues.last_name
    };
  }

  return {
    initialValues,
    loadingAuth: store.auth.loading,
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'name',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(nameSchema)
  })(Name)
);
