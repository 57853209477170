import { Button, Card, ErrorLabel } from '@equitymultiple/react-eui';
import { ButtonTypes } from '@equitymultiple/react-eui/dist/components/Button/Button';
import history from 'browserHistory';
import LoadingForwardBackButtons from 'components/LoadingForwardBackButtons/LoadingForwardBackButtons';
import ProgressBarBorder from 'components/ProgressBarBorder/ProgressBarBorder';
import useRedirectUserWIthInterestAscentInvestment from 'hooks/useRedirectUserWIthInterestAscentInvestment';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  getUserCapitalCallsForInvestment,
  runCapitalCalls
} from 'redux/actions/capital-call';
import {
  clearInvestment,
  fundInvestment,
  loadFundingOptions,
  loadInvestment
} from 'redux/actions/investments';
import { loadClosing } from 'redux/actions/offerings';
import {
  change,
  Form,
  formValueSelector,
  getFormMeta,
  getFormValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { BankAccount } from 'types/api/bankAccount';
import { CapitalCallForInvestment } from 'types/api/capitalCall';
import { Closing, ClosingStage } from 'types/api/closing';
import {
  FundingOptions,
  Investment,
  PaymentMethod
} from 'types/api/investment';
import { Offering } from 'types/api/offering';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import InvestmentStatusMessage from '../../components/InvestmentStatusMessage';
import InvestmentTitle from '../../components/InvestmentTitle/InvestmentTitle';
import {
  redirectIfInvestmentIsClosed,
  redirectIfInvestmentOnWaitlist,
  redirectIfPendingRollover,
  redirectIfUserCannotInvest
} from '../../helpers';
import { fundSchema } from '../../validations';
import * as styles from './../../Investment.module.scss';
import AlpineNoteBalance from './components/AlpineNoteBalance/AlpineNoteBalance';
import CustomFundingInstructions from './components/CustomFundingInstructions';
import EditingInvestmentDetails from './components/EditingInvestmentDetails/EditingInvestmentDetails';
import PaymentForm from './components/PaymentForm/PaymentForm';
import PaymentMethodDetails from './components/PaymentMethodDetails/PaymentMethodDetails';

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h5>
      <Skeleton width="60%" />
    </h5>
    <p>
      <Skeleton width="90%" />
    </p>
    <p>
      <Skeleton />
    </p>
    <h5>
      <Skeleton width="50%" />
    </h5>
    <p>
      <Skeleton width="70%" />
    </p>
    <p>
      <Skeleton />
    </p>
    <LoadingForwardBackButtons />
  </div>
);

type Params = {
  closing_id: string;
  id: string;
  investment_id: string;
  transaction_id: string;
};

interface CustomProps {
  closing: Closing;
  dispatch: Dispatch;
  fundingOptions: FundingOptions;
  investment: Investment;
  loading: boolean;
  offering: Offering;
  paymentMethodInvalid: boolean;
  selectedAlpineNotes: Record<number, boolean>;
  selectedBalance: number;
  selectedBankAccount?: BankAccount;
  selectedPaymentMethod: PaymentMethod;
  sending: boolean;
  user: User;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const Fund = ({
  closing,
  dispatch,
  error: errorMessage,
  handleSubmit,
  investment,
  fundingOptions,
  offering,
  match: { params },
  user,
  loading,
  sending,
  selectedBankAccount,
  selectedBalance,
  invalid,
  paymentMethodInvalid,
  selectedPaymentMethod,
  selectedAlpineNotes
}: Props) => {
  useRedirectUserWIthInterestAscentInvestment(dispatch, offering);

  const [showAlpineBalance, setShowAlpineBalance] = useState(false);
  const [isEditingInvestment, setIsEditingInvestment] = useState<boolean>();
  const [isCapitalCall, setIsCapitalCall] = useState(false);
  const [capitalCallTransaction, setCapitalCallTransaction] =
    useState<CapitalCallForInvestment>();
  let stepCount = 4;
  if (
    investment?.investment_account?.accreditation_status === 'verified' &&
    !showAlpineBalance
  )
    stepCount = 3;

  const hasFailedTransaction = investment?.transaction_status === 'failed';

  const isLoading = loading || isEditingInvestment === null;

  const customFundingInstructions = offering?.custom_funding_instructions;

  const disableSubmitButton = showAlpineBalance && selectedBalance === 0;

  const minAvailableBalance =
    fundingOptions?.alpine_note_balance?.length > 0
      ? Math.min(
          ...fundingOptions.alpine_note_balance.map(
            option => !option.redeemed && parseInt(option.balance)
          )
        )
      : 0;

  const showUpdateInvestmentButton =
    investment &&
    showAlpineBalance &&
    minAvailableBalance > parseInt(investment.unfunded_amount);

  const confirmRoute = `/invest/${params.closing_id}/investment/${params.investment_id}/confirm`;

  const setEditingInvestmentState = () => {
    let isEditing = false;
    if (
      investment.transaction_status === 'failed' ||
      (parseInt(investment.unfunded_amount) > 0 &&
        parseInt(investment.unfunded_amount) < parseInt(investment.amount))
    ) {
      isEditing = true;
    }
    setIsEditingInvestment(isEditing);
  };

  const setCapitalCallState = () => {
    if (investment.capital_call_opt_in) {
      dispatch(getUserCapitalCallsForInvestment(params.investment_id))
        .then(capitalCalls => {
          if (capitalCalls.length > 0) {
            capitalCalls.forEach(transaction => {
              if (transaction.id === parseInt(params.transaction_id)) {
                if (
                  ['draft', 'failed'].includes(transaction.status) ||
                  (transaction.status === 'pending' &&
                    ['CHECK', 'WIRE'].includes(transaction.payment_method))
                ) {
                  setIsCapitalCall(true);
                  setCapitalCallTransaction(transaction);
                } else {
                  history.replace(confirmRoute);
                }
              }
            });
          }
        })
        .catch(error => handleErrorResponse(error));
    }
  };

  const setShowAlpineBalanceState = () => {
    if (
      investment &&
      parseInt(investment.unfunded_amount) > 0 &&
      fundingOptions?.alpine_note_balance?.length > 0 &&
      fundingOptions.alpine_note_balance.some(note => !note.redeemed)
    ) {
      setShowAlpineBalance(true);
    }
  };

  useEffect(() => {
    dispatch(clearInvestment());
    redirectIfUserCannotInvest(user);

    if (!params.closing_id) {
      history.push('/');
      return;
    }

    const newInvestmentRoute = `/invest/${params.closing_id}/investment/new`;
    if (!params.investment_id) {
      history.push(newInvestmentRoute);
    }

    document.title = 'Fund | EquityMultiple';

    dispatch(loadClosing(params.closing_id))
      .then(res => {
        redirectIfInvestmentIsClosed(res.closing);
      })
      .catch(error => handleErrorResponse(error));

    dispatch(loadInvestment(params.investment_id)).catch(error =>
      handleErrorResponse(error, newInvestmentRoute)
    );
    dispatch(loadFundingOptions(params.investment_id));
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (investment) {
      redirectIfPendingRollover(investment, params.closing_id);
      redirectIfInvestmentOnWaitlist(investment, params.closing_id);
      setEditingInvestmentState();
      setCapitalCallState();
      setShowAlpineBalanceState();
    }
  }, [investment, params]); // eslint-disable-line react-hooks/exhaustive-deps

  const skipAlpineBalancePayment = () => {
    dispatch(change('investmentFund', 'alpine_note_balance', null));
    setShowAlpineBalance(false);
  };

  const updateFormValue = (field, value) => {
    dispatch(change('investmentFund', field, value));
  };

  const onSubmit = values => {
    if (
      showAlpineBalance &&
      selectedBalance > 0 &&
      selectedBalance < parseInt(investment.unfunded_amount)
    ) {
      setShowAlpineBalance(false);
      return;
    }

    if (isCapitalCall) {
      return dispatch(runCapitalCalls(investment.id, values))
        .then(() => {
          history.replace(confirmRoute);
        })
        .catch(error => throwSubmissionErrors(error));
    }

    if (values.alpine_note_balance) {
      const selectedNotes = Object.keys(values.alpine_note_balance);
      values.alpine_note_balance = selectedNotes.filter(
        key => values.alpine_note_balance[key]
      );
    }

    const valuesToSubmit = {
      ...values,
      event_type: 'Contribution',
      type: 'Investment'
    };

    return dispatch(fundInvestment(investment.id, valuesToSubmit))
      .then(() => {
        history.push(confirmRoute);
      })
      .catch(error => throwSubmissionErrors(error));
  };

  const getBackLink = () => {
    if (hasFailedTransaction) return '/';
    const path = isCapitalCall ? 'capital_call/allocation' : 'sign';
    return `/invest/${investment.closing_id}/investment/${investment.id}/${path}`;
  };

  const getSubmitButtonText = () => {
    if (hasFailedTransaction) return 'Try Again';
    else if (
      parseInt(investment.amount) === selectedBalance ||
      selectedPaymentMethod ||
      !showAlpineBalance
    ) {
      return 'Complete';
    }
    return 'Next';
  };

  const renderAlpineBalance = (
    event: React.MouseEvent<ButtonTypes, MouseEvent>
  ) => {
    if (fundingOptions?.alpine_note_balance?.length > 0 && !showAlpineBalance) {
      event.preventDefault();
      setShowAlpineBalance(true);
    }
  };

  return (
    <div data-testid="fund">
      <Container className="container-narrow">
        <InvestmentTitle
          title={offering?.title}
          stage={!isCapitalCall ? (closing.stage as ClosingStage) : undefined}
          loading={isLoading}
        />
        <InvestmentStatusMessage closing={closing} investment={investment} />
        <InvestmentStatus
          step={3}
          investment={investment || null}
          closing={closing || null}
          capitalCallTransaction={capitalCallTransaction}
          noTimer
          loading={isLoading}
          dateInvested={closing?.date_invested}
        />
        <Card className="border-top-card">
          <ProgressBarBorder currentStep={3} steps={stepCount} />
          <h3 className="margin-top-0 margin-x">
            {hasFailedTransaction
              ? 'Retry Payment'
              : 'Fund to complete investment'}
          </h3>
          {selectedBalance > 0 && !showAlpineBalance ? (
            <p>
              Select the funding method for remaining amount to complete your
              investment below.
            </p>
          ) : (
            <p>Complete funding step to secure your spot in the investment.</p>
          )}
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <Form name="investmentFund" onSubmit={handleSubmit(onSubmit)}>
              {customFundingInstructions ? (
                <CustomFundingInstructions
                  instructions={customFundingInstructions}
                />
              ) : (
                <>
                  {isEditingInvestment && !hasFailedTransaction && (
                    <EditingInvestmentDetails
                      investment={investment}
                      selectedBalance={selectedBalance}
                    />
                  )}
                  {errorMessage && (
                    <ErrorLabel className="margin-xx" message={errorMessage} />
                  )}
                  {showAlpineBalance ? (
                    <AlpineNoteBalance
                      investment={investment}
                      fundingOptions={fundingOptions}
                      selectedBalance={selectedBalance}
                      minAvailableBalance={minAvailableBalance}
                      selectedAlpineNotes={selectedAlpineNotes}
                    />
                  ) : (
                    <PaymentForm
                      investment={investment}
                      fundingOptions={fundingOptions}
                      capitalCallTransaction={capitalCallTransaction}
                      selectedBalance={selectedBalance}
                      updateFormValue={updateFormValue}
                      selectedPaymentMethod={selectedPaymentMethod}
                      invalid={paymentMethodInvalid && invalid}
                    />
                  )}
                </>
              )}
              {(selectedPaymentMethod !== null || selectedBalance > 0) && (
                <PaymentMethodDetails
                  type={selectedPaymentMethod}
                  offering={offering}
                  selectedBankAccount={selectedBankAccount}
                  investment={investment}
                  selectedBalance={selectedBalance}
                />
              )}
              <div
                className={`forwardBackButtonWrapCompact float-right ${styles.formButtons} ${showAlpineBalance ? styles.multiButton : ''}`}
              >
                {showUpdateInvestmentButton ? (
                  <Button
                    variant="orange"
                    className={styles.submitButton}
                    wrapper={
                      <Link
                        to={`/invest/${investment.closing_id}/investment/${investment.id}/interest`}
                      />
                    }
                  >
                    Update Investment
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="orange"
                    data-testid="fundButton"
                    loading={sending}
                    className={styles.submitButton}
                    disabled={disableSubmitButton}
                  >
                    {getSubmitButtonText()}
                  </Button>
                )}
                <div
                  className={showAlpineBalance ? styles.secondaryButtons : ''}
                >
                  {showAlpineBalance && (
                    <Button
                      data-testid="skipButton"
                      variant="outlined"
                      onClick={skipAlpineBalancePayment}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    wrapper={<Link to={getBackLink()} />}
                    variant="outlined"
                    className="arrowBackButton"
                    onClick={event => renderAlpineBalance(event)}
                  >
                    <Back />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Card>
      </Container>
    </div>
  );
};

const selector = formValueSelector('investmentFund');

function mapStateToProps(state) {
  let selectedBalance = 0;
  let selectedBankAccount = null;

  const initialValues = {
    alpine_note_balance: {},
    payment_method: undefined,
    source: undefined
  };

  if (state.investments.investment?.transactions?.contributions?.length > 0) {
    const transactions =
      state.investments.investment.transactions.contributions;
    let transaction = transactions[transactions.length - 1];

    if (state.investments?.investment?.capital_call_opt_in) {
      transaction = transactions.find(
        transactionToCheck =>
          transactionToCheck.type === 'Capital Call' &&
          transactionToCheck.status === 'pending'
      );
    }

    if (transaction) {
      initialValues.payment_method = transaction.payment_method;
      initialValues.source = transaction.funding_source?.id;
    }
  }

  if (state.investments.fundingOptions?.alpine_note_balance?.length > 0) {
    state.investments.fundingOptions.alpine_note_balance.forEach(note => {
      if (note.redeemed) {
        initialValues.alpine_note_balance[note.id] = true;
      } else if (selector(state, `alpine_note_balance.${note.id}`)) {
        selectedBalance += parseInt(note.balance);
      }
    });
  }

  const selectedSourceId = selector(state, 'source');
  if (state.investments.fundingOptions?.funding_sources?.length > 0) {
    selectedBankAccount = state.investments.fundingOptions.funding_sources.find(
      source => source.id === selectedSourceId
    );
  }

  const selectedPaymentMethod = selector(state, 'payment_method');
  const meta = getFormMeta('investmentFund')(state) as Record<
    string,
    Record<string, boolean>
  >;
  const values = getFormValues('investmentFund')(state) as Record<
    string,
    Record<string, boolean>
  >;

  const paymentMethodInvalid =
    meta?.payment_method?.touched &&
    !meta.payment_method.visited &&
    !values?.payment_method;

  return {
    sending: state.investments.sending || state.capitalCall.sending,
    loading: !state.offerings.offering || !state.investments.investment,
    offering: state.offerings.offering,
    closing: state.offerings.closing,
    investment: state.investments.investment,
    fundingOptions: state.investments.fundingOptions,
    user: state.auth.user,
    initialValues,
    selectedBalance,
    selectedBankAccount,
    selectedPaymentMethod,
    paymentMethodInvalid,
    selectedAlpineNotes: selector(state, 'alpine_note_balance') || {}
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investmentFund',
    enableReinitialize: true,
    touchOnBlur: false,
    validate: validateSchema(fundSchema),
    onSubmitFail: scrollToError
  })(Fund)
);
