import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import RecoveryCodes from 'components/RecoveryCodes/RecoveryCodes';
import SmsConfirmationForm from 'components/SmsConfirmationForm/SmsConfirmationForm';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import {
  requestPhoneVerificationCode,
  updateUserProfile,
  verifyPhoneVerificationCode
} from 'redux/actions/user-settings';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import humane from 'utilities/humane';
import { throwSubmissionErrors } from 'utilities/validation';

import RequireLoginWrap from '../components/RequireLoginWrap';
import { PhoneFormValues } from '../types';
import PhoneForm from './components/PhoneForm/PhoneForm';

interface Props {
  dispatch: Dispatch;
  recoveryCodes: string[];
}

const Phone = ({ dispatch, recoveryCodes }: Props) => {
  const [showSmsConfirmationForm, setShowSmsConfirmationForm] = useState(false);
  const [phoneFormValues, setPhoneFormValues] = useState<PhoneFormValues>();

  useRecaptcha();

  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';
  }, []);

  const getFormattedPhoneNumber = () => {
    if (phoneFormValues) {
      return `+${phoneFormValues.country_code} ${phoneFormValues.phone}`;
    }
    return '';
  };

  const getSmsConfirmationFormHeading = () => {
    let heading = 'Complete opt-in to receive SMS messages on your phone';
    if (phoneFormValues.enable_two_factor && phoneFormValues.opted_to_text) {
      heading =
        'Complete setting up your phone for security and messages about offering or your portfolio';
    } else if (phoneFormValues.enable_two_factor) {
      heading = 'Complete securing your account with two factor authentication';
    }
    return heading;
  };

  const submitPhoneForm = async values => {
    if (values.opted_to_text || values.enable_two_factor) {
      const token = await getRecaptchaToken('requestSmsCode');

      const submitValues = {
        captcha_response: token,
        user: {
          phone: values.phone,
          country_code: values.country_code
        }
      };

      return dispatch(requestPhoneVerificationCode(submitValues))
        .then(() => {
          setPhoneFormValues(values);
          setShowSmsConfirmationForm(true);
        })
        .catch(err => {
          throwSubmissionErrors(err);
        });
    } else {
      return dispatch(
        updateUserProfile({
          phone: values.phone,
          investor_profile_attributes: {
            opted_to_text: values.opted_to_text
          }
        })
      )
        .then(async () => {
          await dispatch(loadAuth());

          history.push('/users/signup/discover');
        })
        .catch(err => {
          throwSubmissionErrors(err);
        });
    }
  };

  const submitSmsConfirmationForm = async values => {
    const token = await getRecaptchaToken('verifySmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        code: values.code,
        ...phoneFormValues
      }
    };

    return dispatch(verifyPhoneVerificationCode(submitValues))
      .then(async res => {
        await dispatch(loadAuth());

        // If a user chose to enable 2FA then they'll receive backup_codes, if they just opt into SMS then they won't
        if (res.backup_codes) {
          setShowSmsConfirmationForm(false);
        } else {
          history.push('/users/signup/discover');
        }
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const handleResendCode = async () => {
    const token = await getRecaptchaToken('requestSmsCode');

    const submitValues = {
      captcha_response: token,
      user: {
        phone: phoneFormValues.phone,
        country_code: phoneFormValues.country_code
      }
    };

    return dispatch(requestPhoneVerificationCode(submitValues))
      .then(() => {
        humane.notice(`Code resent to ${getFormattedPhoneNumber()}`);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  return (
    <RequireLoginWrap>
      <AccountProgressContainer signupStage="sign up" showSkipLink>
        {recoveryCodes ? (
          <>
            <RecoveryCodes recoveryCodes={recoveryCodes} showHeading />
            <Button
              variant="orange"
              className="button-fixed-width margin-top-xx"
              wrapper={<Link to="/users/signup/discover" />}
            >
              Continue
            </Button>
          </>
        ) : showSmsConfirmationForm ? (
          <SmsConfirmationForm
            onSubmit={submitSmsConfirmationForm}
            phoneNumber={getFormattedPhoneNumber()}
            heading={getSmsConfirmationFormHeading()}
            changePhone={() => setShowSmsConfirmationForm(false)}
            resendCode={handleResendCode}
          />
        ) : (
          <PhoneForm onSubmit={submitPhoneForm} />
        )}
      </AccountProgressContainer>
    </RequireLoginWrap>
  );
};

function mapStateToProps(state) {
  return {
    recoveryCodes: state.userSettings.twoFactorRecoveryCodes
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Phone);
