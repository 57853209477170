export const LOAD = 'invite/LOAD';
export const LOAD_SUCCESS = 'invite/LOAD_SUCCESS';
export const LOAD_FAIL = 'invite/LOAD_FAIL';

export const RESEND_INVITE = 'invite/RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'invite/RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_FAIL = 'invite/RESEND_INVITE_FAIL';

export const INVITATION_SEND = 'invite/INVITATION_SEND';
export const INVITATION_SEND_SUCCESS = 'invite/INVITATION_SEND_SUCCESS';
export const INVITATION_SEND_FAIL = 'invite/INVITATION_SEND_FAIL';

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/mkt/invitations')
  };
}

export function resendInviteAction(id) {
  return {
    types: [RESEND_INVITE, RESEND_INVITE_SUCCESS, RESEND_INVITE_FAIL],
    promise: client => client.get(`/mkt/invitations/${id}/resend_invite`)
  };
}

export function emailInvitation(params) {
  return {
    types: [INVITATION_SEND, INVITATION_SEND_SUCCESS, INVITATION_SEND_FAIL],
    promise: client => client.post('/mkt/users/invitation', { data: params })
  };
}
