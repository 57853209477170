import history from 'browserHistory';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  loadInvestmentAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { accountInformationIraSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import IraAccount from './IraAccount';

interface Params {
  reference_id: string;
}

interface CustomProps {
  businessType: string;
  dispatch: Dispatch;
  entityName: string;
  investmentAccount: SingleInvestmentAccount;
  submitting: boolean;
  user: User;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const IraUpdate = ({
  businessType,
  dispatch,
  entityName,
  handleSubmit,
  match: { params },
  investmentAccount,
  submitting,
  user
}: Props) => {
  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
  }, [dispatch, params]);

  const onSubmit = values => {
    if (values.investment_account) {
      EmAnalytics.track('Submits IRA Information', 'Onboarding', {
        account_type: 'IRA'
      });
      values.investment_account.id = investmentAccount.id;
      values.investment_account.type = 'ira';
      return dispatch(updateInvestmentAccount(values))
        .then(res => {
          history.push(
            `/accounts/wizard/ira/thanks/${res.investment_account.reference_id}`
          );
        })
        .catch(res => throwSubmissionErrors(res));
    }
    return null;
  };

  const cardHeader = (
    <h2 className="margin-top-0">Individual Retirement Account</h2>
  );

  return (
    <AccountWrap loading={!investmentAccount} cardHeader={cardHeader}>
      {investmentAccount && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <IraAccount
            businessType={businessType}
            entityName={entityName}
            submitting={submitting}
            irRep={user.investor_profile?.representative_meeting_link}
          />
        </Form>
      )}
    </AccountWrap>
  );
};

const selector = formValueSelector('investment_account');

function mapStateToProps(state) {
  const businessType = selector(state, 'investment_account.business_type');
  const entityName = selector(state, 'investment_account.entity_name');
  return {
    investmentAccount: state.account.investmentAccount,
    initialValues: {},
    businessType,
    entityName,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(accountInformationIraSchema)
  })(IraUpdate)
);
