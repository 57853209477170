import { Card } from '@equitymultiple/react-eui';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';

import history from '../../browserHistory';
import SetPassword from '../../components/forms/SetPassword/SetPassword';
import { HeaderSimple } from '../../components/HeaderSimple';
import {
  resetPassword,
  validateResetPasswordToken
} from '../../redux/actions/auth';
import { Dispatch } from '../../types/redux';
import humane from '../../utilities/humane';

type Props = {
  dispatch: Dispatch;
  location: {
    search: string;
  };
  resetPasswordComplete: boolean;
};

const ResetPassword = ({
  dispatch,
  location,
  resetPasswordComplete
}: Props) => {
  const redirectOnError = () => {
    humane.error(
      'Reset password token is invalid. Please resend reset password instructions.'
    );
    history.push('/users/sign_in');
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query?.reset_password_token) {
      dispatch(validateResetPasswordToken(query.reset_password_token)).catch(
        () => {
          redirectOnError();
        }
      );
    } else {
      redirectOnError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetPasswordComplete) {
      humane.notice(
        'Password has been reset sucessfully, you may log in with your new password.'
      );
      history.push('/users/sign_in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordComplete]);

  const handleSubmit = formData => {
    const query = queryString.parse(location.search);

    if (query) {
      const data = {
        user: {
          password: formData.password,
          reset_password_token: query.reset_password_token
        }
      };

      return dispatch(resetPassword(data)).catch(err => {
        humane.notice(err.body.message);
      });
    }
    return null;
  };

  return (
    <div>
      <HeaderSimple />
      <Container>
        <Card>
          <h4 className="margin-top-0">Choose a New Password</h4>
          <SetPassword onSubmit={handleSubmit} />
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    resetPasswordComplete: store.auth.resetPasswordComplete
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(ResetPassword);
