import { Button, ErrorLabel, Tooltip } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import { ReduxFormRadioField } from 'components/ReduxFormFields';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  createInvestmentAccount,
  loadInvestmentAccounts
} from 'redux/actions/account';
import { loadAuth } from 'redux/actions/auth';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { mustCompleteProfile } from 'utilities/user';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { accountStatus } from '../../contents';
import { hasShellInvestmentAccount, newAccountRoute } from '../../helpers';
import { newAccountSchema } from '../../validation';
import AccountWrap from '../AccountWrap/AccountWrap';
import BackLink from '../BackLink/BackLink';

interface CustomProps {
  dispatch: Dispatch;
  investmentAccounts: SingleInvestmentAccount[];
  loading: boolean;
  selectedType: string;
  submitting: boolean;
  user: User;
}

type PropsBeforeReduxForm = RouteComponentProps & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const NewAccount = ({
  investmentAccounts,
  loading,
  submitting,
  dispatch,
  handleSubmit,
  selectedType,
  user
}: Props) => {
  const [incompleteIndividual, setIncompleteIndividual] = useState(false);
  const [incompleteEntity, setIncompleteEntity] = useState(false);

  useEffect(() => {
    document.title = 'My Accounts | Account Options';
    dispatch(loadAuth())
      .then(userRes => {
        if (mustCompleteProfile(userRes)) history.replace('/');
      })
      .catch(error => handleErrorResponse(error));
    dispatch(loadInvestmentAccounts())
      .then(accounts => {
        if (accounts) {
          const hasIncompleteEntity = accounts.some(
            accountToCheck =>
              accountToCheck.type === 'entity' &&
              accountToCheck.status !== accountStatus.created
          );
          if (hasShellInvestmentAccount(accounts, 'individual'))
            setIncompleteIndividual(true);
          if (hasIncompleteEntity) setIncompleteEntity(true);
        }
      })
      .catch(error => handleErrorResponse(error));
  }, [dispatch]);

  const onSubmit = values => {
    const accountType = values.investment_account.type;

    return dispatch(createInvestmentAccount(values))
      .then(res => {
        EmAnalytics.track('Opens Investment Account', 'Onboarding', {
          account_type:
            accountType === 'ira' ? 'IRA' : utils.startCase(accountType)
        });
        const referenceId = res.investment_account.reference_id;
        history.push(newAccountRoute(accountType, referenceId));
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const isUsResident = user?.investor_profile?.residence_status !== 'Other';
  const backLinkRoute =
    investmentAccounts?.length < 2 &&
    hasShellInvestmentAccount(investmentAccounts)
      ? '/'
      : '/accounts';

  let canOpenIra = true;
  if (
    selectedType === 'ira' &&
    !investmentAccounts?.some(
      account =>
        account.status === accountStatus.created &&
        (account.type === 'individual' || account.type === 'entity')
    )
  )
    canOpenIra = false;

  let entityCount = 0;

  if (investmentAccounts)
    entityCount = investmentAccounts.filter(
      account => account.type === 'entity'
    ).length;

  let canOpenEntity = true;
  if (
    selectedType === 'entity' &&
    ((incompleteEntity && isUsResident) ||
      (incompleteEntity && entityCount > 1 && !isUsResident))
  )
    canOpenEntity = false;

  return (
    <AccountWrap hideHeader loading={loading}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">Select your account type</h4>
        <p>
          Open an Investment Account to easily make investments when you
          identify an Offering that aligns with your investing strategy. Please
          note that under SEC rules, each Investment Account must qualify as an
          accredited investor.
          <Tooltip
            tooltipContent={
              <span>
                The federal securities laws define an accredited investor as any
                of the following: (i) a natural person with income exceeding
                $200,000 in each of the two most recent years or joint income
                with a spouse exceeding $300,000 for those years and a
                reasonable expectation of the same income level in the current
                year; (ii) a […]
                <a
                  href="https://www.investor.gov/additional-resources/news-alerts/alerts-bulletins/investor-bulletin-accredited-investors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </span>
            }
            infoIcon
            className="info-icon-margin-left"
          />
        </p>
        {!isUsResident && (
          <p>
            Foreign nationals can only invest with us through an accredited U.S.
            entity.
          </p>
        )}
        <div className="margin-x">
          {incompleteIndividual && isUsResident && (
            <Field
              type="radio"
              name="investment_account.type"
              id="individual"
              value="individual"
              component={ReduxFormRadioField}
              label="Individual Account"
            />
          )}
          <Field
            type="radio"
            name="investment_account.type"
            id="entity"
            value="entity"
            component={ReduxFormRadioField}
            label="Entity Account"
          />
          {isUsResident && (
            <div>
              <Field
                type="radio"
                name="investment_account.type"
                id="joint-account"
                value="joint account"
                component={ReduxFormRadioField}
                label="Joint Account"
              />
              <Field
                type="radio"
                name="investment_account.type"
                id="ira"
                value="ira"
                component={ReduxFormRadioField}
                label="Individual Retirement Account (IRA)"
              />
            </div>
          )}
        </div>
        {!canOpenIra && (
          <ErrorLabel message="You must open an individual or entity account before opening an IRA account." />
        )}
        {!canOpenEntity && (
          <ErrorLabel message="You can only link one entity account at a time.  Please verify that all current accounts are in good standing prior to adding another." />
        )}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            disabled={!selectedType || !canOpenIra || !canOpenEntity}
            loading={submitting}
            variant="orange"
            className="button-fixed-width"
            data-testid="newAccountButton"
          >
            Continue
          </Button>
          <BackLink route={backLinkRoute} />
        </div>
      </Form>
    </AccountWrap>
  );
};

const selector = formValueSelector('investment_account');

function mapStateToProps(state) {
  const selectedType = selector(state, 'investment_account.type');

  return {
    loading: state.account.loading,
    submitting: state.account.submitting,
    investmentAccounts: state.account.investmentAccounts,
    user: state.auth.user,
    selectedType,
    initialValues: {
      investment_account: {
        type: null
      }
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'investment_account',
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(newAccountSchema)
  })(NewAccount)
);
