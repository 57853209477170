import { Button } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import {
  ReduxFormDateSelectField,
  ReduxFormFileUploaderField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  loadBeneficialOwner,
  loadInvestmentAccount,
  updateBeneficialOwner
} from 'redux/actions/account';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import {
  AccountDocument,
  BeneficialOwner,
  SingleInvestmentAccount
} from 'types/api/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import { handleErrorResponse } from 'utilities/errorHandlers';
import {
  percentageMask,
  percentageMaskOptions,
  phoneMask,
  ssn,
  ssnMaskOptions
} from 'utilities/masks';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { idTypes, stateOptions } from '../contents';
import { formatDate, isComplete } from '../helpers';
import { beneficialOwnerSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import DocumentPreview from './DocumentPreview/DocumentPreview';

interface Params {
  id: string;
  reference_id: string;
}

interface CustomProps {
  beneficialOwner: BeneficialOwner;
  beneficialOwnerSsn: boolean;
  dispatch: Dispatch;
  initialValues: {
    beneficial_owner: {
      document: AccountDocument;
    };
  };
  investmentAccount: SingleInvestmentAccount;
  submitting: boolean;
}

type PropsBeforeReduxForm = RouteComponentProps<Params> & CustomProps;

type Props = InjectedFormProps<object, PropsBeforeReduxForm> &
  PropsBeforeReduxForm;

const BeneficialEdit = ({
  beneficialOwner,
  beneficialOwnerSsn,
  dispatch,
  handleSubmit,
  initialValues,
  investmentAccount,
  match: { params },
  submitting
}: Props) => {
  const [idFieldsVisible, setIdFieldsVisible] = useState(false);

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(error)
    );
    dispatch(loadBeneficialOwner(params.id))
      .then(res => {
        if (!res.beneficial_owner.document) setIdFieldsVisible(true);
      })
      .catch(error => handleErrorResponse(error));
  }, [dispatch, params]);

  const onSubmit = values => {
    if (!idFieldsVisible) delete values.beneficial_owner.document;

    return dispatch(updateBeneficialOwner(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          history.push(
            `/accounts/update/${investmentAccount.account_type}/${params.reference_id}`
          );
        } else {
          history.push(
            `/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/${params.reference_id}`
          );
        }
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const handleUpload = file => {
    dispatch(change('beneficialOwner', 'beneficial_owner.document', file));
  };

  const toggleIdFieldVisibility = () => {
    if (idFieldsVisible)
      dispatch(
        change(
          'beneficialOwner',
          'beneficial_owner.document',
          initialValues.beneficial_owner.document
        )
      );
    else dispatch(change('beneficialOwner', 'beneficial_owner.document', null));

    setIdFieldsVisible(!idFieldsVisible);
  };

  const isLoading = !investmentAccount || !beneficialOwner;

  return (
    <AccountWrap loading={isLoading} account={investmentAccount}>
      {!isLoading && (
        <>
          <h4 className="margin-top-0">Update Beneficial Owner</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.title"
                  component={ReduxFormInputField}
                  label="Title"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.percentage_ownership"
                  mask={percentageMask}
                  inputMaskOptions={percentageMaskOptions}
                  component={ReduxFormInputField}
                  label="Percentage Ownership"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.first_name"
                  component={ReduxFormInputField}
                  label="First Name"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.last_name"
                  component={ReduxFormInputField}
                  label="Last Name"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6} className="overflow-visible">
                <Field
                  name="beneficial_owner.dob"
                  id="beneficial_owner.dob"
                  component={ReduxFormDateSelectField}
                  label="Date of Birth"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.ssn"
                  disabled={beneficialOwnerSsn === true}
                  mask={ssn}
                  inputMaskOptions={ssnMaskOptions}
                  component={ReduxFormInputField}
                  label="SSN"
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.email"
                  component={ReduxFormInputField}
                  label="Email"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.phone"
                  mask={phoneMask}
                  component={ReduxFormInputField}
                  label="Phone Number"
                />
              </Col>
            </Row>
            <h5>Address Information</h5>
            <Row className="margin-row">
              <Col md={6}>
                <Field
                  name="beneficial_owner.address"
                  component={ReduxFormInputField}
                  label="Address"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="beneficial_owner.address2"
                  component={ReduxFormInputField}
                  label="Address 2"
                  placeholder="Apartment number, etc."
                />
              </Col>
            </Row>
            <Row className="margin-row">
              <Col md={4}>
                <Field
                  name="beneficial_owner.city"
                  component={ReduxFormInputField}
                  label="City"
                />
              </Col>
              <Col md={4} className="overflow-visible">
                <Field
                  name="beneficial_owner.state"
                  id="beneficial_owner_state"
                  component={ReduxFormSelectField}
                  label="State"
                  options={stateOptions}
                />
              </Col>
              <Col md={4}>
                <Field
                  name="beneficial_owner.postal_code"
                  component={ReduxFormInputField}
                  mask="99999"
                  label="Postal Code"
                />
              </Col>
            </Row>
            <h5>Identification Document</h5>
            {beneficialOwner.document && beneficialOwner.document.name && (
              <div>
                <div className="text-label margin-x">
                  Identification Document
                </div>
                <DocumentPreview
                  document={beneficialOwner.document}
                  documentType="Identification Document"
                />
              </div>
            )}
            {idFieldsVisible && (
              <Row className="margin-row">
                <Col md={6}>
                  <Field
                    name="beneficial_owner.document"
                    id="beneficial_owner_document"
                    component={ReduxFormFileUploaderField}
                    acceptedFileTypes={['JPG', 'PNG']}
                    maxSize={maxUploadSize}
                    uploading={submitting}
                    upload={handleUpload}
                    showImage
                  />
                </Col>
                <Col md={6} className="overflow-visible">
                  <Field
                    name="beneficial_owner.document_type"
                    id="beneficial_owner_document_type"
                    component={ReduxFormSelectField}
                    label="Document Type"
                    options={idTypes.map(key => ({
                      label: key.label,
                      value: key.value
                    }))}
                  />
                </Col>
              </Row>
            )}
            <button
              className="text-link"
              onClick={toggleIdFieldVisibility}
              type="button"
            >
              {idFieldsVisible
                ? 'Cancel Identification Document Update'
                : 'Update Identification Document'}
            </button>

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                className="float-right"
                style={{ width: 160 }}
                loading={submitting}
              >
                Update
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="Edit Beneficial Owner"
              />
            </div>
          </form>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  let beneficialOwnerSsn = false;
  let dob;
  let percent;
  if (state.account?.beneficialOwner)
    dob = formatDate(state.account.beneficialOwner.dob);
  if (state.account?.beneficialOwner?.percentage_ownership)
    percent = parseFloat(
      state.account.beneficialOwner.percentage_ownership
    ).toFixed(2);
  if (state.account?.beneficialOwner?.ssn !== null) beneficialOwnerSsn = true;
  return {
    beneficialOwnerSsn,
    investmentAccount: state.account.investmentAccount,
    beneficialOwner: state.account.beneficialOwner,
    initialValues: {
      beneficial_owner: {
        ...state.account.beneficialOwner,
        beneficialOwners: state.account.beneficialOwners,
        currentBeneficialOwner: state.account.beneficialOwner,
        dob,
        percentage_ownership: percent
      }
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'beneficialOwner',
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(beneficialOwnerSchema)
  })(BeneficialEdit)
);
