import { Button, Tooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { adBlockerIntercomTooltip } from 'utilities/constants';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { ReduxFormInputField } from '../../../components/ReduxFormFields';
import forgotPasswordSchema from '../validation';
import * as styles from './ForgotPasswordForm.module.scss';

const ForgotPasswordForm = ({
  handleSubmit,
  submitting
}: InjectedFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <h6>Enter your email address</h6>
      <Field
        data-testid="ForgotPassword-"
        name="email"
        id="email"
        className="input-fixed-width"
        component={ReduxFormInputField}
        label="Email"
      />
      <div className={styles.description}>
        *Note, if you're having trouble, you can talk to us by clicking on the
        chat icon <Tooltip tooltipContent={adBlockerIntercomTooltip} infoIcon />{' '}
        in the bottom right.
      </div>
      <Button type="submit" className="float-right" loading={submitting}>
        Reset Password
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'forgotPassword',
  touchOnBlur: false,
  validate: validateSchema(forgotPasswordSchema, {}),
  onSubmitFail: scrollToError
})(ForgotPasswordForm);
