import { Button, Card } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';

import history from '../../browserHistory';
import { HeaderSimple } from '../../components/HeaderSimple';
import mail from '../../images/mailpic.png';
import { resendUnlockEmail } from '../../redux/actions/auth';
import { Dispatch } from '../../types/redux';
import humane from '../../utilities/humane';
import * as styles from './UnlockInstructions.module.scss';

type Props = {
  dispatch: Dispatch;
  location: {
    state?: { email?: string };
  };
  sendingUnlockEmail: boolean;
};

const UnlockInstructions = ({
  dispatch,
  location,
  sendingUnlockEmail
}: Props) => {
  const email = location?.state?.email;

  useEffect(() => {
    if (!email) history.replace('/users/sign_in');
  }, [email]);

  useEffect(() => {
    document.title = 'Unlock Instructions | EquityMultiple';
  }, []);

  const resendEmail = () => {
    dispatch(resendUnlockEmail({ email })).then(res => {
      humane.notice(res.message);
      setTimeout(() => {
        history.replace('/users/sign_in');
      }, 1000);
    });
  };

  return (
    <div>
      <HeaderSimple />
      <Container className={styles.unlockInstructions}>
        <Card>
          <img src={mail} alt="" />
          <h3>For security reasons, your account has been locked.</h3>
          <p>
            Please follow the instructions sent to your e-mail address to unlock
            your account.
            <br />
            Or you can wait 24 hours for your account to be unlocked
            automatically, and you can try signing in again.
          </p>
          <Button onClick={resendEmail} loading={sendingUnlockEmail}>
            Resend Email
          </Button>
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    sendingUnlockEmail: store.auth.sendingUnlockEmail
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(UnlockInstructions);
