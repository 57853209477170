import { Button } from '@equitymultiple/react-eui';
import SetPasswordFields from 'components/forms/SetPassword/fields/SetPasswordFields';
import { ReduxFormPasswordField } from 'components/ReduxFormFields';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { updatePasswordSchema } from '../validation';

interface FormData {
  confirm_new_password: string;
  current_password: string;
  new_password: string;
}

interface Props extends Partial<InjectedFormProps<FormData, Props>> {
  onSubmit: (values) => void;
  updatingPassword?: boolean;
}

const SettingUpdatePasswordForm = ({
  handleSubmit,
  onSubmit,
  updatingPassword
}: Props) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="current_password"
        id="current_password"
        label="Current Password"
        component={ReduxFormPasswordField}
        className="input-fixed-width-wide"
      />
      <SetPasswordFields changePassword />
      <Button
        className="float-right"
        style={{ width: '140px' }}
        type="submit"
        loading={updatingPassword}
      >
        Update
      </Button>
    </form>
  );
};

export default reduxForm<FormData, Props>({
  form: 'updatePassword',
  touchOnBlur: false,
  validate: validateSchema(updatePasswordSchema),
  onSubmitFail: scrollToError
})(SettingUpdatePasswordForm);
