export const accreditationOptions = [
  {
    label: 'I earn $200K+ yearly or $300K+ with my spouse/partner',
    value: 'Income'
  },
  {
    label: 'I have a net worth of 1M+ (excluding my primary residence)',
    value: 'Net Worth'
  },
  {
    label: 'I invest through an entity with $5M+ in assets',
    value: 'Qualified Purchaser'
  },
  {
    label: 'I hold a Series 7, 65, or 82 license in good standing',
    value: 'Licensed Professional'
  },
  {
    label: 'None of these',
    value: 'None'
  }
];

export const attributionOptions = [
  'Advertisements',
  'Online Research',
  'Referred by Fellow Investor',
  'Marcus and Millichap Relationship'
] as const;

export type AttributionOption = (typeof attributionOptions)[number];

export const attributionAdvertisementOptions = [
  'Facebook',
  'Instagram',
  'TikTok',
  'YouTube',
  'NY Times / The Daily',
  'WAMC Public Radio',
  'Physician(s) on FIRE',
  'Investopedia / The Balance',
  'Bankrate',
  'The Water Coolest',
  'Vincent Spotlight',
  'Alts.co',
  'Gmail',
  'Other Podcasts',
  'Another Website'
];

export const attributionResearchOptions = [
  {
    label: 'Search Engines (Google, Bing, etc.)',
    value: 'Search Engines'
  },
  {
    label: 'Investor Education Websites (Nerdwallet, Investopedia, etc.)',
    value: 'Investor Education Websites'
  },
  {
    label: 'Reddit',
    value: 'Reddit'
  },
  {
    label: 'YouTube',
    value: 'YouTube'
  },
  {
    label: 'TikTok',
    value: 'TikTok'
  },
  {
    label: 'Online Trading Academy',
    value: 'Online Trading Academy'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const attributionDetailSourceOptions = [
  "I'm a Marcus & Millichap Agent or Employee",
  "I'm a Marcus & Millichap Client",
  'I heard about the EquityMultiple partnership in the news'
];
