import { Button } from '@equitymultiple/react-eui';
import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import SetPasswordFields from './fields/SetPasswordFields';
import { setPasswordSchema } from './validation';

const SetPassword = ({ handleSubmit }: InjectedFormProps) => {
  return (
    <form data-testid="SetPassword-form" onSubmit={handleSubmit}>
      <SetPasswordFields />
      <Button
        type="submit"
        className="button-fixed-width float-right margin-top-xx"
      >
        Continue
      </Button>
    </form>
  );
};

const onSubmit = values => {
  return values;
};

export default reduxForm<object, Partial<InjectedFormProps>>({
  form: 'setPassword',
  onSubmitFail: scrollToError,
  validate: validateSchema(setPasswordSchema, {}),
  onSubmit
})(SetPassword);
