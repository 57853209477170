import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import { ReduxFormInputField } from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadAuth, resendConfirmationEmail } from 'redux/actions/auth';
import { updateUsername } from 'redux/actions/user-settings';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import RequireLoginWrap from '../components/RequireLoginWrap';
import { emailVerifyChannel } from '../helpers';
import { emailVerifySchema } from '../validation';

interface FormValues {
  unconfirmed_email?: string;
}

export interface Props extends Partial<InjectedFormProps<FormValues, Props>> {
  dispatch: Dispatch;
  resendingConfirmation: boolean;
  user: User;
}

const EmailVerify = ({
  dispatch,
  error,
  handleSubmit,
  submitting,
  resendingConfirmation,
  user
}: Props) => {
  const [showChangeForm, setShowChangeForm] = useState(false);

  const email = user?.unconfirmed_email || user?.email;

  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';

    dispatch(loadAuth()).then(userRes => {
      if (userRes?.confirmed) history.replace('/users/profile/start');
    });

    // If a user verifies their email in another tab then redirect this one to the homepage
    emailVerifyChannel.onmessage = event => {
      if (event.data === 'verified') history.replace('/users/profile/start');
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    return dispatch(updateUsername({ user: values }))
      .then(() => {
        dispatch(loadAuth());
        humane.notice('Email address successfully updated');
      })
      .catch(res => throwSubmissionErrors(res));
  };

  const handleResend = () => {
    return dispatch(resendConfirmationEmail({ user: { email } }))
      .then(() => {
        humane.notice(`Confirmation email resent to ${email}`);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const loading = resendingConfirmation || submitting;

  return user ? (
    <RequireLoginWrap>
      <AccountProgressContainer signupStage="sign up">
        {showChangeForm ? (
          <Form onSubmit={handleSubmit(onSubmit)} data-testid="changeEmailForm">
            <h3>Change Email</h3>
            <p className="margin-xx">
              This email is how we'll communicate details about your account,
              announce new investment opportunities, share new investor
              resources, and more.
            </p>
            <Field
              id="unconfirmed_email"
              name="unconfirmed_email"
              component={ReduxFormInputField}
              label="Email Address"
              className="input-fixed-width"
            />

            {error && <ErrorLabel message={error} className="margin-xxx" />}

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                loading={submitting}
                variant="orange"
                className="button-fixed-width margin-top-xx"
              >
                Continue
              </Button>
              <Button
                disabled={submitting}
                variant="outlined"
                className="button-fixed-width margin-top-xx arrowBackButton"
                onClick={() => setShowChangeForm(false)}
                data-testid="backLink"
              >
                <Back />
              </Button>
            </div>
          </Form>
        ) : (
          <>
            <h3>Verify your email</h3>
            <p data-testid="emailSentMessage">
              We have sent you an email with additional instructions. Please
              check your inbox for <span className="text-orange">{email}</span>
            </p>
            <p>
              <button
                type="button"
                className="text-link margin-xx"
                disabled={loading}
                onClick={() => setShowChangeForm(true)}
              >
                Change Email
              </button>
            </p>
            <p>
              Didn't get the email?{' '}
              <button
                type="button"
                className="text-link margin-xx"
                disabled={loading}
                onClick={handleResend}
              >
                Resend
              </button>
            </p>
          </>
        )}
      </AccountProgressContainer>
    </RequireLoginWrap>
  ) : null;
};

function mapStateToProps(store) {
  return {
    user: store.auth.user,
    resendingConfirmation: store.auth.resendingConfirmationEmail,
    submitting: store.auth.submitting
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, Props>({
    form: 'changeEmail',
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(emailVerifySchema)
  })(EmailVerify)
);
