import { Alert, Button, ErrorLabel } from '@equitymultiple/react-eui';
import history from 'browserHistory';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { stateOptions } from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const Address = ({
  dispatch,
  error,
  handleSubmit,
  submitting
}: SharedProfileValues) => {
  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'address_attributes.address',
      'address_attributes.address2',
      'address_attributes.city',
      'address_attributes.state',
      'address_attributes.postal_code'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          history.push('/users/profile/document_identification');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Address</h3>
        <p className="margin-xx">
          Please provide your current residential address below. If your address
          changes in the future, remember to update it in your profile.
        </p>
        <Alert type="neutral">
          <div>
            1. We prohibit PO Boxes
            <br />
            2. The address must be a US address and cannot be for a registered
            agent
          </div>
        </Alert>
        <Row>
          <Col xs={12} md={6}>
            <Field
              id="address"
              component={ReduxFormInputField}
              name="address_attributes.address"
              label="Address"
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              id="address2"
              component={ReduxFormInputField}
              name="address_attributes.address2"
              label="Unit / Apt / Suite"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Field
              id="city"
              component={ReduxFormInputField}
              name="address_attributes.city"
              label="City"
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              id="state"
              component={ReduxFormSelectField}
              name="address_attributes.state"
              label="State"
              options={stateOptions}
            />
          </Col>
          <Col xs={12} md={4}>
            <Field
              id="postal_code"
              component={ReduxFormInputField}
              name="address_attributes.postal_code"
              label="Zip Code"
              mask="99999"
            />
          </Col>
        </Row>

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/kyc" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    initialValues: getUserAttributes(store.auth.user),
    submitting: store.userSettings.updatingUserProfile
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'address',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(Address)
);
