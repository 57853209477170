import { ErrorLabel } from '@equitymultiple/react-eui';
import {
  ReduxFormRadioField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import AchIcon from 'images/icons/ach-payment.svg';
import CheckIcon from 'images/icons/check-payment.svg';
import WireIcon from 'images/icons/wire-payment.svg';
import React from 'react';
import { Field } from 'redux-form';
import { CapitalCallForInvestment } from 'types/api/capitalCall';
import {
  FundingOptions,
  Investment,
  PaymentMethod
} from 'types/api/investment';
import utils from 'utilities/utils';

import * as styles from './PaymentForm.module.scss';

interface Props {
  capitalCallTransaction?: CapitalCallForInvestment;
  fundingOptions: FundingOptions;
  invalid: boolean;
  investment: Investment;
  selectedBalance: number;
  selectedPaymentMethod: PaymentMethod;
  updateFormValue: (field, value) => void;
}

const PaymentForm = ({
  capitalCallTransaction,
  fundingOptions,
  investment,
  invalid,
  selectedPaymentMethod,
  selectedBalance,
  updateFormValue
}: Props) => {
  const bankAccountOptions = () => {
    return fundingOptions?.funding_sources?.length > 0
      ? fundingOptions.funding_sources.map(fundingSource => ({
          label: fundingSource.bank_name
            ? `${fundingSource.bank_name} - ${fundingSource.type} (${fundingSource.account_number})`
            : `${fundingSource.name} (${fundingSource.account_number})`,
          value: fundingSource.id
        }))
      : [];
  };

  const remainingAmount = capitalCallTransaction
    ? parseFloat(capitalCallTransaction.amount)
    : parseFloat(investment.unfunded_amount) > 0
      ? parseFloat(investment.unfunded_amount) - selectedBalance
      : parseFloat(investment.pending_amount || '0');

  const wrapperClass = invalid
    ? `${styles.wrapper} ${styles.invalid}`
    : styles.wrapper;

  return (
    <div>
      <p className="weight-md-heavy">
        {parseInt(investment.unfunded_amount) === 0
          ? 'Amount'
          : 'Remaining amount'}{' '}
        to fund: {utils.formatCurrency(remainingAmount)}
      </p>
      {invalid && <ErrorLabel message="Payment method is required" />}
      {fundingOptions?.funding_sources?.length > 0 && (
        <div
          className={`${wrapperClass} ${selectedPaymentMethod === 'ACH' && styles.selected}`}
        >
          <button
            type="button"
            className={styles.rowContainer}
            onClick={() => updateFormValue('payment_method', 'ACH')}
            data-testid="achPaymentMethod"
          >
            <div className={styles.iconWrapper}>
              <AchIcon />
            </div>
            <div className={styles.fullWidth}>
              ACH
              <div className={styles.secondaryText}>1 - 2 business days</div>
            </div>
            <Field
              id="ach"
              name="payment_method"
              type="radio"
              component={ReduxFormRadioField}
              value="ACH"
              hideError
            />
          </button>
          {selectedPaymentMethod === 'ACH' && (
            <div className={styles.bankAccountSelectWrapper}>
              <hr />
              <Field
                className={`input-fixed-width ${styles.bankAccountSelect}`}
                name="source"
                id="funding-source"
                component={ReduxFormSelectField}
                label="Bank Account"
                options={bankAccountOptions()}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={`${wrapperClass} ${selectedPaymentMethod === 'WIRE' && styles.selected}`}
      >
        <button
          type="button"
          className={styles.rowContainer}
          onClick={() => updateFormValue('payment_method', 'WIRE')}
          data-testid="wirePaymentMethod"
        >
          <div className={styles.iconWrapper}>
            <WireIcon />
          </div>
          <div className={styles.fullWidth}>
            WIRE
            <div className={styles.secondaryText}>
              1 - 2 business days (fees may apply)
            </div>
          </div>
          <Field
            id="wire"
            name="payment_method"
            type="radio"
            component={ReduxFormRadioField}
            value="WIRE"
            hideError
          />
        </button>
      </div>
      <div
        className={`${wrapperClass} ${selectedPaymentMethod === 'CHECK' && styles.selected}`}
      >
        <button
          type="button"
          className={styles.rowContainer}
          onClick={() => updateFormValue('payment_method', 'CHECK')}
          data-testid="checkPaymentMethod"
        >
          <div className={styles.iconWrapper}>
            <CheckIcon />
          </div>
          <div className={styles.fullWidth}>
            CHECK
            <div className={styles.secondaryText}>1 - 10 business days</div>
          </div>
          <Field
            id="check"
            name="payment_method"
            type="radio"
            component={ReduxFormRadioField}
            value="CHECK"
            hideError
          />
        </button>
      </div>
    </div>
  );
};

export default PaymentForm;
