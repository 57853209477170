import { EMLoadingIcon } from '@equitymultiple/react-eui';
import queryString from 'query-string';
import React, { ReactElement, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { redirectUsersWithIncompleteSignup } from 'utilities/user';
import utils from 'utilities/utils';

import history from '../browserHistory';

export const checkAuth = (user, pathname, search) => {
  if (!user?.id) {
    const isOfferingRoute =
      /invest\/(\d+)(?:\/)?$/.test(pathname) ||
      /invest\/(\d+)\/closings\/(\d+)(?:\/)?$/.test(pathname);

    if (
      window.location.href.indexOf('/users/sign') < 0 &&
      window.location.href.indexOf('/users/password') < 0
    ) {
      utils.setLocalStorage('referer', window.location.href);
    }

    if (isOfferingRoute) {
      const offeringId = pathname.match(/invest\/(\d+)/)[1];
      let redirectPath = '/users/sign_in';

      const query = search ? queryString.parse(search) : null;
      if (query?.sign_up_redirect) redirectPath = '/users/signup/start';

      history.replace({
        pathname: redirectPath,
        search: `?offering_sign_up_id=${offeringId}`
      });
    } else {
      humane.error('You need to sign in or sign up before continuing.');
      history.replace('/users/sign_in');
    }
  }

  redirectUsersWithIncompleteSignup(user);
};

type Props = {
  children?: ReactElement;
  dispatch?: Dispatch;
  loaded?: boolean;
  loading?: boolean;
  location?: {
    pathname?: string;
    search?: string;
  };
  user?: User;
};

const RequireLogin = ({
  children,
  dispatch,
  loading,
  loaded,
  location,
  user
}: Props) => {
  const { pathname, search } = location;
  const currentPage = pathname + search;
  const currentPageRef = useRef(currentPage);

  useEffect(() => {
    dispatch(loadAuth()).then(userRes => {
      checkAuth(userRes, pathname, search);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newPage = pathname + search;

    // Check auth on every route change
    if (!currentPageRef || currentPageRef.current !== newPage) {
      if (loaded) checkAuth(user, pathname, search);
      else
        dispatch(loadAuth()).then(userRes =>
          checkAuth(userRes, pathname, search)
        );
    }

    currentPageRef.current = newPage;
  }, [pathname, search, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (!loaded && loading) || !user ? <EMLoadingIcon /> : children;
};

function mapStateToProps(store) {
  return {
    loaded: store.auth.loaded,
    loading: store.auth.loading,
    user: store.auth.user
  };
}

const RequireLoginWithRouter = withRouter(RequireLogin);

export default connect(mapStateToProps)(RequireLoginWithRouter);
