import deepmerge from 'deepmerge';
import moment from 'moment';
import postalCodes from 'postal-codes-js';
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';

import countries from '../config/countries';
import restrictedLastNames from '../config/restrictedLastNames';
import {
  allResidencyStatuses,
  entityBusinessTypes,
  //entityTrustOrLlcTypes,
  idTypes,
  iraAccountTypes,
  iraCustodians,
  maritalStatuses,
  maxFinancialDependents,
  minFinancialDependents,
  portfolioValues,
  taxFilingStatuses
} from '../containers/Accounts/contents';
import { File } from '../types/yup';
import { fileTypes, maxUploadSize } from './constants';
import { lowerCase, number as regexNumber, symbols, upperCase } from './regex';
import utils from './utils';

// Takes a yup schema and returns errors in redux-form format

export function validateSchema(schema, options?) {
  return formValues => {
    // Leaving these console logs for debugging
    //console.log('formValues', formValues);
    try {
      schema.validateSync(formValues, { ...options, abortEarly: false });
    } catch (errors) {
      if (errors instanceof yup.ValidationError) {
        //console.log('errors', errors);
        // Create array of objects in field:error format, including nested fields
        const errorObjectsArray =
          errors.inner &&
          errors.inner.map(error => {
            const path = error.path;
            const paths = path.split('.');
            return utils.setValueToNestedObjectField(paths, error.message);
          });
        // Merge the array into a single object
        const formErrors =
          errorObjectsArray && deepmerge.all(errorObjectsArray);
        return formErrors;
      }
    }
    return {};
  };
}

// Custom versions of types to use globally in place of the specified type
// Adds nullable() so we don't have to repeat it in every instance

export const yupBool = yup.bool().nullable();
export const yupNumber = yup.number().nullable();
export const yupString = yup.string().nullable().trim().max(255);
export const yupStringArray = yup.array().of(yupString);

// Yup compatible validations which can be reused to create schemas

export const validations = {
  // Base validations that all fields of the specified name will use
  fields: {
    accreditation: yupStringArray
      .required('Your accredited status is required')
      .test(
        '"None" is not selected alongside other options',
        'You cannot select "None of these" in addition to another option',
        value => {
          const onlyNoneIsSelected = value[0] === 'None' && value.length === 1;
          const noneIsNotSelected = !value.find(
            selectedValue => selectedValue === 'None'
          );
          return !value || onlyNoneIsSelected || noneIsNotSelected;
        }
      ),

    address: yupString.required('Address is required'),

    address2: yupString.notRequired(),

    paymentMethod: yupString.required('Payment method is required'),

    businessClass: yupString.required('Business class is required'),

    businessRegistrationJurisdiction: yupString.required(
      'Jurisdiction of business registration is required'
    ),

    captchaResponse: yupString.test({
      name: "Captcha response exists, or we're in a test environment",
      test(value) {
        const hasError =
          !value &&
          process.env.NODE_ENV !== 'test' &&
          process.env.REACT_APP_BYPASS_CAPTCHA !== 'true';
        return hasError
          ? this.createError({
              message: "Please confirm that you're not a robot",
              path: this.path
            })
          : true;
      }
    }),

    city: yupString.required('City is required'),

    confirmValidId: yupBool
      .oneOf(
        [true],
        'Please confirm that your identification document meets the requirements'
      )
      .required(
        'Please confirm that your identification document meets the requirements'
      ),

    country: yupString.required('Country is required'),

    countryOfCitizenship: yupString
      .required('Country of citizenship is required')
      .test({
        name: 'Country of citizenship matches residency',
        test(value) {
          const residenceStatus = this.parent.residence_status;
          let message = null;

          if (residenceStatus) {
            if (residenceStatus !== 'U.S. Citizen' && value === 'United States')
              message =
                'Non-US Citizens cannot select the United States as their country of citizenship';
            if (residenceStatus === 'U.S. Citizen' && value !== 'United States')
              message =
                'United States citizens must select the United States as their country of citizenship';
          }

          return message
            ? this.createError({
                message,
                path: this.path
              })
            : true;
        }
      }),

    // A valid date string in MM/DD/YYYY format
    // yup has a date() function, but it's extremely liberal. Missing month/day/year in our date select
    // will still be parsed as a valid date.
    date: yup
      .string()
      .required('Date is required')
      .test(
        'Is a full valid date',
        'Must be a valid date',
        value =>
          !value || !!value.match(/^(\d{2,2})(\/)(\d{2,2})\2(\d{4}|\d{4})$/)
      )
      .nullable(),

    get dateOfFormation() {
      const { fields, tests } = validations;
      return fields.date
        .test(tests.pastDate)
        .required('Date of formation is required');
    },

    get dateOfRegistration() {
      const { fields, tests } = validations;
      return fields.date
        .test(tests.pastDate)
        .required('Date of registration is required');
    },

    dob: yupString.required('Date of birth is required'),

    ein: yupString
      .notRequired()
      .when('business_type', {
        is: val => val !== 'trust_revocable' && val !== 'soleproprietorship',
        then: () => yupString.required('EIN is required')
      })
      .test(
        'EIN is 9 digits',
        'EIN must be 9 digits',
        value => !value || value.replace(/\D/g, '').length === 9
      ),

    email: yupString
      .required('Email address is required')
      .test(
        'Is a valid email address format',
        'Must be a valid email address',
        value => !value || isEmail(value)
      ),

    employerName: yupString.required('Employer name is required'),

    entityBusinessType: yupString
      .required('Entity business type is required')
      .oneOf(
        entityBusinessTypes.map(businessType => businessType.value),
        'Entity business type is required'
      ),

    entityName: yupString
      .required('Entity name is required')
      .test(
        'Length',
        'Entity name cannot exceed 80 characters',
        val => !val || val.length < 81
      ),

    beneficialOwnerPercentageOwnership: yupString
      .required('Ownership percentage is required')
      .test({
        name: 'Total ownership percentage is less than 100%, ownership percentage is at least 25%',
        test(value) {
          let message = null;
          if (value) {
            const beneficialOwners = this.parent.beneficialOwners;
            const currentBeneficialOwner = this.parent.currentBeneficialOwner;
            const ownershipPercentageValue = parseFloat(
              this.parent.percentage_ownership
            );
            let totalOwnershipPercentage = 0;

            if (beneficialOwners && beneficialOwners.length > 0) {
              totalOwnershipPercentage = beneficialOwners.reduce(
                (totalOwnership, owner) => {
                  if (
                    (currentBeneficialOwner &&
                      owner.id !== currentBeneficialOwner.id) ||
                    !currentBeneficialOwner
                  )
                    return (
                      totalOwnership +
                      (owner.percentage_ownership
                        ? parseFloat(owner.percentage_ownership)
                        : 0)
                    );
                  return totalOwnership;
                },
                totalOwnershipPercentage
              );
            }

            if (totalOwnershipPercentage + ownershipPercentageValue > 100)
              message =
                'Total ownership for all beneficial owners cannot exceed 100%';

            if (ownershipPercentageValue < 25)
              message =
                'You do not need to list individuals or entities under 25% ownership stake';
          }

          return message
            ? this.createError({
                message,
                path: this.path
              })
            : true;
        }
      }),

    financialDependentCount: yupNumber
      .min(
        minFinancialDependents,
        `Number of financial dependents must be between ${minFinancialDependents} and ${maxFinancialDependents}`
      )
      .max(
        maxFinancialDependents,
        `Number of financial dependents must be between ${minFinancialDependents} and ${maxFinancialDependents}`
      )
      .required('Number of financial dependents is required')
      .typeError(
        'Number of financial dependents is required, and must be a number'
      ),

    firstName: yupString
      .required('First name is required')
      .test(
        'Length',
        'First name cannot exceed 40 characters',
        val => !val || val.length < 41
      )
      .test(
        'First name does not contain restricted value',
        '"&" and "and" are not allowed',
        value =>
          !value || (value.indexOf('&') < 0 && value.indexOf(' and ') < 0)
      ),

    fullName: yupString
      .required('Full name is required')
      .test(
        'Length',
        'Full name cannot exceed 80 characters',
        val => !val || val.length < 81
      ),

    // File size and type validations are only applicable when the field value is a file
    // For existing values, the value can either be a string (file URL) or an object (URL, name, etc.)
    idDocument: yup
      .mixed<File>()
      .required('ID document is required')
      .test(
        'File Size',
        'File size is too large',
        file => !file || !file.size || (file && file.size <= maxUploadSize)
      )
      .test(
        'File Type',
        'File must be in JPG or PNG format',
        file =>
          !file ||
          !file.type ||
          (file &&
            fileTypes.map(fileType => fileType.format).includes(file.type))
      ),

    get idExpiration() {
      return yupString
        .required('ID expiration is required')
        .test(validations.tests.futureDate);
    },

    idType: yupString.required('ID type is required').oneOf(
      idTypes.map(idType => idType.value),
      'ID type is required'
    ),

    industry: yupString.required('Industry is required'),

    investmentAccount: yupString.required('Investment account is required'),

    investmentAmount: yupString
      .test({
        name: 'Investment amount is in a valid of increment of the minimum amount',
        test(value) {
          const { increment } = this.parent;
          const amount = utils.currencyStringToNumber(value);
          let message = null;

          if (increment) {
            if (amount % increment !== 0) {
              const incrementFormatted = utils.numberWithCommas(increment);
              message = `You must select a value in increments of $${incrementFormatted}`;
            }
          }

          return message
            ? this.createError({
                message,
                path: 'investment_amount'
              })
            : true;
        }
      })
      .test({
        name: 'Investment amount is within the allowed range',
        test(value) {
          const range = this.parent.range;
          const amount = utils.currencyStringToNumber(value);
          let message = null;

          if (range && range.minRange && range.maxRange) {
            const minRange = parseInt(range.minRange);
            const maxRange = parseInt(range.maxRange);

            if (amount !== minRange && minRange === maxRange) {
              message = `Due to limited allocation available for this investment,
              currently we are only accepting an investment of ${utils.numberWithCommas(
                minRange
              )}
              for this offering.`;
            }

            if (!(amount >= minRange && amount <= maxRange)) {
              message = `You must select a value between $${utils.numberWithCommas(
                minRange
              )}
                and $${utils.numberWithCommas(maxRange)}`;
            }
          }

          return message
            ? this.createError({
                message,
                path: 'investment_amount'
              })
            : true;
        }
      })
      .required('Investment amount is required'),

    investmentExperience: yupStringArray.min(
      1,
      'Investment experience is required'
    ),
    investmentObjectives: yupString.required(
      'Investment objective is required'
    ),
    investmentReason: yupStringArray.min(1, 'Investment reason is required'),

    get iraAccountNumber() {
      return yupString
        .required('Account number is required')
        .test(validations.tests.alphaNumericDash);
    },

    iraAccountType: yupString
      .oneOf(iraAccountTypes, 'Account type is required')
      .required('Account type is required'),

    iraCustodian: yupString
      .oneOf(iraCustodians, 'IRA custodian is required')
      .required('IRA custodian is required'),

    lastName: yupString
      .required('Last name is required')
      .test(
        'Length',
        'Last name cannot exceed 40 characters',
        val => !val || val.length < 41
      )
      .test(
        'Last name does not contain restricted value',
        "You've entered an invalid last name. If you are attempting to invest as an entity, you should create an Entity Investment Account.",
        value =>
          !value || restrictedLastNames.indexOf(value.toLowerCase().trim()) < 0
      ),

    maritalStatus: yupString
      .oneOf(maritalStatuses, 'Marital status is required')
      .required('Marital status is required'),

    occupation: yupString.required('Occupation is required'),

    passport: yupString.notRequired().when('id_type', {
      is: val => val === 'passport',
      then: () => yupString.required('Passport number is required')
    }),

    // Password creation
    password: yupString
      .test(
        'Password meets strength requirements',
        'Password is not strong enough. Please ensure it meets 3 out of the 4 requirements and is at least 8 characters long.',
        value => {
          if (value) {
            let valid = false;
            let complete = 0;
            if (regexNumber.test(value)) complete += 1;
            if (upperCase.test(value)) complete += 1;
            if (lowerCase.test(value)) complete += 1;
            if (symbols.test(value)) complete += 1;
            valid = value.length >= 8 && complete >= 3;
            return valid;
          }
          return true;
        }
      )
      .required('Password is required'),

    phone: yupString
      .test(
        'Phone number is at least 10 digits',
        'Phone number must be at least 10 digits long',
        value => !value || value.replace(/\D/g, '').length > 9
      )
      .required('Phone number is required'),

    portfolioValue: yupString
      .required('Portfolio value is required')
      .oneOf(portfolioValues, 'Portfolio value is required'),

    postalCode: yupString
      .required('Postal code is required')
      .test(
        'Postal code is valid for selected country',
        "You've entered an invalid postal code for your selected country",
        function test(value) {
          if (value) {
            const country = this.parent.country;
            let countryCode;
            if (!country || country === 'United States') countryCode = 'USA';
            else
              countryCode = countries.find(
                countryInArray => countryInArray.name === country
              )?.code;

            return postalCodes.validate(countryCode, value) === true;
          }
          return true;
        }
      ),

    // Generic yes/no boolean field
    requiredBool: yupBool.required('Select yes or no'),

    // Fields that require a value of true, such as investment certifications
    requireTrue: yupBool
      .oneOf([true], 'Acceptance is required')
      .required('Acceptance is required'),

    residenceStatus: yupString
      .oneOf(allResidencyStatuses, 'Residence status is required')
      .required('Residence status is required'),

    riskTolerance: yupString.required('Risk tolerance is required'),

    sponsorLogo: yup
      .mixed<File>()
      .required('Sponsor logo is required')
      .test(
        'File Size',
        'File size is too large',
        file => !file || !file.size || (file && file.size <= maxUploadSize)
      )
      .test(
        'File Type',
        'File must be in JPG or PNG format',
        file =>
          !file ||
          !file.type ||
          (file &&
            fileTypes.map(fileType => fileType.format).includes(file.type))
      ),

    sponsorName: yupString.required('Sponsor name is required'),

    ssn: yupString
      .notRequired()
      .test(
        'SSN is 9 digits long',
        'SSNs must be 9 digits long',
        value => !value || (value && value.replace(/[\W_]/g, '').length === 9)
      )
      .test(
        "If the form has a residence status field and its value isn't 'Other' then SSN is required",
        'Social security number is required',
        function test(value) {
          const residenceStatus = this.parent.residence_status;
          if ((residenceStatus !== 'Other' || !residenceStatus) && !value)
            return false;
          return true;
        }
      ),

    state: yupString.required('State is required'),

    taxFilingStatus: yupString
      .oneOf(
        taxFilingStatuses.map(taxFilingStatus => taxFilingStatus.value),
        'Tax filing status is required'
      )
      .required('Tax filing status is required'),

    title: yupString.required('Title is required')
  },
  // Full tests which can be appended to fields in the schema, to provide additional validation
  // See https://github.com/jquense/yup#mixedtestoptions-object-schema
  tests: {
    alphaNumericDash: {
      name: 'Contains only letters, numbers, hyphens and underscores',
      message: 'Can only contain letters, numbers, hyphens and underscores',
      test: value => !value || (value && /^[a-zA-Z0-9-_]+$/.test(value))
    },
    ageOver18: {
      name: 'Age is at least 18',
      message: 'You must be at least 18 years old',
      test: value => {
        if (value) {
          const formattedDate = moment(value, 'MM/DD/YYYY');
          const validDate = formattedDate.isValid();
          if (validDate) {
            const eighteenYearsAgo = moment().subtract(18, 'years');
            return eighteenYearsAgo.isAfter(formattedDate);
          }
          return false;
        }
        return true;
      }
    },
    noPoBox: {
      name: 'Address does not contain PO box',
      message:
        'For compliance reasons we cannot accept PO Boxes. Please enter a different mailing address.',
      test: value =>
        !value ||
        (value && !value.toLowerCase().replace(/[ .,]/g, '').match(/pobox/g))
    },
    futureDate: {
      name: 'Date is in the future',
      message: 'Date must be today or in the future',
      test: value => {
        if (value) {
          const formattedDate = moment(value, 'MM/DD/YYYY');
          const validDate = formattedDate.isValid();
          if (validDate) {
            return moment().add(-1, 'days').isBefore(formattedDate);
          }
          return false;
        }
        return true;
      }
    },
    pastDate: {
      name: 'Date is in the past',
      message: 'Date must be before today',
      test: value => {
        if (value) {
          const validDate = moment(value, 'MM/DD/YYYY', true).isValid();
          if (validDate) return moment().isAfter(value);
          return false;
        }
        return true;
      }
    }
  },
  // In some cases we need reusable functionality for multiple tests which have slightly different uses.
  // These functions are used as the 'test' function property of a test, and can be passed different
  // fields and use field-specific messaging
  testHelpers: {
    dateIsAfterSiblingDate(value, fieldContext, siblingDate) {
      const firstDate = fieldContext.parent[siblingDate];
      if (value && firstDate) return moment(firstDate).isBefore(moment(value));
      return true;
    },
    dateIsAfterOrEqualToSiblingDate(value, fieldContext, siblingDate) {
      const firstDate = fieldContext.parent[siblingDate];
      if (value && firstDate)
        return moment(firstDate).isSameOrBefore(moment(value));
      return true;
    },
    numberIsGreaterThanOrEqualToSiblingNumber(
      value,
      fieldContext,
      siblingNumber
    ) {
      const smallerNumber = fieldContext.parent[siblingNumber];
      if (value && smallerNumber)
        // Convert to numbers if comparing formatted currency strings.
        // If already numbers then currencyStringToNumber() is harmless
        return (
          utils.currencyStringToNumber(smallerNumber) <=
          utils.currencyStringToNumber(value)
        );
      return false;
    },
    numberIsLessThanOrEqualToSiblingNumber(value, fieldContext, siblingNumber) {
      const largerNumber = fieldContext.parent[siblingNumber];
      if (value && largerNumber)
        // Convert to numbers if comparing formatted currency strings.
        // If already numbers then currencyStringToNumber() is harmless
        return (
          utils.currencyStringToNumber(value) <=
          utils.currencyStringToNumber(largerNumber)
        );
      return true;
    }
  }
};
