import { Button } from '@equitymultiple/react-eui';
import {
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import { stateOptions } from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Field } from 'redux-form';

interface Props {
  residenceStatus?: string;
  updating?: boolean;
}

const AddressForm = ({ residenceStatus, updating }: Props) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Field
            id="address"
            name="address_attributes.address"
            maxLength={50}
            component={ReduxFormInputField}
            placeholder="Street Address"
            label="Address"
          />
        </Col>
        <Col sm={6}>
          <Field
            id="address2"
            name="address_attributes.address2"
            maxLength={50}
            component={ReduxFormInputField}
            placeholder="Apartment number, etc."
            label="Address 2 (Optional)"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            id="city"
            component={ReduxFormInputField}
            name="address_attributes.city"
            label="City"
          />
        </Col>

        <Col sm={4}>
          {residenceStatus === 'Other' ? (
            <Field
              name="address_attributes.state"
              id="state"
              component={ReduxFormInputField}
              label="State / Province"
            />
          ) : (
            <Field
              label="State"
              name="address_attributes.state"
              id="state"
              component={ReduxFormSelectField}
              options={stateOptions}
            />
          )}
        </Col>
        <Col sm={4}>
          <Field
            id="postal_code"
            name="address_attributes.postal_code"
            placeholder="Postal Code"
            label="Postal Code"
            mask="99999"
            component={ReduxFormInputField}
          />
        </Col>
      </Row>
      <Button
        data-testid="profileSubmit"
        style={{ width: '140px' }}
        className="float-right"
        loading={updating}
        name="submit"
        type="submit"
      >
        Update
      </Button>
    </>
  );
};

export default AddressForm;
