export const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY;

export const loadRecaptchaScript = () => {
  const script = document.createElement('script');
  script.id = 'recaptchaScript';
  script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaPublicKey}`;
  document.body.appendChild(script);
};

export const removeRecaptchaScript = () => {
  const recaptchaScript = document.getElementById('recaptchaScript');
  const recaptchaBadge = document.querySelector('.grecaptcha-badge');

  if (recaptchaScript) recaptchaScript.remove();
  if (recaptchaBadge) recaptchaBadge.remove();
};

export const getRecaptchaToken = (action: string) => {
  return new Promise(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY, {
          action
        })
        .then(token => {
          return resolve(token);
        });
    });
  });
};
