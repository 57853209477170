import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import PhoneFields from 'components/forms/PhoneFields/PhoneFields';
import React from 'react';
import { connect } from 'react-redux';
import { Form, InjectedFormProps, reduxForm } from 'redux-form';
import { scrollToError } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { PhoneFormValues } from '../types';
import { phoneSchema } from '../validation';

export interface Props
  extends Partial<InjectedFormProps<PhoneFormValues, Props>> {
  onSubmit: (values: PhoneFormValues) => void;
}

const PhoneForm = ({ error, handleSubmit, onSubmit, submitting }: Props) => {
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="margin-xx">
        Enter the phone number you'll use to receive one-time passwords:
      </h5>
      <PhoneFields />
      {error && <ErrorLabel message={error} />}
      <Button style={{ width: 260 }} type="submit" loading={submitting}>
        Send One-Time Password
      </Button>
    </Form>
  );
};

function mapStateToProps(store) {
  const { user } = store.auth;

  return {
    initialValues: {
      country_code: user.country_code || '1',
      phone: user.phone
    }
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'phone',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(phoneSchema)
  })(PhoneForm)
);
