import { validations, yupBool, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields, tests } = validations;

export const emailConfirmationSchema = yup.object().shape({
  unconfirmed_email: fields.email
});

export const newAccountSchema = yup.object().shape({
  investment_account: yup.object().shape({
    type: yupString.required('Investment account type is required')
  })
});

export const profileSchema = yup.object().shape({
  address: fields.address.test(tests.noPoBox),
  address2: fields.address2.test(tests.noPoBox),
  city: fields.city,
  country_of_citizenship: fields.countryOfCitizenship,
  dob: fields.dob.test(tests.ageOver18),
  first_name: fields.firstName,
  last_name: fields.lastName,
  phone: fields.phone,
  postal_code: fields.postalCode.required('Postal code is required'),
  residence_status: fields.residenceStatus,
  ssn: fields.ssn,
  state: fields.state
});

export const userIdentificationDocumentSchema = yup.object().shape({
  confirm_valid_id: fields.confirmValidId,
  id_document: fields.idDocument,
  id_expiration: fields.idExpiration,
  id_type: fields.idType,
  passport: fields.passport
});

export const accountInformationIraSchema = yup.object().shape({
  investment_account: yup.object().shape({
    business_type: fields.iraAccountType,
    business_type_other: yupString.notRequired().when('business_type', {
      is: val => val === 'Other',
      then: () => yupString.required('The account type is required')
    }),
    entity_name: fields.iraCustodian,
    ira_account_number: fields.iraAccountNumber
  })
});

export const bankTosSchema = yup.object().shape({
  bank_account_tos: yup.object().shape({
    opted_bank_linking_tos_and_pp: fields.requireTrue
  })
});

export const beneficialOwnerSchema = yup.object().shape({
  beneficial_owner: yup.object().shape({
    address: fields.address,
    address2: fields.address2,
    city: fields.city,
    dob: fields.dob.test(tests.ageOver18),
    document: fields.idDocument,
    document_type: fields.idType,
    email: fields.email,
    first_name: fields.firstName,
    last_name: fields.lastName,
    percentage_ownership: fields.beneficialOwnerPercentageOwnership,
    phone: fields.phone,
    postal_code: fields.postalCode.required('Postal code is required'),
    ssn: fields.ssn,
    state: fields.state,
    title: fields.title
  })
});

export const w9Schema = yup.object().shape({
  investment_account: yup.object().shape({
    tax_filing_status: fields.taxFilingStatus
  })
});

export const jointSecondaryHolderSchema = yup.object().shape({
  investment_account: yup.object().shape({
    account_holder_2_address: yupString
      .notRequired()
      .test(tests.noPoBox)
      .when('account_holder_2_address_is_same', {
        is: false,
        then: () => yupString.required('Address is required')
      }),
    account_holder_2_address2: fields.address2.test(tests.noPoBox),
    account_holder_2_address_is_same: yupBool.notRequired(),
    account_holder_2_city: yupString
      .notRequired()
      .when('account_holder_2_address_is_same', {
        is: false,
        then: () => yupString.required('City is required')
      }),
    account_holder_2_email: fields.email,
    account_holder_2_first_name: fields.firstName,
    account_holder_2_last_name: fields.lastName,
    account_holder_2_phone: fields.phone,
    account_holder_2_postal_code: fields.postalCode.when(
      'account_holder_2_address_is_same',
      {
        is: false,
        then: () => yupString.required('Postal code is required')
      }
    ),
    account_holder_2_region: yupString
      .notRequired()
      .when('account_holder_2_address_is_same', {
        is: false,
        then: () => yupString.required('State is required')
      })
  })
});

export const jointSecondaryHolderIdentificationSchema = yup.object().shape({
  investment_account: yup.object().shape({
    account_holder_2_confirm_valid_id: fields.confirmValidId,
    account_holder_2_dob: fields.dob.test(tests.ageOver18),
    account_holder_2_id_document: fields.idDocument,
    account_holder_2_id_expiration: fields.idExpiration,
    account_holder_2_id_type: fields.idType
  })
});

export const entitySchema = yup.object().shape({
  investment_account: yup.object().shape({
    address: fields.address.test(tests.noPoBox),
    address2: fields.address2.test(tests.noPoBox),
    business_class: fields.businessClass,
    business_type: fields.entityBusinessType,
    city: fields.city,
    date_of_formation: fields.dateOfFormation,
    date_of_registration: fields.dateOfRegistration,
    ein: fields.ein,
    entity_current_bankruptcy: fields.requiredBool,
    entity_name: fields.entityName,
    entity_portfolio_value: fields.portfolioValue,
    entity_publicly_traded: fields.requiredBool,
    industry_class: fields.industry,
    jurisdiction_of_business_registration:
      fields.businessRegistrationJurisdiction,
    officer_title: fields.title,
    postal_code: fields.postalCode.required('Postal code is required'),
    region: fields.state,
    retirement_purpose_vehicle: fields.requiredBool
  })
});

export const existingBankAccountSchema = yup.object().shape({
  bank: yupString.required(
    'Please select which bank you intend to link an account with'
  )
});
